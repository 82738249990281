<template>
  <svg
    id="圖層_1"
    data-name="圖層 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1110 400"
  >
    <defs></defs>
    <text class="cls-1" transform="translate(168.46 240.98)">
      想了解正統相法
      <tspan class="cls-2" x="248.15" y="0">，</tspan>
      <tspan class="cls-3" x="284.31" y="0">卻擔心內容精深</tspan>
      <tspan class="cls-2" x="532.46" y="0">，</tspan>
      <tspan x="568.62" y="0">記不起來嗎？</tspan>
    </text>
    <text class="cls-4" transform="translate(117.09 336.38)">
      其實
      <tspan class="cls-5" x="139.09" y="0">，</tspan>
      <tspan x="208.64" y="0">沒有你想像中的困難！</tspan>
    </text>
    <path
      class="cls-6"
      d="M565.17,139.86a45.5,45.5,0,1,0-10.86,7.91,30.08,30.08,0,0,0,2.88,1.73,59,59,0,0,0,14.37,4.86c.82.19,1.86.31,2.38-.36.7-.9-.22-2.14-1-2.93A34.57,34.57,0,0,1,565.17,139.86Z"
    />
    <path
      class="cls-7"
      d="M527.59,115.22l-.07-1.87a12.12,12.12,0,0,1,3-8.11l2.29-2.92c1.59-2.08,3.46-4.09,3.33-7.28a4.67,4.67,0,0,0-4.65-4.57A11,11,0,0,0,525,93.1a4.64,4.64,0,0,1-6.24-6.86A19.15,19.15,0,0,1,532,80.9c10.05-.28,15.87,5.27,16.08,12.13.21,7.28-4.44,11.79-6.31,13.45l-1.25,1.11c-2.08,1.81-3.39,3.47-3.26,6l.07,1.31c.14,2.64-1.38,4-4.71,4.16S527.66,117.85,527.59,115.22Zm-2.22,14.49a6.8,6.8,0,0,1,13.59,0,6.78,6.78,0,0,1-6.73,6.73A6.85,6.85,0,0,1,525.37,129.71Z"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style scoped>
.cls-1 {
  font-size: 35.45px;
  fill: #fff;
  /* font-family: GenEi-M-Gothic-v2-Medium, GenEi M Gothic v2; */
  font-weight: 500;
}

.cls-2 {
  /* font-family: jf-jinxuanlatte-1.1-Medium-B5pc-H, "jf-jinxuanlatte-1.1"; */
  letter-spacing: 0.02em;
}

.cls-2,
.cls-5 {
  font-weight: 400;
}

.cls-3 {
  letter-spacing: 0em;
}

.cls-4 {
  font-size: 69.55px;
  /* font-family: GenEi-M-Gothic-v2-Heavy, GenEi M Gothic v2; */
  font-weight: 800;
}

.cls-4,
.cls-7 {
  fill: #ff5445;
}

.cls-5 {
  /* font-family: jf-jinxuanlatte-1.1-Bold-B5pc-H, 'jf-jinxuanlatte-1.1'; */
}

.cls-6 {
  fill: none;
  stroke: #ff5445;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 5.85px;
}
</style>
