<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1110 649.64"
  >
    <defs>
      <linearGradient
        id="未命名漸層_11"
        x1="55.67"
        y1="324.18"
        x2="1073.25"
        y2="324.18"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#fff" />
      </linearGradient>
    </defs>
    <g class="cls-1">
      <g id="圖層_1" data-name="圖層 1">
        <rect
          class="cls-3"
          x="55.67"
          y="41.19"
          width="1017.58"
          height="565.98"
          rx="15.93"
        />
        <text class="cls-4" transform="translate(276.47 153.13)">
          實戰派命理師 - 簡少年老師
        </text>
        <text class="cls-5" transform="translate(178.4 244.77)">
          <tspan class="cls-6">
            師承面相學大師蕭湘居士一脈
            <tspan class="cls-7" x="435.24" y="0"></tspan>
            <tspan class="cls-8" x="435.24" y="0">，</tspan>
            <tspan class="cls-9" x="468.1" y="0">並結</tspan>
            <tspan class="cls-10" x="533.82" y="0">合</tspan>
            <tspan class="cls-11" x="569.16" y="0"></tspan>
            <tspan class="cls-12" x="572.16" y="0">1</tspan>
            <tspan class="cls-13" x="593.14" y="0">4</tspan>
            <tspan class="cls-14" x="614.74" y="0"></tspan>
            <tspan class="cls-15" x="621.46" y="0">餘</tspan>
            <tspan class="cls-16" x="654.32" y="0">年</tspan>
            <tspan class="cls-17" x="689.66" y="0">看相</tspan>
          </tspan>
          <tspan class="cls-6" x="0" y="50.8">經</tspan>
          <tspan class="cls-18" x="33.48" y="50.8">驗</tspan>
          <tspan class="cls-19" x="67.58" y="50.8">，</tspan>
          <tspan class="cls-20" x="97.34" y="50.8">在兩岸三地已授課近</tspan>
          <tspan class="cls-21" x="404.24" y="50.8"></tspan>
          <tspan class="cls-22" x="414.06" y="50.8">3</tspan>
          <tspan class="cls-23" x="436.9" y="50.8">0</tspan>
          <tspan class="cls-22" x="456.64" y="50.8">0</tspan>
          <tspan class="cls-21" x="479.48" y="50.8"></tspan>
          <tspan class="cls-20" x="489.3" y="50.8">場</tspan>
          <tspan class="cls-24" x="523.4" y="50.8">，</tspan>
          <tspan class="cls-18" x="553.78" y="50.8">線上</tspan>
          <tspan class="cls-25" x="621.98" y="50.8">授課接觸</tspan>
          <tspan class="cls-15">
            <tspan x="-5.58" y="101.6" xml:space="preserve">100,000+</tspan>
            <tspan class="cls-26" x="168.7" y="101.6">人</tspan>
            <tspan class="cls-27" x="199.08" y="101.6">了</tspan>
            <tspan class="cls-28" x="230.7" y="101.6">解一般人學習面相最</tspan>
            <tspan class="cls-27" x="520.86" y="101.6">容易遇到的問題</tspan>
            <tspan class="cls-29" x="734.45" y="101.6">。</tspan>
          </tspan>
          <tspan class="cls-30">
            <tspan x="0" y="183.2">簡老師運用現代價</tspan>
            <tspan class="cls-31" x="252.96" y="183.2">值</tspan>
            <tspan x="284.58" y="183.2">觀</tspan>
            <tspan class="cls-32" x="316.2" y="183.2"></tspan>
            <tspan class="cls-33" x="315.17" y="183.2">，</tspan>
            <tspan class="cls-31" x="339.04" y="183.2"></tspan>
            <tspan x="346.64" y="183.2">幽默口吻解讀傳統相學並採用</tspan>
            <tspan x="0" y="234">照片案例舉證說明</tspan>
            <tspan class="cls-24" x="252.96" y="234">，</tspan>
            <tspan x="283.34" y="234">快速教大家判斷秘訣上完課立即能</tspan>
            <tspan x="0" y="284.8">運用於生活中！</tspan>
          </tspan>
        </text>
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style scoped>
.cls-1 {
  isolation: isolate;
}

.cls-2 {
  opacity: 0.8;
  mix-blend-mode: multiply;
}

.cls-3 {
  fill: url(#未命名漸層_11);
}

.cls-4 {
  font-size: 45px;
  /* font-family: GenEi-M-Gothic-v2-Heavy, GenEi M Gothic v2; */
  font-weight: 800;
}

.cls-5 {
  font-size: 31px;
  /* font-family: GenEi-M-Gothic-v2-Medium, GenEi M Gothic v2; */
  font-weight: 500;
}

.cls-26,
.cls-27,
.cls-28,
.cls-29,
.cls-5 {
  fill: #3e3a39;
}

.cls-6 {
  letter-spacing: 0.08em;
}

.cls-7 {
  letter-spacing: -0.3em;
}

.cls-19,
.cls-24,
.cls-29,
.cls-31,
.cls-33,
.cls-8 {
  /* font-family: NotoSansHant-Medium-B5pc-H, Noto Sans T Chinese; */
  font-weight: 400;
}

.cls-13,
.cls-17,
.cls-8 {
  letter-spacing: 0.06em;
}

.cls-15,
.cls-9 {
  letter-spacing: 0.06em;
}

.cls-10,
.cls-16 {
  letter-spacing: 0.14em;
}

.cls-11,
.cls-12,
.cls-13,
.cls-14,
.cls-15,
.cls-16,
.cls-21,
.cls-22,
.cls-23 {
  fill: #ff5445;
}

.cls-11 {
  letter-spacing: -0.2em;
}

.cls-12,
.cls-28 {
  letter-spacing: 0.04em;
}

.cls-14 {
  letter-spacing: -0.08em;
}

.cls-18,
.cls-22 {
  letter-spacing: 0.1em;
}

.cls-19 {
  letter-spacing: -0.04em;
}

.cls-20 {
  letter-spacing: 0.1em;
}

.cls-21,
.cls-27,
.cls-30 {
  letter-spacing: 0.02em;
}

.cls-24,
.cls-26 {
  letter-spacing: -0.02em;
}

.cls-25 {
  letter-spacing: 0.08em;
}

.cls-29,
.cls-33 {
  letter-spacing: -0.23em;
}

.cls-32 {
  letter-spacing: -0.08em;
}
.cls-3 {
  filter: drop-shadow(0px 0px 15px rgba(165, 144, 139));
}
</style>
