<template>
  <svg
    id="圖層_1"
    data-name="圖層 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 120 1110 220"
  >
    <defs></defs>
    <text class="cls-1" transform="translate(250.51 300.72)">
      從
      <tspan class="cls-2" x="77.4" y="11.89"></tspan>
      <tspan class="cls-3" x="101.2" y="11.89">0</tspan>
      <tspan class="cls-4" x="168.72" y="11.89"></tspan>
      <tspan class="cls-5" x="201.68" y="0">帶你無痛學</tspan>
      <tspan class="cls-6" x="547.95" y="0">會</tspan>
      <tspan class="cls-7" x="623.99" y="0">!</tspan>
    </text>
    <text class="cls-8" transform="translate(236.78 196.21)">
      最易上手的面相課
    </text>
    <path
      class="cls-9"
      d="M422.77,318.14a57.15,57.15,0,1,0-13.65,9.95,38.07,38.07,0,0,0,3.62,2.17c5.63,3,11.84,4.68,18.05,6.11,1,.23,2.34.38,3-.46.88-1.13-.28-2.68-1.31-3.68A43.26,43.26,0,0,1,422.77,318.14Z"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style scoped>
.cls-1 {
  font-size: 67.9px;
  letter-spacing: 0.14em;
}

.cls-1,
.cls-8 {
  /* font-family: GenEi-M-Gothic-v2-Heavy, GenEi M Gothic v2; */
  font-weight: 800;
}

.cls-2 {
  letter-spacing: 0.04em;
}

.cls-3,
.cls-4 {
  fill: #ff5445;
  font-size: 94.01px;
}

.cls-3 {
  letter-spacing: 0.06em;
}

.cls-4 {
  letter-spacing: 0.04em;
}

.cls-5 {
  letter-spacing: 0.02em;
}

.cls-6 {
  letter-spacing: 0.12em;
}

.cls-7 {
  letter-spacing: 0.02em;
}

.cls-8 {
  font-size: 81.4px;
  letter-spacing: 0.02em;
}

.cls-9 {
  fill: none;
  stroke: #ff5445;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6.4px;
}
</style>
