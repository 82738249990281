<template>
  <svg
    id="圖層_1"
    data-name="圖層 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 55 1110 348"
  >
    <defs></defs>
    <path
      class="cls-1"
      d="M795.84,355.72c0,1-1.93,1.78-2.18,2.73s1,2.6.53,3.45-2.56.56-3.26,1.27-.4,2.76-1.26,3.26-2.48-.8-3.46-.54-1.7,2.19-2.73,2.19-1.78-1.93-2.72-2.19-2.6,1-3.46.54-.56-2.56-1.26-3.26-2.76-.4-3.26-1.27.79-2.47.53-3.45-2.19-1.7-2.19-2.73,1.94-1.78,2.19-2.73-1-2.59-.53-3.45,2.55-.56,3.26-1.26.39-2.76,1.26-3.27,2.48.8,3.46.54,1.7-2.19,2.72-2.19,1.78,1.93,2.73,2.19,2.6-1,3.46-.54.55,2.56,1.26,3.27,2.76.39,3.26,1.26-.79,2.48-.53,3.45S795.84,354.7,795.84,355.72Z"
    />
    <text class="cls-2" transform="translate(335.26 218.79)">
      九執掌訣、氣色、面相底層邏輯。
      <tspan x="0" y="48">帶你循序漸進、邏輯性的熟悉面相知識！</tspan>
    </text>
    <path
      class="cls-1"
      d="M307.93,229.36c0,11.63-22,20.22-24.83,30.95-3,11.1,11.67,29.5,6,39.22s-29,6.34-37,14.34-4.48,31.31-14.34,37-28.12-9-39.22-6c-10.73,2.86-19.32,24.83-30.95,24.83s-20.23-22-31-24.83c-11.1-3-29.51,11.67-39.23,6s-6.34-29-14.34-37-31.31-4.48-37-14.34,9-28.12,6-39.22C49.27,249.58,27.3,241,27.3,229.36s22-20.23,24.84-31c3-11.1-11.67-29.5-6-39.22s29-6.34,37-14.34,4.49-31.31,14.34-37,28.13,9,39.23,6C147.39,111,156,89,167.62,89s20.22,22,30.95,24.83c11.1,3,29.5-11.67,39.22-6s6.34,29,14.34,37,31.31,4.48,37,14.34-9,28.12-6,39.22C286,209.13,307.93,217.72,307.93,229.36Z"
    />
    <path
      class="cls-1"
      d="M1087.73,112.3c0,2.07-3.9,3.59-4.41,5.5s2.07,5.24,1.08,7-5.16,1.13-6.58,2.55-.79,5.56-2.54,6.57-5-1.6-7-1.07-3.43,4.41-5.49,4.41-3.59-3.9-5.5-4.41-5.24,2.07-7,1.07-1.13-5.15-2.55-6.57-5.56-.8-6.57-2.55,1.6-5,1.07-7-4.41-3.43-4.41-5.5,3.9-3.59,4.41-5.49-2.07-5.24-1.07-7,5.15-1.13,6.57-2.55.8-5.56,2.55-6.57,5,1.6,7,1.08,3.43-4.41,5.5-4.41,3.59,3.9,5.49,4.41,5.24-2.08,7-1.08,1.12,5.15,2.54,6.57,5.56.8,6.58,2.55-1.6,5-1.08,7S1087.73,110.24,1087.73,112.3Z"
    />
    <path
      class="cls-1"
      d="M42.29,92.3c0,.38-.72.66-.81,1s.38,1,.2,1.28-1,.21-1.21.47-.15,1-.47,1.21-.91-.3-1.27-.2-.63.81-1,.81-.66-.72-1-.81-1,.38-1.28.19-.21-.94-.47-1.2-1-.15-1.2-.47.29-.91.19-1.28-.81-.63-.81-1,.72-.65.81-1-.38-1-.19-1.28.94-.21,1.2-.47.15-1,.47-1.21.92.3,1.28.2.63-.81,1-.81.66.72,1,.81,1-.38,1.27-.19.21.94.47,1.2,1,.15,1.21.47-.3.91-.2,1.28S42.29,91.93,42.29,92.3Z"
    />
    <text class="cls-9" transform="translate(93.77 221.17)">
      加碼
      <tspan x="0" y="78">贈送</tspan>
    </text>
  </svg>
</template>

<script>
export default {}
</script>

<style scoped>
.cls-1,
.cls-2 {
  fill: #fff;
}

.cls-2 {
  font-size: 40px;
  /* font-family: GenEi-M-Gothic-v2-Bold, GenEi M Gothic v2; */
  font-weight: 700;
  letter-spacing: 0.06em;
}

.cls-3,
.cls-4,
.cls-5 {
  /* font-family: NotoSansHant-Medium-B5pc-H, Noto Sans T Chinese; */
  font-weight: 400;
}

.cls-3 {
  letter-spacing: -0.13em;
}

.cls-4 {
  letter-spacing: 0.12em;
}

.cls-5 {
  letter-spacing: -0.13em;
}

.cls-6 {
  letter-spacing: 0.1em;
}

.cls-7 {
  letter-spacing: 0.18em;
}

.cls-8 {
  letter-spacing: 0.06em;
}

.cls-9 {
  font-size: 70px;
  /* font-family: GenEi-M-Gothic-v2-Heavy, GenEi M Gothic v2; */
  font-weight: 800;
  letter-spacing: 0.04em;
}
</style>
