<template>
  <svg
    id="圖層_1"
    data-name="圖層 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1110 1300"
  >
    <defs>
      <linearGradient
        id="未命名漸層_28"
        x1="13.77"
        y1="1286.46"
        x2="1140.42"
        y2="1286.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" />
        <stop offset="0.5" stop-color="#a9a9aa" />
        <stop offset="1" stop-color="#fff" />
      </linearGradient>
    </defs>
    <rect class="cls-1" x="-14.16" y="668.93" width="1129.55" height="974.78" />
    <rect class="cls-2" x="-0.86" y="678.24" width="557.34" height="304.44" />
    <rect class="cls-2" x="555.15" y="981.52" width="557.34" height="304.44" />
    <rect
      class="cls-3"
      x="-213.68"
      y="-131.83"
      width="1497.76"
      height="809.37"
    />
    <rect
      class="cls-2"
      x="173.21"
      y="395.96"
      width="743.81"
      height="190.34"
      rx="95.17"
    />
    <text class="cls-4" transform="translate(349.75 545.3)">
      特價$
      <tspan class="cls-3" x="194.97" y="0">9800</tspan>
    </text>
    <text class="cls-5" transform="translate(418.37 461.91)">原價$12800</text>
    <line class="cls-6" x1="396.31" y1="443.13" x2="693.92" y2="443.13" />
    <text class="cls-7" transform="translate(132.79 852.18)">
      <tspan class="cls-8">現代觀念解說傳統</tspan>
      <tspan class="cls-8" x="0" y="49.74">相法</tspan>
      <tspan x="79.58" y="49.74"></tspan>
      <tspan class="cls-9" x="81.34" y="49.74">，</tspan>
      <tspan class="cls-10" x="109.5" y="49.74"></tspan>
      <tspan class="cls-11" x="117.94" y="49.74">吸收好輕鬆</tspan>
    </text>
    <text class="cls-7" transform="translate(132.79 1149.8)">
      <tspan class="cls-8">簡老師親自主持的</tspan>
      <tspan class="cls-8" x="0" y="49.74">討論群</tspan>
      <tspan class="cls-12" x="119.38" y="49.74"></tspan>
      <tspan class="cls-9" x="120.38" y="49.74">，</tspan>
      <tspan class="cls-10" x="148.54" y="49.74"></tspan>
      <tspan class="cls-11" x="156.98" y="49.74">持續精進</tspan>
    </text>
    <circle class="cls-13" cx="291.96" cy="763.72" r="25.16" />
    <polyline class="cls-14" points="315.27 746.03 291.4 774.97 278.2 761.19" />
    <circle class="cls-13" cx="291.96" cy="1071.24" r="25.16" />
    <polyline
      class="cls-14"
      points="315.27 1053.56 291.4 1082.49 278.2 1068.71"
    />
    <circle class="cls-13" cx="829.58" cy="763.72" r="25.16" />
    <polyline
      class="cls-14"
      points="852.9 746.03 829.02 774.97 815.82 761.19"
    />
    <text class="cls-15" transform="translate(690.31 852.18)">
      超精美全彩講義
      <tspan x="32.84" y="49.74">學習效率up</tspan>
    </text>
    <circle class="cls-13" cx="829.58" cy="1066.76" r="25.16" />
    <polyline
      class="cls-14"
      points="852.9 1049.07 829.02 1078.01 815.82 1064.23"
    />
    <text class="cls-15" transform="translate(710.2 1151.46)">
      課程影片不限
      <tspan x="-19.14" y="45.05">次</tspan>
      <tspan class="cls-16" x="20.65" y="45.05">數</tspan>
      <tspan class="cls-17" x="59.69" y="45.05">、</tspan>
      <tspan x="98.73" y="45.05">永久觀看</tspan>
    </text>
    <text class="cls-18" transform="translate(200.14 289.66)">
      從
      <tspan class="cls-19" x="82.19" y="12.62"></tspan>
      <tspan class="cls-20" x="107.46" y="12.62">0</tspan>
      <tspan class="cls-21" x="179.15" y="12.62"></tspan>
      <tspan class="cls-22" x="214.14" y="0">帶你無痛學</tspan>
      <tspan class="cls-23" x="581.82" y="0">會</tspan>
      <tspan class="cls-22" x="662.56" y="0">!</tspan>
    </text>
    <text class="cls-24" transform="translate(192.48 178.68)">
      最易上手的面相課
    </text>
    <path
      class="cls-25"
      d="M383,308.15a60.57,60.57,0,1,0-14.49,10.57,43,43,0,0,0,3.85,2.3c6,3.15,12.57,5,19.16,6.48,1.11.26,2.49.41,3.18-.48.94-1.21-.29-2.85-1.39-3.91A46,46,0,0,1,383,308.15Z"
    />
    <line class="cls-26" x1="13.77" y1="1286.46" x2="1140.42" y2="1286.46" />
  </svg>
</template>

<script>
export default {}
</script>

<style scoped>
.cls-1 {
  fill: #fffbff;
}

.cls-2 {
  fill: #f4f1f4;
}

.cls-3 {
  fill: #ff5445;
}

.cls-4 {
  font-size: 70.24px;
  letter-spacing: 0.06em;
}

.cls-15,
.cls-4,
.cls-5,
.cls-7 {
  /* font-family: GenEi-M-Gothic-v2-Medium, GenEi M Gothic v2; */
  font-weight: 500;
}

.cls-5 {
  font-size: 41.33px;
  fill: #595757;
  letter-spacing: 0.06em;
}

.cls-13,
.cls-14,
.cls-25,
.cls-26,
.cls-6 {
  fill: none;
}

.cls-13,
.cls-14,
.cls-6 {
  stroke: #ff5445;
}

.cls-14,
.cls-25,
.cls-6 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-6 {
  stroke-width: 2.78px;
}

.cls-15,
.cls-7 {
  font-size: 37.54px;
}

.cls-15,
.cls-8 {
  letter-spacing: 0.06em;
}

.cls-10,
.cls-17,
.cls-9 {
  /* font-family: NotoSansHant-Medium-B5pc-H, Noto Sans T Chinese; */
  font-weight: 400;
}

.cls-9 {
  letter-spacing: -0.25em;
}

.cls-11 {
  letter-spacing: 0.06em;
}

.cls-12 {
  letter-spacing: -0.02em;
}

.cls-13,
.cls-26 {
  stroke-miterlimit: 10;
}

.cls-13,
.cls-14 {
  stroke-width: 5.5px;
}

.cls-16 {
  letter-spacing: 0.04em;
}

.cls-17 {
  letter-spacing: 0.04em;
}

.cls-18 {
  font-size: 72.09px;
  letter-spacing: 0.14em;
}

.cls-18,
.cls-24 {
  fill: #fff;
  /* font-family: GenEi-M-Gothic-v2-Heavy, GenEi M Gothic v2; */
  font-weight: 800;
}

.cls-19 {
  letter-spacing: 0.04em;
}

.cls-20,
.cls-21 {
  font-size: 99.82px;
}

.cls-20 {
  letter-spacing: 0.06em;
}

.cls-21 {
  letter-spacing: 0.04em;
}

.cls-22 {
  letter-spacing: 0.02em;
}

.cls-23 {
  letter-spacing: 0.12em;
}

.cls-24 {
  font-size: 86.43px;
  letter-spacing: 0.02em;
}

.cls-25 {
  stroke: #f4f1f4;
  stroke-width: 6.8px;
}

.cls-26 {
  stroke: url(#未命名漸層_28);
}
</style>
