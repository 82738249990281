<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1110 1650"
  >
    <g id="圖層_6" data-name="圖層 6">
      <path
        class="cls-2"
        d="M-2295.8,1198.85s-1.36,354.26,0,380.42c150.28-36.35,218.4-27.57,285.11-15.32,95.19,17.47,187.2,49.1,281.67,70.15s194,31.28,287.39,5.73c90.49-24.76,246.29-80.84,350.05-95.48v-.19c33.17-6,66.45-11.3,99.89-15.65,197.14-25.67,445.48-5.15,587.8,32.33,315.66,83.13,474.64,35.3,715.53,8.66s484.58-69.76,723.83-31.14c160.43,25.89,327.76,88,483.53,41.73V1198.85Z"
      />
      <path
        class="cls-2"
        d="M1518.4,1268s1.35-493.6,0-519.76c-150.28,36.36-218.4,27.57-285.11,15.33-95.19-17.48-187.2-49.11-281.67-70.16s-194-31.28-287.39-5.73c-90.49,24.77-246.29,80.85-350,95.48v.19c-33.17,6-66.45,11.3-99.89,15.66-197.14,25.66-445.48,5.14-587.8-32.34C-689.17,683.56-848.15,731.4-1089,758s-484.58,69.76-723.83,31.15c-160.43-25.9-327.76-88-483.53-41.74V1268Z"
      />
    </g>
    <g id="圖層_34" data-name="圖層 34">
      <text class="cls-3" transform="translate(350.26 978.67)">
        精心配套設計
        <tspan x="0" y="80">幫助學習記憶</tspan>
      </text>
      <text class="cls-4" transform="translate(56.96 390.07)">
        <tspan class="cls-5">掌握一門學問最好的辦法就是持續</tspan>
        <tspan class="cls-5" x="0" y="59.13">練習</tspan>
        <tspan class="cls-6" x="65.72" y="59.13"></tspan>
        <tspan class="cls-7" x="65.31" y="59.13">。</tspan>
        <tspan class="cls-8" x="89.8" y="59.13"></tspan>
        <tspan class="cls-5" x="98.02" y="59.13">每位簡老師的學</tspan>
        <tspan class="cls-9" x="328.04" y="59.13">員</tspan>
        <tspan class="cls-10" x="361.52" y="59.13">，</tspan>
        <tspan class="cls-11" x="392.52" y="59.13">皆受邀</tspan>
        <tspan class="cls-12" x="0" y="118.25">進入</tspan>
        <tspan class="cls-13" x="66.96" y="118.25">學習討論</tspan>
        <tspan class="cls-14" x="200.88" y="118.25">群</tspan>
        <tspan class="cls-10" x="234.98" y="118.25">，</tspan>
        <tspan class="cls-15" x="265.98" y="118.25">你可以在群組內</tspan>
        <tspan class="cls-5" x="0" y="177.38">提出問</tspan>
        <tspan class="cls-9" x="98.58" y="177.38">題</tspan>
        <tspan class="cls-10" x="132.06" y="177.38">，</tspan>
        <tspan class="cls-12" x="163.06" y="177.38">與簡老師及各路</tspan>
        <tspan class="cls-5" x="397.42" y="177.38">學員深</tspan>
        <tspan class="cls-5" x="0" y="236.51">入討</tspan>
        <tspan class="cls-12" x="65.72" y="236.51">論</tspan>
        <tspan class="cls-10" x="99.2" y="236.51">，</tspan>
        <tspan class="cls-15" x="130.2" y="236.51">亦可參與其他人的提</tspan>
        <tspan class="cls-9" x="425.94" y="236.51">問</tspan>
        <tspan class="cls-10" x="459.42" y="236.51">，</tspan>
        <tspan class="cls-5">
          <tspan x="0" y="295.63">找出看相盲點</tspan>
          <tspan class="cls-16" x="200.47" y="295.63">、</tspan>
          <tspan class="cls-8" x="224.96" y="295.63"></tspan>
          <tspan x="233.18" y="295.63">增強實力</tspan>
          <tspan class="cls-17" x="364.62" y="295.63">。</tspan>
        </tspan>
      </text>
      <text class="cls-18" transform="translate(807.83 1309.57)">
        課程影片不限次數
        <tspan class="cls-19" x="230.44" y="0">、</tspan>
        <tspan x="0" y="35.6">永久免費觀看</tspan>
        <tspan class="cls-20" x="170.83" y="35.6">，</tspan>
        <tspan class="cls-21" x="194.32" y="35.6">重要</tspan>
        <tspan x="0" y="71.2">觀念反覆聽</tspan>
        <tspan class="cls-22" x="141.4" y="71.2">。</tspan>
      </text>
      <text class="cls-18" transform="translate(225.01 1330.25)">
        精美全彩圖像講義
        <tspan x="0" y="35.6">理解更快速</tspan>
        <tspan class="cls-19" x="141.4" y="35.6">。</tspan>
      </text>
      <path
        class="cls-2"
        d="M158.74,1304.5l-83.2,31.35c-11.1,4.19-21.8-4.73-26-15.83l-1.6-.53c-4.18-11.1,1.33-19.65,12.43-23.84l83.19-31.35Z"
      />
      <path
        class="cls-23"
        d="M87.1,1419.61a15.45,15.45,0,0,0,20,8.77l86.14-33.66"
      />
      <path
        class="cls-24"
        d="M193.69,1395.8l-40.14-102.71-51.65,20.18-34.49,13.49a15.46,15.46,0,0,1-20-8.77l9,23,30.92,79.11"
      />
      <path
        class="cls-25"
        d="M87.65,1419.59a15.45,15.45,0,0,0,20,8.77l86.15-33.66"
      />
      <path
        class="cls-26"
        d="M47.94,1318a15.46,15.46,0,0,1,8.77-20l86.14-33.66L193.59,1394"
      />
      <path
        class="cls-26"
        d="M154.1,1293.07l-51.64,20.19-22.23,8.68L68,1326.74a15.46,15.46,0,0,1-20-8.77"
      />
      <line class="cls-26" x1="87.83" y1="1420.05" x2="47.64" y2="1317.2" />
      <line class="cls-26" x1="65.4" y1="1328.28" x2="100.02" y2="1416.87" />
      <line class="cls-26" x1="124.52" y1="1278.85" x2="144.76" y2="1270.74" />
      <line class="cls-26" x1="133.71" y1="1283.39" x2="147.51" y2="1277.79" />
      <line class="cls-26" x1="127.56" y1="1293.93" x2="150.27" y2="1284.84" />
      <text class="cls-27" transform="translate(56.95 200.38)">
        學習不斷電
        <tspan class="cls-28" x="297.26" y="0">！</tspan>
        <tspan class="cls-28">
          <tspan x="0" y="75.32">簡老師主持的討論群</tspan>
        </tspan>
      </text>
      <path
        class="cls-29"
        d="M444.33,187.3a35.19,35.19,0,1,1,8.4,6.12,22,22,0,0,1-2.23,1.34,45.5,45.5,0,0,1-11.11,3.76c-.64.15-1.44.24-1.85-.28-.54-.7.18-1.65.81-2.27A26.66,26.66,0,0,0,444.33,187.3Z"
      />
      <path
        class="cls-30"
        d="M463.18,178.11a5.4,5.4,0,0,1,5.47-5.15,5.26,5.26,0,1,1,0,10.51A5.4,5.4,0,0,1,463.18,178.11Zm1.66-11.32-1.61-19.09c-.32-3.6,1.83-5.58,5.37-5.58s5.68,2,5.36,5.58l-1.66,19.09a3.35,3.35,0,0,1-3.7,3.22C466.51,170,465,168.88,464.84,166.79Z"
      />
      <path
        class="cls-30"
        d="M875.48,976.92H976.92c5.5,0,11.36-.58,16.84,0,2.52.26,4.25,1.18,4.61,4.13s0,6.15,0,9.1v31.62c0,3.52.26,7.16,0,10.67a5.35,5.35,0,0,1-.52,2.72c-1,2.1-2.3,2.11-4.12,2.29-9.83,1-20.28,0-30.15,0H939.26a3.1,3.1,0,0,0-2.89,2.2,14,14,0,0,1-11.36,9.4l3.39,4.41a27,27,0,0,0,4.88-13c.2-1.61-1.54-3-3-3H881.77c-2,0-4.31.35-6.26,0-4.12-.73-3.58-4.2-3.58-7.23V986.54c0-3.18-1.09-9.16,3.55-9.62,3.81-.39,3.85-6.39,0-6-11.22,1.13-9.55,12.2-9.55,20.43v34.39c0,7.6-.64,17.17,9.76,17.71,8.85.46,17.84,0,26.69,0h27.9l-3-3a21,21,0,0,1-4.06,10c-1.72,2.39,1.12,4.74,3.39,4.41,7.58-1.12,12.95-6.61,15.54-13.59l-2.89,2.2h53c3.43,0,6.74-.17,9.37-2.8s2.79-6,2.79-9.44V985.28c0-2.66.31-5.62-.62-8.16-2.49-6.82-9.39-6.2-15.19-6.2H875.48C871.62,970.92,871.61,976.92,875.48,976.92Z"
      />
      <path
        class="cls-30"
        d="M192.31,1029.14H113.72a4.31,4.31,0,0,0-4.31,4.31v35.19a4.31,4.31,0,0,0,4.31,4.31h36.59c1.27,3.93,4.76,7.19,8.86,7.57a14.32,14.32,0,0,1-2.94-7.57h36.08a4.31,4.31,0,0,0,4.31-4.31v-35.19A4.31,4.31,0,0,0,192.31,1029.14Z"
      />
      <rect x="710.28" y="89.06" width="281.36" height="589.53" rx="28.06" />
      <path
        class="cls-32"
        d="M962.79,94.71H930.22a3.58,3.58,0,0,0-3.59,3.58V100a14.77,14.77,0,0,1-14.77,14.77H790.35A14.78,14.78,0,0,1,775.58,100V98.29A3.58,3.58,0,0,0,772,94.71H739.22A23.24,23.24,0,0,0,716,118V647.39c0,16.3,13.22,26.1,29.52,26.1h211c16.31,0,29.52-9.8,29.52-26.1V118A23.24,23.24,0,0,0,962.79,94.71Z"
      />
      <rect
        class="cls-24"
        x="596.93"
        y="1282.74"
        width="169.82"
        height="107.8"
        rx="19.03"
      />
      <rect
        class="cls-26"
        x="596.93"
        y="1282.74"
        width="169.82"
        height="107.8"
        rx="19.03"
      />
      <path
        class="cls-2"
        d="M669.74,1306.1l39,23.21a8.52,8.52,0,0,1,0,14.65l-39,23.22a8.53,8.53,0,0,1-12.88-7.33v-46.42A8.52,8.52,0,0,1,669.74,1306.1Z"
      />
      <text class="cls-33" transform="translate(268.51 1129.31)">
        為了幫助你更好吸收課程內容
        <tspan class="cls-34" x="411.04" y="0">，</tspan>
        <tspan class="cls-35" x="434.53" y="0"></tspan>
        <tspan class="cls-36" x="442.85" y="0">我們提供不</tspan>
        <tspan x="-8.41" y="35.6">斷複習的環境</tspan>
        <tspan class="cls-34" x="183.39" y="35.6">，</tspan>
        <tspan class="cls-37" x="206.88" y="35.6"></tspan>
        <tspan x="215.2" y="35.6">從視覺與聽覺雙重加深印象</tspan>
        <tspan class="cls-34" x="583.79" y="35.6">。</tspan>
      </text>
      <path
        class="cls-2"
        d="M695.44,430h354.24a14,14,0,0,1,14,13.95v52.35a14,14,0,0,1-14,14H776.21c-4.1,12.71-15.38,23.25-28.65,24.48a46.59,46.59,0,0,0,9.52-24.48H695.44a14,14,0,0,1-14-14V443.93A14,14,0,0,1,695.44,430Z"
      />
      <path
        d="M695.44,432h305.12c15.75,0,31.52-.16,47.27,0,2.46,0,4.8,0,7.11,1.22,7.06,3.56,6.63,10.62,6.63,17.24v43.25c0,7.46-3.57,14.08-12,14.42-1.31.05-2.63,0-3.95,0H776.21a2.12,2.12,0,0,0-2,1.51c-4.06,11.79-14,21.47-26.66,23l1.78,3.1a50.58,50.58,0,0,0,9.8-25.52,2.08,2.08,0,0,0-2.06-2.06H700.87c-2.64,0-5.58.27-8.16-.34-6.69-1.57-9.16-7.46-9.16-13.65V462.74c0-10.44-3.49-30,11.89-30.7,2.65-.12,2.66-4.26,0-4.13-8.15.39-15,6-15.92,14.4a97.7,97.7,0,0,0-.1,10v37.76c0,3.5-.33,7.29.64,10.69,2,7.05,8.46,11.32,15.57,11.56,9.69.32,19.44,0,29.14,0h32.31L755,510.23a46.65,46.65,0,0,1-9.23,23.44,2.07,2.07,0,0,0,1.78,3.11c14.63-1.73,25.92-12.31,30.64-26l-2,1.52h264c5,0,11,.7,15.69-1.26,13.53-5.62,9.84-24.87,9.84-36.48V450.84c0-5.25.51-10.67-2.41-15.36-4.34-7-11.43-7.57-18.64-7.57H695.44C692.78,427.91,692.78,432,695.44,432Z"
      />
      <path
        class="cls-2"
        d="M978,283H695.44a14,14,0,0,0-14,13.95v56a14,14,0,0,0,14,14H895.68c4.1,12.71,15.38,23.25,28.65,24.48a46.59,46.59,0,0,1-9.52-24.48H978a14,14,0,0,0,14-14v-56A14,14,0,0,0,978,283Z"
      />
      <path
        d="M978,280.9H728.23c-10.83,0-21.73-.34-32.56,0-11.12.36-16.25,9-16.25,19.12v36.56c0,8.73-2.09,20.84,4.71,27.63,4.81,4.82,10.72,4.71,16.88,4.71H895.68l-2-1.52c4.72,13.69,16,24.27,30.64,26a2.07,2.07,0,0,0,1.78-3.11,46.62,46.62,0,0,1-9.23-23.44l-2.07,2.07c20.75,0,41.51.27,62.25,0,5.89-.08,11.39-2.39,14.59-7.58,2.43-3.93,2.41-8.18,2.41-12.59V310.12c0-4.35.16-8.75,0-13.1a16.35,16.35,0,0,0-16-16.12c-2.66-.12-2.65,4,0,4.13,14.32.69,11.89,16.49,11.89,26.4v35.83c0,3.6.41,7.57-1.22,10.89-2.64,5.36-7.84,6.64-13.23,6.64H914.81a2.06,2.06,0,0,0-2.06,2.06,50.58,50.58,0,0,0,9.8,25.52l1.78-3.1c-12.69-1.5-22.6-11.19-26.66-23a2.12,2.12,0,0,0-2-1.51H714.57c-5.76,0-11.52,0-17.28,0-2.46,0-4.81-.08-7.11-1.22-5.84-2.89-6.63-8.56-6.63-14.28V314.17c0-9.41-3.07-26.65,9.79-29,2.83-.51,6-.18,8.83-.18H866.53c36.55,0,73.14.53,109.68,0H978C980.7,285,980.7,280.9,978,280.9Z"
      />
      <path
        class="cls-2"
        d="M695.44,138.82h354.24a14,14,0,0,1,14,14v56a14,14,0,0,1-14,13.95H776.21c-4.1,12.71-15.38,23.25-28.65,24.49a46.62,46.62,0,0,0,9.52-24.49H695.44a14,14,0,0,1-14-13.95v-56A14,14,0,0,1,695.44,138.82Z"
      />
      <path
        d="M695.44,140.89H1002c15.41,0,30.83-.22,46.24,0,3.65.05,7.11.75,9.85,3.49,4.38,4.37,3.5,10.76,3.5,16.4V205.4c0,7.63-3,14.87-11.91,15.24-1.16.05-2.33,0-3.49,0h-270a2.13,2.13,0,0,0-2,1.51c-4.06,11.79-14,21.47-26.66,23l1.78,3.11a50.62,50.62,0,0,0,9.8-25.53,2.08,2.08,0,0,0-2.06-2.06H699.73c-3.71,0-7.31.22-10.65-1.84-5-3.07-5.53-8.22-5.53-13.4V171.91c0-10.47-3.55-30.29,11.89-31,2.65-.13,2.66-4.26,0-4.13-8.27.39-15.17,6.2-16,14.67-.31,3.33-.07,6.76-.07,10.09v39.22a83.78,83.78,0,0,0,.14,9.9c1,8.21,7.84,13.78,15.89,14.13,9.4.41,18.92,0,28.33,0h33.3L755,222.7a46.62,46.62,0,0,1-9.23,23.44,2.07,2.07,0,0,0,1.78,3.11c14.63-1.73,25.92-12.3,30.64-26l-2,1.52h264.55c5.75,0,12.27.76,17.36-2.41,5.91-3.67,7.58-9.75,7.58-16.24v-45c0-4.63.59-9.92-1.16-14.3-3.38-8.49-11.3-10.09-19.26-10.09H695.44C692.78,136.76,692.78,140.89,695.44,140.89Z"
      />
      <path
        class="cls-2"
        d="M948.59,554.32H697.05a14,14,0,0,0-13.95,13.95v44.56a14,14,0,0,0,13.95,13.95H867.82c4.1,12.71,15.38,23.25,28.65,24.48A46.59,46.59,0,0,1,887,626.78h61.64a14,14,0,0,0,13.95-13.95V568.27A14,14,0,0,0,948.59,554.32Z"
      />
      <path
        d="M948.59,552.26H730.19c-10.76,0-21.52-.15-32.27,0-9.31.12-16.5,6.55-16.89,16-.35,8.55,0,17.18,0,25.74v15c0,3.64-.08,7.14,1.52,10.54,3.36,7.15,10.19,9.25,17.44,9.25H867.82l-2-1.51c4.72,13.69,16,24.27,30.64,26a2.08,2.08,0,0,0,1.79-3.11A46.63,46.63,0,0,1,889,626.78L887,628.84h58.6c10.32,0,18.78-5.36,19.06-16.59.27-10.89,0-21.83,0-32.72,0-3.74.16-7.52,0-11.26a16.33,16.33,0,0,0-16-16c-2.66-.13-2.65,4,0,4.13,14,.66,11.89,15.63,11.89,25.4s.18,19.8,0,29.68c-.14,7.15-4.3,12.91-11.89,13.24-1.9.09-3.83,0-5.73,0H887a2.07,2.07,0,0,0-2.06,2.07,50.58,50.58,0,0,0,9.8,25.52l1.78-3.1c-12.69-1.5-22.6-11.19-26.66-23a2.13,2.13,0,0,0-2-1.52H710.92c-4.39,0-8.8.12-13.19,0-4.78-.12-9.14-2.14-11.35-6.63-1.65-3.33-1.22-7.34-1.22-11V575.42c0-3-.35-6.28.18-9.25,1.06-5.94,6.22-9.58,12-9.78,9.12-.32,18.3,0,27.42,0H852.51c31.49,0,63,.54,94.51,0h1.57C951.25,556.39,951.25,552.26,948.59,552.26Z"
      />
      <path
        class="cls-38"
        d="M955.46,612.4c-1.32-9.87-1.16-20.13,2.34-29.45s10.69-17.59,20.2-20.78c6.81-2.28,14.31-1.86,21.27,0,6,1.57,11.91,4.22,16.41,8.5,6.6,6.25,9.66,15.28,11.66,24.1,1.54,6.79,2.6,13.8,1.73,20.7-.72,5.69-2.74,11.13-4.74,16.51s-4,10.78-6.11,16.13a31.38,31.38,0,0,1-4.24,8.19c-4,5-9.72,7.42-16,10.29-3,1.39-6.74,3.09-10.09,3.06a28.28,28.28,0,0,1-13.33-4.07c-3-1.9-6.9-5.77-9.32-8.38-5.51-5.94-7.61-12.47-9.25-20.36s-.78-17.57-.56-25.62"
      />
      <path
        d="M966.17,570.44c-5,.37-8.87,4.68-11.1,9.2a33.62,33.62,0,0,0-.79,27.61c3-7.84,7.78-9.07,7.9-25.16,0-3.08,2.42-5.05,4.47-7.34,1-1.11,2.91-1.19,4.07-2.1a31.82,31.82,0,0,1,5.56-3.77c3.89-1.91,10-2.66,14.29-2.34,13.4,1,23.09,2.59,31.57,13,1.88,2.32,4.43,10.74,5.54,13.51a29,29,0,0,1,2.14,15.68c-.37,2.33-2.38,8.75-.53,10.22,2.19,1.73,7-3.6,9.09-5.49a32.86,32.86,0,0,0,7.88-11.64c1.77-4.35,2.56-9,.93-13.38-1-2.81-2.89-5.78-1.79-8.56.47-1.18,3.66-2.32,3.58-3.59s-3.16-2.21-4.27-2.61c-5.08-1.84-7.84-2.79-13.6-9.07-5.3-5.78,1.42-5.62-.58-6.81s-7.89-2.13-12.06-3.65c-1.9-.69-4.72-2.87-6.41-4a40.55,40.55,0,0,0-15.33-5.53c-5-.71-14,2.84-17.48,6.46a3.9,3.9,0,0,1-1,.9,4.17,4.17,0,0,1-1.93.22c-3.52,0-3.59,1.94-5.92,4.59s-1.51,4.31-3,7.51c-.35-1.35-1.47-3.08-2.69-2.4a11.6,11.6,0,0,0-2.08,2.39,13.54,13.54,0,0,0-.84,8.78"
      />
      <path
        class="cls-38"
        d="M1029.21,620.64q-4.2,12.36-8.7,24.63a20.82,20.82,0,0,0,13.2-21c-.15-2-1-4.39-2.92-4.87"
      />
      <path
        class="cls-38"
        d="M954.16,610.9c-.19-1.6-2.39-2.45-3.95-1.86a5.73,5.73,0,0,0-3.05,3.62,19.57,19.57,0,0,0,0,13.12c1.59,4,5.83,8.3,8.5,11.72A175.88,175.88,0,0,1,954,611"
      />
      <path
        class="cls-39"
        d="M973.31,644.93c7.49,3.53,16.91,5.83,25.25,5.09,4.93-.44,4.76-6.91,0-7.54-4.26-.56-8.61-.63-12.89-1.05-3.68-.36-7.43-1.11-11.11-1.12-2.33,0-3.55,3.54-1.25,4.62Z"
      />
      <path
        class="cls-40"
        d="M1003.35,644.66C992,644.1,972,641.19,972,641.19a14.38,14.38,0,0,1,12.87-1.4,10,10,0,0,0,2.48.79,9.37,9.37,0,0,0,2.36-.18,17.81,17.81,0,0,1,14.09,4.26"
      />
      <path
        class="cls-40"
        d="M1003.77,644.74A23.07,23.07,0,0,1,972,641.11s-.07,0,0,.26c.67,4.53,4.55,9,8.66,11a19.92,19.92,0,0,0,5.85,1.73,18.33,18.33,0,0,0,10.1-1.19,14.26,14.26,0,0,0,7.25-8.1Z"
      />
      <path
        d="M972.18,640.43a2.94,2.94,0,0,1,0-.42c0-.12,0,.11,0,0s0-.07,0-.11a1.46,1.46,0,0,1,0-.2l0-.1s.06-.13,0,0l.09-.19a1.18,1.18,0,0,1,.11-.19l.06-.08c.07-.1-.08.09,0,0a2.13,2.13,0,0,1,.3-.3s.06-.07.08-.07l0,0a.1.1,0,0,1,0,0l.18-.12.19-.09c.09,0-.08,0,0,0l.1,0a1.78,1.78,0,0,0,.29-.12l0,0,0,0,.11,0a.45.45,0,0,0-.12-.89,1.41,1.41,0,0,0-.58,0,2.94,2.94,0,0,0-.51.23,3,3,0,0,0-1.48,2.69.55.55,0,0,0,.54.55.55.55,0,0,0,.54-.55Z"
      />
      <path
        d="M1002.41,641.7s0,0,0,0l.08.07.16.15.16.17.07.08,0,0h0a2.92,2.92,0,0,1,.24.37l.09.17a.93.93,0,0,0,.05.1c.07.16,0-.09,0,.08a3.38,3.38,0,0,1,.13.42c0,.06,0,.13,0,.19a.43.43,0,0,0,0,.11c0,.06,0-.07,0,0a5.53,5.53,0,0,1,0,.91c0,.1,0-.09,0,0s0,.08,0,.11a.79.79,0,0,0,0,.43.57.57,0,0,0,.71.42c.47-.13.53-.74.55-1.15a4.28,4.28,0,0,0-.43-2,3.92,3.92,0,0,0-1.41-1.66.51.51,0,0,0-.7.18.53.53,0,0,0,.18.7Z"
      />
      <path
        d="M984.14,602.81l-1.44-3.23a4.37,4.37,0,0,0-1-1.55,4.51,4.51,0,0,0-1.84-.82q-3.66-1-7.36-1.73a7.58,7.58,0,0,0-3-.19c-1.82.38-3.1,2-4.22,3.45,1.3-1.06,3.12-1.13,4.79-1a27.43,27.43,0,0,1,13.48,4.81"
      />
      <path
        d="M984.75,602.45c-.48-1.07-1-2.15-1.43-3.23a4.34,4.34,0,0,0-1.7-2.14,11.91,11.91,0,0,0-2.93-.93c-1.22-.32-2.45-.62-3.68-.9-2-.45-4.36-1.26-6.37-.49a9.61,9.61,0,0,0-4,3.62c-.42.56.41,1.4,1,1a6.19,6.19,0,0,1,4.43-.9,26,26,0,0,1,4.55.82,31.19,31.19,0,0,1,8.5,4c.94.59,1.71-.83.85-1.46a25.62,25.62,0,0,0-9.57-4.17c-2.9-.66-6.91-1.46-9.48.48l.86,1.11c1-1.21,2-2.62,3.52-3.11,1.8-.58,4.08.27,5.85.66a36.69,36.69,0,0,1,5.39,1.37c1,.4,1.34,1.34,1.76,2.27l1.23,2.74c.37.83,1.59.11,1.22-.72Z"
      />
      <path
        d="M1001.84,605.3l2-2.88a4.57,4.57,0,0,1,1.26-1.33,4.41,4.41,0,0,1,2-.44q3.76-.24,7.56-.24a7.61,7.61,0,0,1,3,.42c1.71.73,2.65,2.54,3.45,4.21-1.06-1.29-2.83-1.72-4.49-1.93a27.49,27.49,0,0,0-14.16,2"
      />
      <path
        d="M1002.46,605.66l1.64-2.3c.56-.79,1-1.65,2-1.88a16,16,0,0,1,2.86-.21c1,0,2-.07,3-.09,1.79,0,4.15-.4,5.76.55,1.34.79,2.06,2.33,2.73,3.67l1.12-.86c-2.1-2.37-6-2.42-9-2.38a26.13,26.13,0,0,0-10.63,2.27c-.86.4-.25,1.88.65,1.54a30.63,30.63,0,0,1,9.47-2.26c2.63-.14,6.55-.35,8.46,1.84.57.64,1.44-.18,1.12-.87-.76-1.59-1.63-3.31-3.2-4.26-1.87-1.12-4.44-.77-6.52-.72-1.26,0-2.52.09-3.78.16a10.12,10.12,0,0,0-2.9.36,4.33,4.33,0,0,0-2,1.71c-.73,1-1.43,2-2.14,3-.53.75.7,1.45,1.23.72Z"
      />
      <path
        class="cls-41"
        d="M976.91,598.64a11.43,11.43,0,1,0,10,12.67A11.42,11.42,0,0,0,976.91,598.64Zm-2.38,20.49a9.2,9.2,0,1,1,10.2-8.08A9.2,9.2,0,0,1,974.53,619.13Z"
      />
      <path
        class="cls-41"
        d="M1010.5,602.53a11.43,11.43,0,1,0,10,12.68A11.44,11.44,0,0,0,1010.5,602.53Zm-2.38,20.5a9.2,9.2,0,1,1,10.2-8.08A9.21,9.21,0,0,1,1008.12,623Z"
      />
      <path
        d="M1003,622.45c-3.32-.88-5.52-4.16-6.35-7.49-.43-1.72-.69-3.68-2.1-4.74a4.32,4.32,0,0,0-4.89.22,12.62,12.62,0,0,0-3.32,4,13.09,13.09,0,0,1-3.28,4l3-12a3.43,3.43,0,0,0,2.41.52c.84-.09,1.65-.35,2.48-.5a8.9,8.9,0,0,1,4.76.52c1.48.6,3,1.63,4.52,1.19l-1.32,7.38Z"
      />
      <path
        d="M967.16,616.88a17,17,0,0,1-2.85-4,24.43,24.43,0,0,0-1.75-3.6,3.68,3.68,0,0,0-3.37-1.77c-1.71.27-2.62,2.09-3.73,3.42a1.78,1.78,0,0,1-.83.64.73.73,0,0,1-.89-.36,1,1,0,0,1,0-.57,10.86,10.86,0,0,1,2.58-4.24c.95-1.12,2.08-2.34,3.55-2.41,1.1-.05,2.19.57,3.27.35a4.3,4.3,0,0,0,1.63-.9l3.62-2.75s-3.76,3-2.52,9.25Z"
      />
      <path
        d="M1015.57,622.5a16.78,16.78,0,0,0,3.7-3.28,25.16,25.16,0,0,1,2.53-3.1,3.68,3.68,0,0,1,3.69-1c1.6.66,2.07,2.63,2.84,4.18a1.84,1.84,0,0,0,.66.82.76.76,0,0,0,1-.15,1.08,1.08,0,0,0,.11-.56,10.75,10.75,0,0,0-1.54-4.71c-.67-1.32-1.48-2.77-2.91-3.17-1-.3-2.25.06-3.25-.41a4.12,4.12,0,0,1-1.38-1.25l-2.9-3.51s3,3.76.34,9.59Z"
      />
      <path
        class="cls-42"
        d="M968.51,571.87c-3-1.78-5.76-.93-8.52,1.13-5.36,4-7.94,10.86-8.27,17.54s1.31,13.26,2.94,19.74A30.72,30.72,0,0,1,958,600.06c.72-1.37,1.55-2.68,2.16-4.1a29.84,29.84,0,0,0,1.79-8.4,28.4,28.4,0,0,1,1.86-8.38c1.18-2.62,2.68-4.88,5.56-4.78,0,0,6-9,23.73-7.92,7.88.5,17.57,3.27,23.62,8.33,9.4,7.87,11.22,16.41,11.7,19.51,1.73,11.08.95,14.48.56,24.8,8.23-2.39,15.21-10.86,17.56-19.11,1.83-6.39,2.13-13.94-2.16-19a14.16,14.16,0,0,0,4-3.43,1.77,1.77,0,0,0,.52-1.37c-.15-.73-1-1-1.78-1.15a27.29,27.29,0,0,1-18.28-13.57c.76-1.06,2.54-1.67,2.31-3-.2-1.07-1.6-1.29-2.69-1.37-8.62-.66-15.22-7.83-23.29-10.92a25.91,25.91,0,0,0-21.22,1.33,11.05,11.05,0,0,0-5.15,4.86,9.71,9.71,0,0,0-10.08,6.76,17.1,17.1,0,0,0-.55,5.52c-.22-.31-.44-.61-.64-.92a4,4,0,0,0-2-1.88,2.49,2.49,0,0,0-2.3.71,5.31,5.31,0,0,0-1.22,2.17c-.59,1.79-.63,4.86.85,6.2"
      />
      <path
        class="cls-42"
        d="M954.12,612c-.35-1.89-2.06-3.64-4-3.4s-3.16,2.33-3.73,4.24a21.8,21.8,0,0,0,8.47,23.69"
      />
      <path
        class="cls-42"
        d="M1027.71,621.9c.76-1.75,2.47-2.94,4.27-2.28s2.53,3,2.65,5A21.8,21.8,0,0,1,1021,645.72"
      />
      <path
        d="M981.47,623.55a3.62,3.62,0,0,0-2.9,5.51,3.93,3.93,0,0,0,3.25,1.8,10,10,0,0,0,2.09-.4,2.44,2.44,0,0,1,1.36-.09c.46.16.77.65,1.1,1a4.79,4.79,0,0,0,3.59,1.43,4.89,4.89,0,0,0,1.88-.45c.65-.29,1.19-1.07,1.85-1.22s1.44.58,2,.91a4.07,4.07,0,0,0,1.89.53,4.3,4.3,0,0,0,3.27-1.45c2-2.3.43-5.89-2.44-6.45-.86-.17-1.22,1.14-.36,1.31a2.68,2.68,0,0,1,2.13,3.16,2.62,2.62,0,0,1-2.9,1.79c-1.26-.2-2.11-1.44-3.45-1.47s-1.92,1.23-3.09,1.59a3.42,3.42,0,0,1-3.65-1.19,3.2,3.2,0,0,0-3.15-1c-1.47.27-3,1-4.1-.52a2.46,2.46,0,0,1,1.72-3.71c.57-.11.45-1.09-.14-1Z"
      />
      <path
        class="cls-42"
        d="M1028.68,616.77a138.78,138.78,0,0,1-9,30.17,37.88,37.88,0,0,1-4.06,7.52c-3.35,4.5-8.36,7.42-13.25,10.17-5.15,2.9-10.82,5.83-16.59,5.71l-1.58-.17c-5.72-1.15-10.63-5.35-15-9.39C965,657,960.8,653,958.58,647.84a37.69,37.69,0,0,1-2.23-8.25,125.06,125.06,0,0,1-1.92-29.32"
      />
      <polyline
        class="cls-43"
        points="973.64 606.02 980.32 610.53 972.82 613.06"
      />
      <polyline
        class="cls-43"
        points="1011.99 610.47 1004.45 613.33 1011.17 617.51"
      />
      <path
        class="cls-38"
        d="M977.41,336.32c-1.44-10.71-1.26-21.84,2.53-31.95s11.6-19.09,21.92-22.54c7.39-2.48,15.52-2,23.07-.05,6.55,1.71,12.92,4.59,17.81,9.22,7.16,6.79,10.47,16.59,12.64,26.15,1.67,7.37,2.82,15,1.87,22.46-.78,6.17-3,12.07-5.13,17.91s-4.35,11.69-6.63,17.49a34.26,34.26,0,0,1-4.6,8.89c-4.32,5.46-10.55,8.05-17.34,11.16-3.29,1.51-7.31,3.35-10.94,3.32A30.68,30.68,0,0,1,998.15,394c-3.29-2.06-7.49-6.26-10.12-9.1-6-6.44-8.25-13.53-10-22.08s-.85-19.07-.61-27.79"
      />
      <path
        d="M989,290.8c-5.44.4-9.62,5.08-12,10a36.47,36.47,0,0,0-.86,30c3.21-8.51,8.44-9.84,8.57-27.29,0-3.34,2.63-5.47,4.85-8,1.07-1.2,3.16-1.28,4.42-2.28a35,35,0,0,1,6-4.09c4.22-2.06,10.82-2.88,15.5-2.53,14.54,1.08,25,2.81,34.25,14.13,2,2.51,4.81,11.64,6,14.65a31.5,31.5,0,0,1,2.33,17c-.4,2.53-2.59,9.49-.58,11.08,2.38,1.89,7.63-3.9,9.85-5.95a35.47,35.47,0,0,0,8.56-12.63c1.92-4.71,2.77-9.74,1-14.51-1.13-3.05-3.14-6.27-1.94-9.29.5-1.28,4-2.51,3.87-3.89s-3.42-2.39-4.62-2.83c-5.51-2-8.51-3-14.76-9.84-5.75-6.27,1.54-6.1-.63-7.39s-8.56-2.31-13.08-4c-2.06-.75-5.13-3.11-7-4.32a43.93,43.93,0,0,0-16.64-6c-5.38-.77-15.19,3.09-19,7a4.24,4.24,0,0,1-1.14,1,4.71,4.71,0,0,1-2.09.24c-3.82,0-3.9,2.1-6.42,5s-1.64,4.69-3.25,8.16c-.38-1.47-1.59-3.35-2.92-2.61a12.4,12.4,0,0,0-2.25,2.59,14.67,14.67,0,0,0-.91,9.53"
      />
      <path
        class="cls-38"
        d="M1057.41,345.25q-4.56,13.42-9.44,26.73a22.57,22.57,0,0,0,14.32-22.79c-.17-2.2-1-4.77-3.17-5.29"
      />
      <path
        class="cls-38"
        d="M976,334.7c-.2-1.75-2.59-2.67-4.28-2a6.2,6.2,0,0,0-3.31,3.94,21.23,21.23,0,0,0,0,14.22c1.73,4.31,6.33,9,9.23,12.72a189.93,189.93,0,0,1-1.81-28.78"
      />
      <path
        class="cls-39"
        d="M996.76,371.61c8,3.79,18.43,6.45,27.4,5.52,4.15-.43,6-7.32,1.09-8-4.53-.65-9.13-.69-13.68-1.14s-9-1.35-13.45-1.36c-2.52,0-3.85,3.84-1.36,5Z"
      />
      <path
        class="cls-44"
        d="M1029.36,371.32c-12.35-.61-34-3.77-34-3.77a15.65,15.65,0,0,1,14-1.52,10.82,10.82,0,0,0,2.69.86,10.06,10.06,0,0,0,2.56-.2,19.33,19.33,0,0,1,15.29,4.63"
      />
      <path
        class="cls-44"
        d="M1029.81,371.4a25,25,0,0,1-34.49-3.93s-.07,0,0,.28c.73,4.91,4.93,9.8,9.4,12a21.85,21.85,0,0,0,6.34,1.87,19.93,19.93,0,0,0,11-1.29,15.48,15.48,0,0,0,7.86-8.79Z"
      />
      <path
        d="M995.55,366.73a2.34,2.34,0,0,1,0-.46c0-.12,0,.12,0,0a.52.52,0,0,0,0-.11l.06-.23,0-.1s.07-.14,0,0a1.86,1.86,0,0,1,.1-.2l.12-.2.06-.1c.08-.1-.08.1,0,0a2.5,2.5,0,0,1,.32-.33s.06-.07.08-.07l-.05,0,.06-.05.19-.12.21-.11c.09,0-.09,0,0,0l.11,0,.31-.13,0,0,.06,0,.12,0a.49.49,0,0,0-.13-1,1.67,1.67,0,0,0-.63,0,4,4,0,0,0-.56.25,3.12,3.12,0,0,0-.86.72,3.06,3.06,0,0,0-.74,2.2.59.59,0,1,0,1.18,0Z"
      />
      <path
        d="M1028.34,368.1l0,0,.09.08a1.29,1.29,0,0,1,.17.16l.17.18.08.09,0,.05h0a3.42,3.42,0,0,1,.26.4,1.74,1.74,0,0,1,.1.19l0,.11c.08.17,0-.1,0,.08a3.53,3.53,0,0,1,.15.46l0,.2a.5.5,0,0,0,0,.12c0,.07,0-.07,0,0a6.53,6.53,0,0,1,0,1c0,.11,0-.09,0,0a.5.5,0,0,0,0,.12.89.89,0,0,0,0,.46.62.62,0,0,0,.77.45c.51-.14.57-.8.59-1.25a4.58,4.58,0,0,0-.46-2.12,4.38,4.38,0,0,0-1.53-1.8.55.55,0,1,0-.56,1Z"
      />
      <path
        d="M1008.52,325.92l-1.57-3.5a3.56,3.56,0,0,0-3-2.58c-2.65-.71-5.31-1.33-8-1.88a8.28,8.28,0,0,0-3.28-.2c-2,.41-3.37,2.13-4.58,3.74,1.4-1.15,3.38-1.23,5.19-1.09a29.84,29.84,0,0,1,14.62,5.22"
      />
      <path
        d="M1009.18,325.53c-.52-1.17-1-2.34-1.56-3.51a4.59,4.59,0,0,0-1.84-2.32,13,13,0,0,0-3.18-1c-1.32-.35-2.65-.67-4-1-2.17-.49-4.73-1.37-6.92-.53-1.88.71-3.14,2.36-4.31,3.92-.45.61.45,1.52,1.06,1.06a6.72,6.72,0,0,1,4.8-1,27.67,27.67,0,0,1,4.94.89,33.65,33.65,0,0,1,9.22,4.33c1,.65,1.85-.9.93-1.58A27.91,27.91,0,0,0,998,320.32c-3.14-.71-7.49-1.58-10.28.52l.93,1.21c1-1.32,2.17-2.85,3.82-3.38,2-.63,4.42.29,6.34.72a40.41,40.41,0,0,1,5.85,1.48c1.1.43,1.46,1.46,1.91,2.46l1.33,3c.41.91,1.73.12,1.33-.77Z"
      />
      <path
        d="M1027.72,328.61l2.23-3.12a3.57,3.57,0,0,1,3.49-1.92c2.73-.17,5.47-.25,8.2-.26a8.12,8.12,0,0,1,3.26.46c1.86.79,2.87,2.75,3.75,4.57-1.15-1.41-3.08-1.88-4.88-2.1a29.84,29.84,0,0,0-15.36,2.22"
      />
      <path
        d="M1028.39,329l1.78-2.49c.61-.85,1.14-1.78,2.22-2a16.9,16.9,0,0,1,3.1-.22c1.09-.05,2.19-.08,3.28-.1,2,0,4.5-.43,6.25.6,1.46.86,2.24,2.52,3,4l1.21-.94c-2.27-2.57-6.55-2.63-9.71-2.58a28.56,28.56,0,0,0-11.53,2.46c-.93.44-.27,2.05.7,1.68a33.38,33.38,0,0,1,10.27-2.46c2.86-.15,7.11-.38,9.18,2,.62.7,1.56-.19,1.21-.93-.81-1.73-1.76-3.6-3.46-4.62-2-1.23-4.82-.84-7.08-.78-1.37,0-2.73.09-4.1.17a10.69,10.69,0,0,0-3.14.39,4.64,4.64,0,0,0-2.16,1.86c-.78,1.07-1.54,2.17-2.31,3.26-.58.81.76,1.57,1.33.77Z"
      />
      <path
        class="cls-45"
        d="M1000.68,321.39a12.4,12.4,0,1,0,10.88,13.74A12.39,12.39,0,0,0,1000.68,321.39Zm-2.58,22.23a10,10,0,1,1,11.06-8.77A10,10,0,0,1,998.1,343.62Z"
      />
      <path
        class="cls-45"
        d="M1037.11,325.62A12.4,12.4,0,1,0,1048,339.36,12.39,12.39,0,0,0,1037.11,325.62Zm-2.58,22.23a10,10,0,1,1,11.07-8.77A10,10,0,0,1,1034.53,347.85Z"
      />
      <path
        d="M1029,347.22c-3.61-1-6-4.51-6.9-8.13-.46-1.85-.74-4-2.27-5.14a4.67,4.67,0,0,0-5.3.25,13.36,13.36,0,0,0-3.6,4.32,14.16,14.16,0,0,1-3.57,4.36l3.29-13a3.68,3.68,0,0,0,2.62.57c.91-.1,1.78-.38,2.68-.54a9.61,9.61,0,0,1,5.17.57c1.6.65,3.24,1.76,4.9,1.29l-1.43,8Z"
      />
      <path
        d="M990.1,341.18A18.28,18.28,0,0,1,987,336.8a28.81,28.81,0,0,0-1.9-3.91,4,4,0,0,0-3.66-1.91c-1.86.29-2.84,2.26-4.05,3.71a2,2,0,0,1-.9.69.78.78,0,0,1-1-.4,1,1,0,0,1,0-.61,11.88,11.88,0,0,1,2.8-4.6c1-1.22,2.25-2.54,3.85-2.62,1.2,0,2.38.63,3.54.38a4.6,4.6,0,0,0,1.78-1l3.92-3s-4.08,3.23-2.73,10Z"
      />
      <path
        d="M1042.61,347.27a18.4,18.4,0,0,0,4-3.55,27.8,27.8,0,0,1,2.74-3.37,4,4,0,0,1,4-1c1.74.72,2.25,2.86,3.09,4.54a1.94,1.94,0,0,0,.71.88.79.79,0,0,0,1-.16,1.1,1.1,0,0,0,.12-.6,11.82,11.82,0,0,0-1.67-5.12c-.72-1.42-1.61-3-3.15-3.43-1.15-.33-2.45.06-3.53-.44a4.53,4.53,0,0,1-1.5-1.36l-3.14-3.81s3.23,4.08.36,10.41Z"
      />
      <path
        class="cls-46"
        d="M991.56,292.36c-3.19-1.94-6.24-1-9.24,1.21-5.82,4.33-8.62,11.79-9,19S974.77,327,976.53,334a33.64,33.64,0,0,1,3.59-11.09c.79-1.48,1.69-2.9,2.35-4.44a32.42,32.42,0,0,0,1.95-9.12,30.29,30.29,0,0,1,2-9.09c1.27-2.85,2.89-5.29,6-5.18,0,0,6.47-9.81,25.75-8.59,8.54.54,19.06,3.54,25.62,9,10.2,8.53,12.17,17.79,12.69,21.15,1.87,12,1,15.72.61,26.91,8.93-2.59,16.5-11.78,19-20.73,2-6.93,2.31-15.12-2.35-20.63a15.18,15.18,0,0,0,4.34-3.71,1.93,1.93,0,0,0,.57-1.49c-.16-.8-1.13-1.08-1.93-1.25A29.63,29.63,0,0,1,1057,281.08c.83-1.15,2.76-1.81,2.51-3.21-.21-1.16-1.74-1.4-2.91-1.49-9.36-.71-16.51-8.49-25.27-11.85a28.2,28.2,0,0,0-23,1.45c-2.3,1.23-4.51,2.89-5.57,5.27a10.53,10.53,0,0,0-10.94,7.33,18.58,18.58,0,0,0-.6,6l-.7-1a4.33,4.33,0,0,0-2.19-2,2.67,2.67,0,0,0-2.49.78,5.65,5.65,0,0,0-1.32,2.35c-.65,1.94-.69,5.27.92,6.72"
      />
      <path
        class="cls-46"
        d="M976,335.86c-.38-2-2.23-3.94-4.3-3.69s-3.42,2.53-4,4.61a23.64,23.64,0,0,0,9.19,25.69"
      />
      <path
        class="cls-46"
        d="M1055.78,346.63c.83-1.91,2.68-3.2,4.63-2.48s2.75,3.25,2.88,5.41a23.64,23.64,0,0,1-14.83,22.9"
      />
      <path
        d="M1005.62,348.41a3.93,3.93,0,0,0-3.14,6,4.21,4.21,0,0,0,3.52,1.95,9.79,9.79,0,0,0,2.27-.43,2.58,2.58,0,0,1,1.48-.09c.49.17.83.7,1.18,1.06a5.29,5.29,0,0,0,5.94,1.06c.7-.32,1.3-1.16,2-1.32s1.56.63,2.22,1a4.37,4.37,0,0,0,2.06.58,4.69,4.69,0,0,0,3.54-1.57c2.19-2.5.47-6.4-2.65-7-.93-.19-1.32,1.24-.39,1.42a2.9,2.9,0,0,1,2.31,3.42,2.83,2.83,0,0,1-3.14,1.95c-1.37-.22-2.3-1.57-3.75-1.6s-2.08,1.34-3.34,1.73a3.74,3.74,0,0,1-4-1.3,3.52,3.52,0,0,0-3.42-1.11c-1.59.29-3.27,1.08-4.45-.56a2.66,2.66,0,0,1,1.87-4c.62-.13.49-1.19-.15-1.13Z"
      />
      <path
        class="cls-46"
        d="M1056.83,341.06a150.19,150.19,0,0,1-9.79,32.73,41.4,41.4,0,0,1-4.4,8.16c-3.64,4.88-9.07,8.05-14.37,11-5.59,3.15-11.74,6.33-18,6.19l-1.71-.18c-6.21-1.24-11.53-5.8-16.3-10.19-4.48-4.12-9-8.44-11.46-14a40.57,40.57,0,0,1-2.42-8.95A136.4,136.4,0,0,1,976.28,334"
      />
      <circle cx="1033.91" cy="337.45" r="2.95" />
      <circle cx="999.62" cy="332.63" r="2.95" />
      <path
        class="cls-38"
        d="M613.64,487.25c-3.9-9.16-6.48-19.1-5.59-29s5.62-19.82,14-25.43c6-4,13.3-5.6,20.5-5.71,6.24-.09,14.27-2.53,19.76.39,8,4.27,11.72,15.61,16,23.58,3.3,6.14,6.19,12.61,7.19,19.5.82,5.68,1.54,10.44,1,16.16s.93,11.31.32,17a47,47,0,0,1-2.52,11.06c-2.5,5.91-8.41,9.58-13.68,14-2.55,2.15-7.18,4.1-10.42,5a28.14,28.14,0,0,1-13.94-.37c-3.43-1-9.57-3.12-12.6-5-6.89-4.26-10.67-8.64-14.35-15.81s-4.06-18.7-6-26.51"
      />
      <path
        d="M647.38,419.19c-4.81-3.13-13.63-3.78-18.9-1.5-7.4,3.2-14.92,10.74-19,17.7-5.08,8.71-.25,22.18-10,28.21,6.7,4.42,7.69,17,12,23.42,1.65-2.58.13-12.08,1.08-16.84.18-.92-.61-1.59,0-2.32a7,7,0,0,1,2.83-1.75c6.43-2.8,14.67-7.86,17.3-14.36,1.45-3.59,1.51-9.09,2.8-12.74,1.16-3.28,1.6-3.39,3.69-6.17,1.3-1.74,8-4.8,14.23,1.89,1.74,1.86,3.87-1.94,6.42-1.82,3.9.19,7.5,1.61,9.52,4.95a73.28,73.28,0,0,1,8.51,18.77c.65,2,2.94,2.24,3.56,3.54,1.77,3.72,1.52,7.9,4.57,10.65a11,11,0,0,1,5.51-7.34c1.07-.56,2.42-1.23,2.38-2.44a2.76,2.76,0,0,0-.39-1.16,114.54,114.54,0,0,1-8-20.72,52.7,52.7,0,0,0-2.81-8.54,24.81,24.81,0,0,0-14.1-12.24c-6-2.15-13.74-.47-20,.84"
      />
      <path
        class="cls-47"
        d="M687,475.54c-.5,8.7.19,18.9-.53,27.59,3.56-2.65,5.89-6.84,7.16-11.09s.18-10-1.33-14.2c-.69-1.91-2.09-4-4.12-3.92"
      />
      <path
        class="cls-47"
        d="M612,486.16c-.61-1.5-3-1.73-4.31-.75a5.71,5.71,0,0,0-2,4.31c-.32,4.21.94,9.14,2.51,13.12,2.6,6.6,8.78,6,12.27,8.58a173.67,173.67,0,0,1-8.68-25.14"
      />
      <path
        class="cls-48"
        d="M669,505.7c-11.13,2.5-31.15,5-31.15,5a14.4,14.4,0,0,1,12-4.78,10.4,10.4,0,0,0,2.61.1,9.19,9.19,0,0,0,2.23-.81,17.86,17.86,0,0,1,14.73.36"
      />
      <path
        class="cls-48"
        d="M637.93,511.12C649.18,508,669.12,506,669.12,506,655.82,521.39,637.93,511.12,637.93,511.12Z"
      />
      <path
        d="M638.87,471.24l-2-2.9a3.3,3.3,0,0,0-3.21-1.81c-2.51-.18-5-.29-7.56-.32a7.62,7.62,0,0,0-3,.38c-1.72.72-2.68,2.52-3.5,4.18,1.07-1.28,2.86-1.69,4.52-1.88a27.49,27.49,0,0,1,14.15,2.2"
      />
      <path
        d="M639.48,470.88c-.67-1-1.33-1.94-2-2.91a4.62,4.62,0,0,0-1.92-1.8,9.39,9.39,0,0,0-3-.44c-1.26-.09-2.52-.15-3.78-.2-2.06-.08-4.53-.43-6.41.59-1.65.89-2.58,2.67-3.36,4.3-.33.68.54,1.5,1.11.86,1.94-2.16,5.87-1.91,8.5-1.75A31.12,31.12,0,0,1,638,471.9c.89.35,1.51-1.13.65-1.54A26.24,26.24,0,0,0,628.05,468c-2.92-.07-6.86-.06-9,2.29l1.12.86a8.14,8.14,0,0,1,2.77-3.64c1.63-.92,4-.54,5.77-.48,1,0,2,.07,3,.12a15.13,15.13,0,0,1,2.86.24c1,.26,1.47,1.11,2,1.91l1.61,2.31c.52.75,1.75,0,1.23-.71Z"
      />
      <path
        d="M656.59,468.23l1.14-3.35a3.29,3.29,0,0,1,2.58-2.62q3.56-1.31,7.17-2.4a7.39,7.39,0,0,1,3-.46c1.85.21,3.27,1.67,4.52,3-1.38-.93-3.21-.84-4.86-.55a27.38,27.38,0,0,0-13,6"
      />
      <path
        d="M657.27,468.42l.92-2.68a3.85,3.85,0,0,1,1.41-2.39,14.86,14.86,0,0,1,2.6-1c1-.34,1.93-.66,2.9-1,1.68-.54,3.8-1.55,5.59-1.18,1.58.32,2.76,1.62,3.81,2.75l.86-1.12c-2.82-1.74-6.85-.54-9.74.43a26.17,26.17,0,0,0-9.06,5.05c-.71.63.25,1.92,1,1.33a31.3,31.3,0,0,1,8-4.74,27.4,27.4,0,0,1,4.44-1.26,6.31,6.31,0,0,1,4.62.42c.7.44,1.38-.53.86-1.12-1.18-1.31-2.51-2.72-4.28-3.18-2.15-.55-4.51.54-6.52,1.19-1.14.38-2.28.77-3.41,1.18a11.18,11.18,0,0,0-2.79,1.21,4.35,4.35,0,0,0-1.42,2.21c-.41,1.16-.79,2.33-1.19,3.49-.29.87,1.08,1.24,1.37.38Z"
      />
      <circle cx="634.38" cy="478.79" r="3.22" />
      <circle cx="664.87" cy="474.06" r="3.22" />
      <path
        class="cls-49"
        d="M612.23,487.21c-.83-1.73-3-3-4.72-2.22s-2.42,3.09-2.46,5.08c-.2,9,4.16,17.47,12.68,20.31"
      />
      <path
        class="cls-49"
        d="M685.85,477.17c.27-1.9,1.6-3.5,3.51-3.34s3.25,2.21,3.89,4.1c2.91,8.49,1.31,18.4-6,23.7"
      />
      <path
        class="cls-49"
        d="M685.42,472c1,9.52,2.63,25.86,1.26,35.37a23.15,23.15,0,0,1-4.14,9.39c-5.65,8.15-13.26,14.91-22.83,17.94-7.2,2.29-16.19-1-22.62-4.32a51.16,51.16,0,0,1-9.35-5.66,29.49,29.49,0,0,1-6.85-8.39,55,55,0,0,1-3.69-10c-2.09-6.84-3.52-13.84-5.12-20.81"
      />
      <path
        d="M645.69,496.53l.58.05h.19l.29,0c.36.06.72.14,1.07.23s.7.21,1,.34c.15.05.31.13.48.17a.65.65,0,0,0,.81-.59c0-.58-.64-.75-1.08-.91s-.69-.23-1.05-.32a13.13,13.13,0,0,0-2.34-.38.68.68,0,0,0,0,1.35Z"
      />
      <path
        d="M640.89,493a2.69,2.69,0,0,0-.73,2.77,3.13,3.13,0,0,0,2.35,2.08,3.18,3.18,0,0,0,1.88-.1.45.45,0,0,0-.12-.88,6.46,6.46,0,0,1-.88,0,2.36,2.36,0,0,1-.74-.1,2.21,2.21,0,0,1-1.21-.76,2,2,0,0,1-.43-1.23,1.62,1.62,0,0,1,.47-1.21.42.42,0,0,0,0-.58.43.43,0,0,0-.59,0Z"
      />
      <path
        d="M656.59,493.49a12.25,12.25,0,0,0-2,1c-.29.18-.58.37-.85.57l-.52.4a1.1,1.1,0,0,0-.45.57.62.62,0,0,0,.6.79.9.9,0,0,0,.51-.2,1.73,1.73,0,0,1,.21-.17l.09-.08,0,0,0,0,.1-.07c.28-.2.56-.39.85-.57s.58-.33.87-.48l.44-.2.14-.06c.09,0-.1,0,0,0l.25-.1a.69.69,0,0,0,.47-.83.67.67,0,0,0-.82-.47Z"
      />
      <path
        d="M660.08,490.71a1.88,1.88,0,0,1,.8,2.32,3.1,3.1,0,0,1-2.43,1.67c-.51.12-.36.85.12.88a3.2,3.2,0,0,0,2-.61,3.25,3.25,0,0,0,1.31-1.72A2.75,2.75,0,0,0,660.4,490c-.43-.18-.73.54-.32.75Z"
      />
      <path
        d="M611.6,467.91c7.62.83,15-4.53,19.3-10.39a28.61,28.61,0,0,0,4.93-10.8c.46-2.17.43-4.47,1-6.6,1.33-4.95,7.08-10.11,12.17-6.69,2.38,1.61,3,3.35,6.12,2.72,4-.81,6.4-2.39,10.31-.14,3.18,1.81,4.62,4.61,5.89,7.93,2.8,7.34,4.66,17.14,13.8,18.69,1.17.2,1.68-1.58.5-1.8-12-2.18-9.3-18.42-16.9-25.34a12,12,0,0,0-9.62-3c-3,.38-5.09,1.55-8-.24-1.21-.75-1.79-1.72-3.2-2.19-7.37-2.46-13,5.88-14,12-1.63,10.54-9.74,24.37-22.35,23-1.82-.19-1.81,2.66,0,2.86Z"
      />
      <path
        d="M612.83,466c-2,3.89-1.44,9.24-1.2,13.43.21,3.66.58,7.32,1.09,10.95l1.33-.77c-7-7.5-6.22-19.47-13.75-26.66l-.44,1.68c4.2-1.53,5.65-5.25,6.08-9.47.77-7.65,1.29-15,5.6-21.66,4-6.13,10.07-12.46,17.22-14.7,6.9-2.16,12.9-.37,19.59,1.18.92.21,1.91-1.06,1-1.75-9.07-7-23.72-2.59-31.45,4.13a39.78,39.78,0,0,0-11.47,16,44.56,44.56,0,0,0-2.2,9.83c-.58,4.76.77,12.38-4.92,14.55a1,1,0,0,0-.45,1.67c7.54,7,7.06,18.91,14,26.36.6.64,1.43-.06,1.32-.77-.45-3.14-.77-6.3-1-9.46-.29-4.16-1.11-9.88.88-13.73.45-.87-.86-1.64-1.31-.77Z"
      />
      <path
        d="M648.25,420.23a26.6,26.6,0,0,1,21-.61c7,2.94,10.37,9.06,13,15.81,3.53,9.05,4.08,20.56,12.52,26.68l-.53-2c-2,3-5.61,4.4-7.55,7.44-2.08,3.26-1.29,7.41-2.06,11l2.39.33c-.67-5.85-1.08-12-2.77-17.65a1,1,0,0,0-2,.55c1.08,5.64,1.69,11.4,2.36,17.1.15,1.23,2.08,1.78,2.38.32.93-4.54.08-8.73,3.93-12.11,2.09-1.83,4.27-3.2,5.89-5.52a1.52,1.52,0,0,0-.53-2c-6.41-4.8-7-13.61-9.4-20.66-2-5.76-4.33-12.22-8.6-16.72-8-8.46-21.14-8.91-31.06-3.74-1.19.62-.15,2.32,1,1.78Z"
      />
      <text class="cls-50" transform="translate(708.23 463.82)">
        @簡少年
        <tspan class="cls-51" x="70.18" y="0">
          想請教老師！眼神媚和似睡非睡
        </tspan>
        <tspan class="cls-51">
          <tspan x="0" y="20.6">都是主自己喜歡追求異性</tspan>
          <tspan class="cls-52" x="187.89" y="20.6"></tspan>
          <tspan class="cls-53" x="189.52" y="20.6">，</tspan>
          <tspan class="cls-54" x="203.25" y="20.6"></tspan>
          <tspan x="207.89" y="20.6">桃花不一定旺嗎？</tspan>
        </tspan>
      </text>
      <text class="cls-55" transform="translate(725.46 176.42)">
        @簡少年
        <tspan class="cls-56" x="75.68" y="0">想請教老師</tspan>
        <tspan class="cls-57" x="167.78" y="0">!</tspan>
        <tspan class="cls-56" x="177.51" y="0">如何從面相分析</tspan>
        <tspan class="cls-56">
          <tspan x="0" y="20.6">對方個性呢？例如：個性不服輸</tspan>
        </tspan>
      </text>
      <text class="cls-58" transform="translate(724.63 587.87)">
        桃花雖然旺
        <tspan class="cls-59" x="102.93" y="0"></tspan>
        <tspan class="cls-60" x="104.56" y="0">，</tspan>
        <tspan class="cls-61" x="118.29" y="0"></tspan>
        <tspan x="122.93" y="0">但也還是</tspan>
        <tspan x="0" y="20.6">容易會有爛桃花哦</tspan>
      </text>
      <text class="cls-62" transform="translate(707.35 321.91)">
        髮角
        <tspan class="cls-63" x="37.77" y="0">，</tspan>
        <tspan class="cls-64" x="51.5" y="0"></tspan>
        <tspan x="56.14" y="0">眉峰</tspan>
        <tspan class="cls-65" x="93.91" y="0">，</tspan>
        <tspan class="cls-66" x="107.64" y="0"></tspan>
        <tspan x="112.28" y="0">鼻子大</tspan>
        <tspan class="cls-65" x="168.12" y="0">，</tspan>
        <tspan class="cls-64" x="181.85" y="0"></tspan>
        <tspan class="cls-67" x="186.49" y="0">鼻子挺</tspan>
        <tspan class="cls-65" x="242.34" y="0">，</tspan>
        <tspan class="cls-64" x="256.06" y="0"></tspan>
        <tspan x="0" y="20.6">眼神銳利</tspan>
        <tspan class="cls-63" x="73.92" y="20.6">，</tspan>
        <tspan class="cls-64" x="87.64" y="20.6"></tspan>
        <tspan x="92.28" y="20.6">代表比較不服輸哦！</tspan>
      </text>
      <path
        class="cls-68"
        d="M643.47,157.93a7.72,7.72,0,0,0-7.79-.91,15.2,15.2,0,0,0-6.23,5.25c-5.9,7.89-7.6,18.16-7.9,28A229.45,229.45,0,0,1,619.2,219c-.91,5.87-1.23,12.91,1,18.4a19.52,19.52,0,0,0,5.45,7c3.35,2.94,7.37,5.37,11.81,5.8,2.33.22,4.7-.12,7,.08,3.25.28,2.8,2.34,6.05,2.6s10.56-1.71,11.71-4.75c8.24,2.46,16.82-1.9,24.32-6.12A15.36,15.36,0,0,0,698,247c8.81.25,17.08-5.69,21.13-13.53s4.4-15.1,1.09-23.27c-3.52-8.68-7.18-17.77-11.16-25.64-4.51-9-10.22-22.8-17.08-30.11s-15.4-12.83-25.42-13.29-20.49,6.79-24,16.17"
      />
      <path
        class="cls-38"
        d="M636.64,208.67a101.94,101.94,0,0,1,.25-23c1.07-8.87-1.64-11.25,6-16.05,5.45-3.43,3.29-5.54,9.75-5.44,5.59.09,13.92,12,18.76,14.76,7.08,4.06,12.11,12.34,28.92,16.6,5,1.27,1.7,9.17,1.1,14.29s-1.2,10.25-1.9,15.36a28.32,28.32,0,0,1-2,8c-2.4,5.23-6.9,8.53-11.75,12.37-2.35,1.86-5.22,4.12-8.14,4.81a25.36,25.36,0,0,1-12.5-.72,50.13,50.13,0,0,1-9.91-5.33c-6.06-4-9.29-9.26-12.39-15.79s-4.42-15.16-5.94-22.22"
      />
      <circle cx="655.69" cy="205.1" r="3.03" />
      <circle cx="680.98" cy="201.96" r="3.03" />
      <path
        class="cls-38"
        d="M635.73,206.41c-.5-1.36-2.6-1.63-3.84-.79a5.14,5.14,0,0,0-1.88,3.81,17.5,17.5,0,0,0,2.74,11.45c2.23,3.13,6.85,6,9.91,8.42a157,157,0,0,1-7.09-22.79"
      />
      <path
        class="cls-69"
        d="M635.92,207.36c-.7-1.57-2.56-2.73-4.17-2.12s-2.26,2.7-2.35,4.49a19.56,19.56,0,0,0,12.42,18.85"
      />
      <path
        class="cls-38"
        d="M701.82,197.89c.16-1.44,2.12-2.22,3.53-1.7a5.12,5.12,0,0,1,2.76,3.23,17.55,17.55,0,0,1,.13,11.77c-1.4,3.58-5.17,7.5-7.55,10.59A157.09,157.09,0,0,0,702,198"
      />
      <path
        class="cls-69"
        d="M701.86,198.86c.3-1.7,1.82-3.28,3.53-3.08s2.85,2.07,3.38,3.78a19.57,19.57,0,0,1-7.44,21.32"
      />
      <path
        class="cls-69"
        d="M701.44,195.58c.67,8.57.55,19.86-.94,28.34a37.75,37.75,0,0,1-1.93,7.43c-3.46,8.69-17.73,18-22.79,19.13l-1.41.19c-5.24.21-21-5.22-26.93-13a32.53,32.53,0,0,1-3.7-6.72,112.91,112.91,0,0,1-7.91-25.16"
      />
      <path
        class="cls-69"
        d="M658.85,191.78a3.94,3.94,0,0,0-2.57-.31,23.21,23.21,0,0,0-13.19,6"
      />
      <path
        class="cls-69"
        d="M674.26,189.87a4,4,0,0,1,2.42-.93,23.16,23.16,0,0,1,14.25,2.56"
      />
      <path
        class="cls-69"
        d="M667.61,211.22c0,1.9-.17,5.41.76,7.06a5.57,5.57,0,0,1,1,2"
      />
      <path class="cls-69" d="M673.87,219.35a3.5,3.5,0,0,1,1.67-1.29" />
      <path
        class="cls-69"
        d="M703.1,195.87c-16.79-1.5-30.48-16.45-42.32-26.37-2.49-2.09-5.14-4.17-8.3-4.92-6-1.41-9.41,5.48-9.41,5.48a7.25,7.25,0,0,0-5.19,4.37,18.14,18.14,0,0,0-1.26,6.91c-.25,7.5-.61,25.29-.87,25"
      />
      <path
        class="cls-70"
        d="M663.35,230.2a20.09,20.09,0,0,0,10.24,1.92c2.07-.19,5.25-.67,6.53-2.61,1-1.5-.07-2.82-1.68-3a36.06,36.06,0,0,0-4.76.25l-7,.24c-2.21.08-2.17,3.6,0,3.41l7-.62c.25,0,4.26-.71,4.44-.48l-.36-1.38c-.5,1.31-5.21,1.47-6.14,1.48A18.76,18.76,0,0,1,664.3,228c-1.29-.51-2.22,1.68-.95,2.25Z"
      />
      <path class="cls-69" d="M661.81,227c5.52,4.65,15.63,2.81,19.62-1.74" />
      <path
        class="cls-69"
        d="M662.78,227.7a5.44,5.44,0,0,0,2.91.14c1.33-.31,2.65-1.13,4-.76.42.11.79.34,1.21.42,1.26.22,2.33-.94,3.59-1.14a7.84,7.84,0,0,1,2.3.21,6.58,6.58,0,0,0,4.47-1"
      />
      <path
        class="cls-69"
        d="M662.32,228a8,8,0,0,0,3.6,3.69,11.45,11.45,0,0,0,5.08,1.22,12,12,0,0,0,6.64-1.7,8.45,8.45,0,0,0,3.85-5.55"
      />
      <path class="cls-69" d="M643.92,250.25c5,3.2,15.4,3.31,18.92-2.34" />
      <path
        class="cls-69"
        d="M642.39,156.33c-4.2-.86-8.6,1.16-11.51,4.31s-4.54,7.25-5.7,11.37c-3.52,12.53-3.24,25.76-4.67,38.7a144.26,144.26,0,0,0-1.35,14.37,29.32,29.32,0,0,0,2.87,14,20.89,20.89,0,0,0,25.21,10.12"
      />
      <path
        class="cls-69"
        d="M642.7,156.48c4.59-9.11,13.42-15.93,23.62-15.78,9.67.14,18.33,6.23,24.81,13.42a71.41,71.41,0,0,1,9.87,14,88.69,88.69,0,0,1,5,10.39c1.62,4.16,3.31,8.29,5.23,12.33,3.91,8.26,8.79,16.3,10.27,25.32a30,30,0,0,1-1,14.39,23,23,0,0,1-8.46,11.54c-7,4.84-15.44,6.86-23,2.19"
      />
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style scoped>
.cls-1,
.cls-26,
.cls-29,
.cls-42,
.cls-43,
.cls-46,
.cls-49,
.cls-69 {
  fill: none;
}

.cls-2 {
  fill: #f4f1f4;
}

.cls-3 {
  font-size: 65px;
  letter-spacing: 0.04em;
}

.cls-27,
.cls-3,
.cls-33,
.cls-50,
.cls-55,
.cls-58,
.cls-62 {
  /* font-family: GenEi-M-Gothic-v2-Bold, GenEi M Gothic v2; */
  font-weight: 700;
}

.cls-4 {
  font-size: 31px;
}

.cls-19,
.cls-20,
.cls-22,
.cls-4,
.cls-53,
.cls-54,
.cls-60,
.cls-61,
.cls-63,
.cls-64,
.cls-65,
.cls-66 {
  fill: #3e3a39;
}

.cls-18,
.cls-4 {
  /* font-family: GenEi-M-Gothic-v2-Medium, GenEi M Gothic v2; */
  font-weight: 500;
}

.cls-5 {
  letter-spacing: 0.06em;
}

.cls-6 {
  letter-spacing: -0.06em;
}

.cls-10,
.cls-16,
.cls-19,
.cls-20,
.cls-22,
.cls-7,
.cls-8 {
  /* font-family: NotoSansHant-Medium-B5pc-H, Noto Sans T Chinese; */
}

.cls-10,
.cls-16,
.cls-17,
.cls-19,
.cls-20,
.cls-22,
.cls-34,
.cls-35,
.cls-37,
.cls-53,
.cls-54,
.cls-57,
.cls-60,
.cls-61,
.cls-63,
.cls-64,
.cls-65,
.cls-66,
.cls-7,
.cls-8 {
  font-weight: 400;
}

.cls-7 {
  letter-spacing: -0.21em;
}

.cls-8 {
  letter-spacing: 0.04em;
}

.cls-9 {
  letter-spacing: 0.08em;
}

.cls-11,
.cls-14 {
  letter-spacing: 0.1em;
}

.cls-12,
.cls-13 {
  letter-spacing: 0.08em;
}

.cls-13,
.cls-14,
.cls-27,
.cls-30,
.cls-33,
.cls-50,
.cls-55 {
  fill: #ff5445;
}

.cls-15 {
  letter-spacing: 0.06em;
}

.cls-16 {
  letter-spacing: -0.21em;
}

.cls-17 {
  /* font-family: jf-jinxuanlatte-1.1-Medium-B5pc-H, 'jf-jinxuanlatte-1.1'; */
  letter-spacing: 0.04em;
}

.cls-18 {
  font-size: 28px;
  letter-spacing: 0.06em;
}

.cls-19 {
  letter-spacing: -0.19em;
}

.cls-20,
.cls-33 {
  font-size: 29px;
}

.cls-20 {
  letter-spacing: -0.19em;
}

.cls-21 {
  letter-spacing: 0.06em;
}

.cls-22 {
  letter-spacing: -0.19em;
}

.cls-23 {
  fill: #366080;
}

.cls-24 {
  fill: #ffcbbc;
}

.cls-25,
.cls-32 {
  fill: #f9dacf;
}

.cls-25,
.cls-26,
.cls-40,
.cls-41,
.cls-42,
.cls-43,
.cls-44,
.cls-45,
.cls-46,
.cls-48,
.cls-49,
.cls-69 {
  stroke: #000;
}

.cls-25,
.cls-26,
.cls-29,
.cls-40,
.cls-42,
.cls-43,
.cls-44,
.cls-46,
.cls-48,
.cls-49,
.cls-69 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-25,
.cls-26 {
  stroke-width: 4.66px;
}

.cls-27 {
  font-size: 58.29px;
  letter-spacing: 0.02em;
}

.cls-28 {
  fill: #000;
}

.cls-29 {
  stroke: #ff5445;
  stroke-width: 4.52px;
}

.cls-31 {
  clip-path: url(#clip-path);
}

.cls-33 {
  letter-spacing: 0.08em;
}

.cls-34,
.cls-35,
.cls-37,
.cls-53,
.cls-54,
.cls-57,
.cls-60,
.cls-61,
.cls-63,
.cls-64,
.cls-65,
.cls-66 {
  /* font-family: NotoSansHant-Bold-B5pc-H, Noto Sans T Chinese; */
}

.cls-34 {
  letter-spacing: -0.19em;
}

.cls-35 {
  letter-spacing: 0.06em;
}

.cls-36 {
  letter-spacing: 0.08em;
}

.cls-37 {
  letter-spacing: 0.06em;
}

.cls-38 {
  fill: #f7e2db;
}

.cls-39 {
  fill: #fff;
}

.cls-40,
.cls-44,
.cls-48 {
  fill: #ffc1b3;
}

.cls-40 {
  stroke-width: 1.73px;
}

.cls-41,
.cls-45 {
  stroke-miterlimit: 10;
}

.cls-41 {
  stroke-width: 0.59px;
}

.cls-42 {
  stroke-width: 2.08px;
}

.cls-43 {
  stroke-width: 2.03px;
}

.cls-44 {
  stroke-width: 1.88px;
}

.cls-45 {
  stroke-width: 0.64px;
}

.cls-46 {
  stroke-width: 2.25px;
}

.cls-47 {
  fill: #e1ccc5;
}

.cls-48 {
  stroke-width: 1.73px;
}

.cls-49 {
  stroke-width: 2.08px;
}

.cls-50 {
  font-size: 16.11px;
  letter-spacing: 0.06em;
}

.cls-51,
.cls-56,
.cls-57,
.cls-58,
.cls-62 {
  fill: #231815;
}

.cls-52,
.cls-53,
.cls-54,
.cls-55,
.cls-59,
.cls-60,
.cls-61,
.cls-62 {
  font-size: 17.38px;
}

.cls-52,
.cls-61,
.cls-62 {
  letter-spacing: 0.04em;
}

.cls-53,
.cls-65 {
  letter-spacing: -0.21em;
}

.cls-54,
.cls-59,
.cls-66,
.cls-67 {
  letter-spacing: 0.04em;
}

.cls-55 {
  letter-spacing: 0.06em;
}

.cls-56 {
  letter-spacing: 0.06em;
}

.cls-58 {
  font-size: 19.42px;
  letter-spacing: 0.06em;
}

.cls-60,
.cls-63 {
  letter-spacing: -0.21em;
}

.cls-68 {
  fill: #604c3f;
}

.cls-69 {
  stroke-width: 1.86px;
}

.cls-70 {
  fill: #e06748;
}
</style>
