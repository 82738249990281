<template>
  <svg
    id="圖層_1"
    data-name="圖層 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 120 1110 630"
  >
    <defs>
      <clipPath id="will-learn-clip-path">
        <rect
          class="cls-1"
          x="-1255.39"
          y="954.06"
          width="1095.39"
          height="1090.17"
        />
      </clipPath>
    </defs>
    <text class="cls-3" transform="translate(291.76 184.27)">
      上完這門課你將學到
    </text>
    <text class="cls-4" transform="translate(104.12 577.12)">
      面相識人術的奧秘
    </text>
    <text class="cls-5" transform="translate(106.96 645.3)">
      無論是感情上尋找天菜
      <tspan class="cls-6" x="285.31" y="0">、</tspan>
      <tspan x="307.63" y="0">職場</tspan>
      <tspan x="-6.89" y="37.49">媒合事業夥伴或家庭關係問題</tspan>
      <tspan x="22.33" y="74.98">讓人與人的連結更順遂！</tspan>
    </text>
    <circle class="cls-7" cx="237.56" cy="394.13" r="5.43" />
    <circle class="cls-7" cx="290.7" cy="392.49" r="5.43" />
    <path
      class="cls-8"
      d="M199.76,399.58c-1-3.07-4.32-5.57-7.44-4.71s-4.69,4.67-5.18,8c-2.19,15,6.31,30.92,20,37.45"
    />
    <path
      class="cls-8"
      d="M325.05,398c.85-3.13,3.4-5.53,6.57-4.86s5,4.37,5.66,7.66c3.11,14.82-4.39,31.25-17.63,38.61"
    />
    <path
      class="cls-8"
      d="M325.41,389.22c-.24,16.15-3.39,37-7.65,52.58a63.31,63.31,0,0,1-4.91,13.56c-4.49,8.34-12.13,14.45-19.61,20.24-7.89,6.11-16.7,10.34-26.37,11.55l-2.67.11c-9.84-.5-19-4.25-27.34-9.92C229,472,221,466.39,216,458.35a63.73,63.73,0,0,1-5.74-13.23,210.68,210.68,0,0,1-10.43-48.47"
    />
    <path
      class="cls-8"
      d="M260.93,403.3c1.25,3.93-.1,8.14-.35,12.25a22.79,22.79,0,0,0,2.19,11.17"
    />
    <path
      class="cls-8"
      d="M242.61,440.3c2.57,3.37,6.7,5.18,10.82,6.16a38.11,38.11,0,0,0,29.94-5.55"
    />
    <path
      class="cls-9"
      d="M326.44,394.53c-1.74-15.07-6.83-33.1-11.44-47.55.36,2.08-2.59,3.44-4.49,2.5s-2.8-3.1-3.52-5.1c-2.57-7.13-4.62-14.45-6.66-21.75q-17,11.56-35.09,21.46c-1.35-2-.09-4.73,1.17-6.82l12.32-20.47-13.55,11.69c-8.64,7.45-17.28,14.9-26.37,21.78a49.59,49.59,0,0,1-10.87,6.7,4.53,4.53,0,0,1-3.11.41,3.37,3.37,0,0,1-2-3.3,10,10,0,0,1,1.21-3.92l8.92-19a165.08,165.08,0,0,0-28,39.39c-4,7.85-6.27,18.46-5.43,27.23-11-26.63-9.65-60.48,5.43-85,4.72-7.7,10.77-14.84,18.67-19.21,5.34-3,13.56-6.7,19.53-7.94,26.52-5.53,36,7.34,36,7.34a21.45,21.45,0,0,1,11-3c12.36-.35,24.31,6.34,32,16s11.4,22.08,12.32,34.42-.8,24.71-3.07,36.86a85.57,85.57,0,0,1-4.34,16.51"
    />
    <path
      class="cls-9"
      d="M328.62,394.53C327.05,378,323,362,318.09,346.12c-1.1-3.57-6-2.69-6.29.86.11-1.37-2.44-5.55-2.95-7q-.93-2.7-1.78-5.41c-1.33-4.22-2.55-8.48-3.78-12.73-.52-1.79-2.84-3-4.52-1.84-11.3,7.79-23,14.95-35,21.6l4.28,1.74c-1.46-3.61,4.17-10.12,6-13.13l7.15-12c1.7-2.85-2.08-5.45-4.43-3.41-11.19,9.72-22.3,19.57-33.89,28.83-1,.84-15.81,13.25-17.3,9.77-.27-.62,2.11-4.75,2.4-5.37l2.46-5.16,4.92-10.31c1.27-2.65-2-5.66-4.27-3.29a171,171,0,0,0-24.9,33c-6.36,11-10.36,22.68-9.42,35.57l5.56-.76c-11.81-29.44-9.45-68.82,13.13-92.83C226,293,243.67,286.39,259.06,287.54a29.82,29.82,0,0,1,13.8,4.15,22.34,22.34,0,0,1,2.9,2.23c.33.28,1.7,2,.7.65a3.25,3.25,0,0,0,4.33,1.14c9.27-5.22,21.2-1.84,29.52,3.7,9.07,6,15.24,15.78,18.61,26,3.56,10.79,3.39,22.33,2.19,33.53s-3.13,23.32-7.27,34c-1.57,4,5,5.84,6.51,1.79,4.76-12.64,6.55-27.21,7.21-40.64.62-12.55-.94-26-5.74-37.7-7.95-19.42-33.07-38.25-54.22-26.12l4.33,1.13c-10.34-13.59-33.49-11.75-47.36-6.4-13.63,5.25-24.13,13-31.94,25.5a90.17,90.17,0,0,0-12.95,40.07,104.33,104.33,0,0,0,7.15,48c1.09,2.68,5.83,2.73,5.56-.75-1-12.47,3.59-23.52,9.7-34.11a166.14,166.14,0,0,1,22.8-30.59l-4.27-3.29-6.41,13.83c-1.44,3.09-4,6.86-4,10.42.06,8.75,9.53,5.45,14,2.7,7.86-4.81,15-11.13,22-17,8.2-6.91,16.27-14,24.41-20.94l-4.43-3.41L266,332.4c-2.32,3.83-5.38,7.85-3.55,12.46a3,3,0,0,0,4.28,1.74c12.05-6.57,23.75-13.71,35.17-21.32l-4.52-1.84c1.41,5.19,2.82,10.38,4.42,15.51,1.13,3.62,2.05,8.23,4.57,11.21,3.86,4.58,11.41,3.19,11.85-3.18l-6.29.85c5,15.41,10,31.22,12.43,47.28.36,2.41,4.52,1.87,4.28-.58Z"
    />
    <path class="cls-8" d="M246.49,375A26.27,26.27,0,0,0,220,378.58" />
    <path
      class="cls-8"
      d="M277.08,374.53a19.9,19.9,0,0,1,13.71-4.36,34.83,34.83,0,0,1,14,4"
    />
    <path
      class="cls-10"
      d="M371.89,316.72a44.53,44.53,0,1,0,60.88,16.11A44.52,44.52,0,0,0,371.89,316.72Zm40.33,69.34a35.68,35.68,0,1,1,12.9-48.78A35.69,35.69,0,0,1,412.22,386.06Z"
    />
    <path
      class="cls-10"
      d="M452.12,457.65h0a10.6,10.6,0,0,1-14.5-3.84l-22.48-39,20.5-11.93,21.22,36.89A13.1,13.1,0,0,1,452.12,457.65Z"
    />
    <path
      class="cls-10"
      d="M442,436.13l-1.35.78a6.62,6.62,0,0,1-9.06-2.4L407.07,392l12.8-7.44,24.48,42.49A6.62,6.62,0,0,1,442,436.13Z"
    />
    <line class="cls-11" x1="357.66" y1="325.97" x2="352.51" y2="325.9" />
    <line class="cls-12" x1="341.9" y1="325.76" x2="177.37" y2="323.61" />
    <line class="cls-11" x1="172.06" y1="323.54" x2="166.91" y2="323.47" />
    <line class="cls-11" x1="392.05" y1="400.52" x2="388.49" y2="404.23" />
    <line class="cls-13" x1="381.83" y1="411.15" x2="291.95" y2="504.63" />
    <line class="cls-11" x1="288.62" y1="508.09" x2="285.05" y2="511.8" />
    <text class="cls-14" transform="translate(606.44 576.12)">
      讀懂自己
      <tspan class="cls-15" x="165.39" y="0">，</tspan>
      <tspan x="198.47" y="0">掌握人生</tspan>
    </text>
    <text class="cls-16" transform="translate(612.17 643.59)">
      俗話說 ｢知己知彼
      <tspan class="cls-17" x="213" y="0">，</tspan>
      <tspan x="234.22" y="0">百戰百勝｣</tspan>
      <tspan x="-3.35" y="37.49">了解自己</tspan>
      <tspan class="cls-18" x="102.23" y="37.49">、</tspan>
      <tspan x="125.11" y="37.49">掌握優勢</tspan>
      <tspan class="cls-17" x="230.68" y="37.49">，</tspan>
      <tspan x="251.91" y="37.49">面臨人生</tspan>
      <tspan x="11.54" y="74.98">重大抉擇</tspan>
      <tspan class="cls-17" x="117.11" y="74.98">，</tspan>
      <tspan x="138.34" y="74.98">能做出適當選擇</tspan>
      <tspan class="cls-17" x="328.27" y="74.98">。</tspan>
    </text>
    <path
      class="cls-19"
      d="M697.18,499.82s61.27-90.46,69.63-90.46l79.32-1.42,59.68-41.55a15.1,15.1,0,0,1,20.46,2.1,15.12,15.12,0,0,1,12.71,6h0a15.07,15.07,0,0,1,2.57,12.85,15.14,15.14,0,0,1-3,21.18L889.66,445a15.07,15.07,0,0,1-11.85,2.79v7.76a15.13,15.13,0,0,1-15.13,15.12h-75.9l-13.13,33.67"
    />
    <path
      class="cls-19"
      d="M871,468.14l52.67-39.88A12.18,12.18,0,0,0,926,411.2h0A12.17,12.17,0,0,0,909,408.82l-39.62,29.91S805,412.22,763.67,439"
    />
    <path
      class="cls-19"
      d="M870.67,425.63l53.7-40.5a12.18,12.18,0,0,1,17.06,2.39h0"
    />
    <path
      class="cls-19"
      d="M860.68,415.64l58.84-44.78a12.11,12.11,0,0,1,5.93-2.37"
    />
    <path
      class="cls-19"
      d="M749.33,428.67S761,396.75,791,391.35c0,0,21.38-5.3,35.52-9.79,0,0,19.36-8.62,22.85-9.17,6.24-1,9.7,2.93,8.82,9-1,6.66-6.33,12.88-12.14,15.92-7.5,3.93-11.77,3.56-11.77,3.56A35.74,35.74,0,0,1,824.05,416"
    />
    <circle class="cls-20" cx="819.26" cy="362.42" r="56.22" />
    <line class="cls-21" x1="818.12" y1="262.12" x2="818.12" y2="286.09" />
    <line class="cls-21" x1="863.32" y1="273.56" x2="858.35" y2="282.67" />
    <line class="cls-21" x1="875.6" y1="286.2" x2="860.63" y2="303.78" />
    <line class="cls-21" x1="912.27" y1="327.99" x2="888.88" y2="333.75" />
    <line class="cls-21" x1="908.85" y1="345.39" x2="886.6" y2="349.44" />
    <line class="cls-21" x1="730.49" y1="404.35" x2="757.71" y2="386.54" />
    <line class="cls-21" x1="724.36" y1="383.82" x2="745.07" y2="378.83" />
    <line class="cls-21" x1="721.51" y1="340.64" x2="749.63" y2="342.59" />
    <line class="cls-21" x1="724.74" y1="320.48" x2="750.2" y2="331.18" />
    <line class="cls-21" x1="752.72" y1="281.18" x2="764.19" y2="294.65" />
  </svg>
</template>

<script>
export default {}
</script>

<style scoped>
.cls-1,
.cls-11,
.cls-12,
.cls-13,
.cls-8 {
  fill: none;
}

.cls-2 {
  clip-path: url(#will-learn-clip-path);
}

.cls-3 {
  font-size: 55px;
  /* font-family: GenEi-M-Gothic-v2-Heavy, GenEi M Gothic v2; */
  font-weight: 800;
}

.cls-14,
.cls-4 {
  font-size: 44.11px;
  /* font-family: GenEi-M-Gothic-v2-Bold, GenEi M Gothic v2; */
  font-weight: 700;
}

.cls-10,
.cls-14,
.cls-20,
.cls-4 {
  fill: #ff5445;
}

.cls-4 {
  letter-spacing: 0.04em;
}

.cls-16,
.cls-5 {
  font-size: 27.57px;
  /* font-family: GenEi-M-Gothic-v2-Medium, GenEi M Gothic v2; */
  font-weight: 500;
}

.cls-16,
.cls-5,
.cls-7,
.cls-9 {
  fill: #3e3a39;
}

.cls-5 {
  letter-spacing: 0.06em;
}

.cls-15,
.cls-17,
.cls-18,
.cls-6 {
  /* font-family: NotoSansHant-Medium-B5pc-H, Noto Sans T Chinese; */
  font-weight: 400;
}

.cls-6 {
  letter-spacing: -0.19em;
}

.cls-7 {
  stroke: #3e3a39;
  stroke-miterlimit: 10;
  stroke-width: 1.29px;
}

.cls-11,
.cls-12,
.cls-13,
.cls-21,
.cls-8 {
  stroke: #ff5445;
}

.cls-11,
.cls-12,
.cls-13,
.cls-19,
.cls-20,
.cls-21,
.cls-8 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-21,
.cls-8 {
  stroke-width: 3.5px;
}

.cls-11,
.cls-12,
.cls-13 {
  stroke-width: 2.57px;
}

.cls-12 {
  stroke-dasharray: 10.62 10.62;
}

.cls-13 {
  stroke-dasharray: 9.61 9.61;
}

.cls-15 {
  letter-spacing: -0.25em;
}

.cls-16 {
  letter-spacing: 0.02em;
}

.cls-17 {
  letter-spacing: -0.23em;
}

.cls-18 {
  letter-spacing: -0.17em;
}

.cls-19 {
  fill: #fff;
  stroke: #000;
  stroke-width: 5.15px;
}

.cls-20 {
  stroke: #fff;
  stroke-width: 3px;
}

.cls-21 {
  fill: #dedfdf;
}
</style>
