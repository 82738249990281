<template>
  <div id="app">
    <div class="box box-1">
      <title-01 />
      <image-01 />
      <will-learn-01 />
    </div>
    <div class="box box-2">
      <div class="title-2">
        <h3>並且，你能到達的程度</h3>
      </div>
      <div class="what-you-can-do">
        <div class="youtube-card">
          <div class="video-container">
            <iframe
              src="https://www.youtube.com/embed/ySIWQCloVFw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="youtube-title-wrapper">
            <h4>
              我有個朋友會算命，他能一眼就看出你是否單身？！ 【#簡少年
              #不負責任面相學】
            </h4>
          </div>
          <img class="youtube-hr" src="@/assets/image/hr.svg" />
          <div class="youtube-channel-wrapper">
            <div class="youtube-channel-icon">
              <img src="@/assets/image/youtube-icon/photo3.png" />
            </div>
            <h5>PressPlay Academy</h5>
          </div>
        </div>
        <div class="youtube-card">
          <div class="video-container">
            <iframe
              src="https://www.youtube.com/embed/ZemknGBBZs4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="youtube-title-wrapper">
            <h4>
              算命仙駕到！志恒上輩子是皇帝！一直單身的原因是.....ft.簡少年
            </h4>
          </div>
          <img class="youtube-hr" src="@/assets/image/hr.svg" />
          <div class="youtube-channel-wrapper">
            <div class="youtube-channel-icon">
              <img src="@/assets/image/youtube-icon/photo1.png" />
            </div>
            <h5>High咖的屁孩日記</h5>
          </div>
        </div>
        <div class="youtube-card">
          <div class="video-container">
            <iframe
              src="https://www.youtube.com/embed/glb9o8C-51I"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="youtube-title-wrapper">
            <h4>【跟男友去算命】我們八字適合結婚嗎？大師！ feat.簡少年</h4>
          </div>
          <img class="youtube-hr" src="@/assets/image/hr.svg" />
          <div class="youtube-channel-wrapper">
            <div class="youtube-channel-icon">
              <img src="@/assets/image/youtube-icon/photo2.png" />
            </div>
            <h5>林進</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="not-difficult">
      <div class="gradient">
        <title-201 />
      </div>
      <img src="@/assets/image/00.jpg" />
      <div class="teacher">
        <intro-01 />
      </div>
    </div>
    <div class="box box-3" id="class-01">
      <class-01  />
      <div class="bonus">
        <bonus />
      </div>
    </div>
    <div class="box-4">
      <div class="message">
        <group />
      </div>
      <div class="comment">
        <div class="title-3">
          <h3>學員真實心得分享</h3>
        </div>
        <comment-card
          v-for="(comment, index) in comments"
          :key="index"
          :name="comment.name"
          :icon="comment.icon"
          :content="comment.content"
        />
        <div class="comment-cover"></div>
      </div>
      <div class="recommend">
        <recommend />
      </div>
    </div>
    <div class="box box-5">
      <div class="title-4">
        <h3>知名 KOL / 媒體 <br />一致好評</h3>
      </div>
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide>
          <img src="@/assets/image/slider/slider1.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/image/slider/slider2.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/image/slider/slider3.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/image/slider/slider4.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/image/slider/slider5.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/image/slider/slider6.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/image/slider/slider7.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/image/slider/slider8.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/image/slider/slider9.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/image/slider/slider10.png" />
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <img src="@/assets/image/hr.svg" />
      <div class="kol-list">
        <kol />
      </div>
      <div class="collaboration">
        <div class="gradient-top">
          <h3>各大品牌合作邀約</h3>
        </div>
        <div class="collaboration-list">
          <img src="@/assets/image/18-n.png" />
        </div>
        <div class="gradient-bottom"></div>
      </div>
      <reward class="reward" />
      <div class="title-5">
        <h3>講師介紹</h3>
      </div>
      <div class="others">
        <master />
      </div>
      <price-01 />
      <div class="button-wrapper-2">
        <a
          href="https://course.taotaoxi.net/programs/67da538d-65c6-4d0d-a73c-ca236087ae32"
        >
          <img src="@/assets/image/button/button2.svg" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import WillLearn01 from '@/components/WillLearn01.vue'
import CommentCard from '@/components/CommentCard'
import Image01 from '@/components/Image01'
import Title01 from '@/components/Title01'
import Title201 from '@/components/Title201'
import Intro01 from '@/components/Intro01'
import Class01 from '@/components/Class01'
import Bonus from '@/components/Bonus'
import Group from '@/components/Group'
import Recommend from '@/components/Recommend'
import Kol from '@/components/Kol'
import Price01 from '@/components/Price01'
import Reward from '@/components/Reward'
import Master from '@/components/Master'

export default {
  name: 'App',
  data() {
    return {
      swiperOption: {
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      },
      comments: [
        {
          icon: 'photo7',
          name: '國康',
          content:
            '課程取名為面相精華班真的取得沒錯，有感受到課程裡都是滿滿的乾貨。對於我來說課程真的很值得，有感覺到老師已經把他會的東西，實用的東西完全傳授給我們了。',
        },
        {
          icon: 'photo6',
          name: 'Amy',
          content:
            '有助教和老師回答問題，這個應該是關鍵，課程的強項，不然自己亂解釋一通，也是做白工。',
        },
        {
          icon: 'photo4',
          name: 'Wei6906',
          content:
            '在Clubhouse聽到簡老師的房，很喜歡老師解說命理的方式，知道老師有開課，於是就報名了。學習紫微之後，也對簡老師的面相學及風水學有興趣，也加入學習行列。',
        },
        {
          icon: 'photo5',
          name: '助教2',
          content:
            '教法創新有效率、內容又有獨到見解，不管是針對完全初學，或是已經有一些底子的同學，都非常受用，誠心推薦給大家！',
        },
      ],
    }
  },
  components: {
    CommentCard,
    Swiper,
    SwiperSlide,
    Image01,
    Title01,
    WillLearn01,
    Title201,
    Intro01,
    Class01,
    Bonus,
    Group,
    Recommend,
    Kol,
    Price01,
    Reward,
    Master,
  },
}
</script>
<style scoped>
#app {
  max-width: 1110px;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img,
svg {
  width: 100%;
  display: block;
}
.box {
  background-size: contain;
  background-repeat: no-repeat;
}
</style>

<style scoped>
.box-1 {
  background-image: url('./assets/image/bg/bg1.svg');
  /* padding-top: 440.49%; */
  /* padding-bottom: 339.54%; */
  padding-bottom: 3.54%;
  padding-top: 12%;
  background-repeat: no-repeat no-repeat;
}

.box-1 .button-wrapper-1 {
  padding-left: 20%;
  padding-right: 14%;
  margin: -3% 0;
}
.box-1 .title-1 {
  text-align: center;
  padding-top: 15%;
}
.box-1 .title-1 h3 {
  font-size: 5vmin;
  letter-spacing: 0px;
  font-weight: bold;
}

.box-1 .section-2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.box-1 .section-2 .will-img-1 {
  width: 39%;
  margin-right: 10%;
  margin-top: 1.5%;
}
.box-1 .section-2 .will-img-2 {
  width: 40.5%;
}
</style>

<style scoped>
.box-2 .title-2 {
  text-align: center;
  color: white;
  /* margin-top: 10%; */
  padding: 5% 0;
  background-color: #ff5445;
}
.box-2 .title-2 h3 {
  font-size: 6vmin;
}
.box-2 .what-you-can-do {
  /* padding-top: 6.5%; */
  background-color: #f4f1f4;
}

.youtube-card {
  padding-top: 3.9%;
  width: 70.27%;
  margin: 0 auto;
  padding-bottom: 6.6%;
}

.youtube-card .video-container {
  position: relative;
  padding-bottom: 61.538%;
  height: 0;
  background-color: black;
}
.youtube-card .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.youtube-card .youtube-title-wrapper {
  background: white;
  padding: 7% 7% 6%;
}
.youtube-card .youtube-title-wrapper h4 {
  font-size: 2.5vmin;
}

.youtube-card .youtube-channel-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* padding-top: 10%; */
  justify-content: center;
  align-items: center;
  background: white;
  padding: 4% 0 5%;
  border-radius: 0 0 10px 10px;
}

.youtube-card .youtube-channel-wrapper .youtube-channel-icon {
  width: 12%;
  padding-right: 2%;
}

.youtube-card .youtube-channel-wrapper h5 {
  color: #595757;
  font-size: 3vmin;
}
.youtube-card .youtube-hr {
  background-color: white;
}
</style>

<style scoped>
.not-difficult {
  position: relative;
}
.not-difficult .gradient {
  background-image: url('./assets/image/bg/22.png');
  position: absolute;
  padding: 0;
  background-size: contain;
  padding-bottom: 45%;
  width: 100%;
}
.not-difficult .teacher {
  position: absolute;
  bottom: 0%;
  width: 100%;
}
</style>

<style scoped>
.box-3 {
}
.box-3 .bonus {
  background-color: #ff5445;
}
</style>

<style scoped>
.box-4 {
}

.box-4 .title-3 {
  text-align: center;
  padding: 3% 0 0;
}

.box-4 .title-3 h3 {
  font-size: 6vmin;
  letter-spacing: 0px;
  font-weight: bold;
}
.box-4 .comment {
  position: relative;
}
.box-4 .comment .comment-cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/image/bg/bg3-cover.svg');
  background-repeat: repeat-y;
  background-size: cover;
}
</style>

<style scoped>
.box-5 {
  /* background-image: url('./assets/image/bg/bg4.svg'); */
  background-color: #f4f1f4;
}
.box-5 .title-4 {
  text-align: center;
  color: white;
  /* margin-top: 10%; */
  padding: 5% 0;
  background-color: #ff5445;
}
.box-5 .title-4 h3 {
  font-size: 6vmin;
}
.box-5 .button-wrapper-2 {
  padding-top: 10%;
  padding-bottom: 25%;
  padding-left: 21%;
  padding-right: 19%;
  background-color: #fffbff;
}
.box-5 .collaboration {
  position: relative;
  margin-bottom: -9%;
}
.collaboration .gradient-top {
  padding: 6% 0 35%;
  text-align: center;
  position: absolute;
  width: 100%;
  color: white;
  background-image: url('./assets/image/bg/23.png');
  background-repeat: repeat-x;
  background-size: contain;
}

.collaboration .gradient-bottom {
  padding-top: 46.7%;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-image: url('./assets/image/bg/23-r.png');
  background-repeat: repeat-x;
  background-size: contain;
}

.collaboration .gradient-top h3 {
  font-size: 6vmin;
}
.collaboration .collaboration-list {
  padding-top: 19%;
  padding-bottom: 16%;
}
.reward {
  z-index: 10;
  position: relative;
  background: #f4f1f4;
}

.box-5 .title-5 {
  background-color: #231815;
  text-align: center;
  color: white;
  /* margin-top: 10%; */
  padding: 2.5% 0;
}

.box-5 .title-5 h3 {
  font-size: 5.5vmin;
}
.box-5 .reward {
  position: relative;
  z-index: 10;
}
</style>

<style>
.swiper-button-prev,
.swiper-button-next {
  top: 42%;
  color: #ff5445;
}
</style>
