<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1110 1703.8">
    <defs></defs>
    <g class="cls-1">
      <g id="圖層_1" data-name="圖層 1">
        <rect
          class="cls-2"
          x="-219"
          y="526.12"
          width="1529.69"
          height="1182.52"
        />
        <rect
          class="cls-3"
          x="-219"
          y="-2.62"
          width="1529.69"
          height="879.35"
        />
        <g class="cls-4">
          <line class="cls-5" x1="5.79" y1="0.47" x2="5.79" y2="1708.65" />
          <line class="cls-5" x1="63.66" y1="0.47" x2="63.66" y2="1708.65" />
          <line class="cls-5" x1="121.52" y1="0.47" x2="121.52" y2="1708.65" />
          <line class="cls-5" x1="179.38" y1="0.47" x2="179.38" y2="1708.65" />
          <line class="cls-5" x1="237.24" y1="0.47" x2="237.24" y2="1708.65" />
          <line class="cls-5" x1="295.1" y1="0.47" x2="295.1" y2="1708.65" />
          <line class="cls-5" x1="352.97" y1="0.47" x2="352.97" y2="1708.65" />
          <line class="cls-5" x1="410.83" y1="0.47" x2="410.83" y2="1708.65" />
          <line class="cls-5" x1="468.69" y1="0.47" x2="468.69" y2="1708.65" />
          <line class="cls-5" x1="526.55" y1="0.47" x2="526.55" y2="1708.65" />
          <line class="cls-5" x1="584.41" y1="0.47" x2="584.41" y2="1708.65" />
          <line class="cls-5" x1="642.28" y1="0.47" x2="642.28" y2="1708.65" />
          <line class="cls-5" x1="700.14" y1="0.47" x2="700.14" y2="1708.65" />
          <line class="cls-5" x1="758" y1="0.47" x2="758" y2="1708.65" />
          <line class="cls-5" x1="815.86" y1="0.47" x2="815.86" y2="1708.65" />
          <line class="cls-5" x1="873.73" y1="0.47" x2="873.73" y2="1708.65" />
          <line class="cls-5" x1="931.59" y1="0.47" x2="931.59" y2="1708.65" />
          <line class="cls-5" x1="989.45" y1="0.47" x2="989.45" y2="1708.65" />
          <line
            class="cls-5"
            x1="1047.31"
            y1="0.47"
            x2="1047.31"
            y2="1708.65"
          />
          <line
            class="cls-5"
            x1="1105.17"
            y1="0.47"
            x2="1105.17"
            y2="1708.65"
          />
        </g>
        <text class="cls-6" transform="translate(337.68 123.24)">
          課程單元規劃
        </text>
        <text class="cls-7" transform="translate(364.75 990.26)">
          2大必備相法
        </text>
        <text class="cls-8" transform="translate(178.97 1461.95)">
          三停流年法
        </text>
        <text class="cls-8" transform="translate(713.53 1461.95)">
          定位流年法
        </text>
        <text class="cls-9" transform="translate(114.13 1524.58)">
          由上到下依序將臉分為
          <tspan class="cls-10">
            <tspan x="-0.62" y="43.13">上停</tspan>
            <tspan class="cls-11" x="58.59" y="43.13">、</tspan>
            <tspan x="83.7" y="43.13">中停</tspan>
            <tspan class="cls-12" x="142.91" y="43.13">、</tspan>
            <tspan x="168.02" y="43.13">下停</tspan>
            <tspan class="cls-13" x="234.98" y="43.13">三部分</tspan>
          </tspan>
          <tspan x="7.75" y="86.25">對應人生的三個階段</tspan>
          <tspan class="cls-14" x="301.32" y="86.25">：</tspan>
          <tspan class="cls-10">
            <tspan x="18.34" y="129.38">早年</tspan>
            <tspan class="cls-11" x="77.55" y="129.38">、</tspan>
            <tspan x="102.66" y="129.38">中年</tspan>
            <tspan class="cls-12" x="161.87" y="129.38">、</tspan>
            <tspan x="186.98" y="129.38">晚年運</tspan>
            <tspan class="cls-15" x="287.42" y="129.38"></tspan>
            <tspan class="cls-11" x="290.73" y="129.38">。</tspan>
          </tspan>
        </text>
        <text class="cls-16" transform="translate(647.44 1524.58)">
          將人的臉分為
          <tspan class="cls-10" x="200.88" y="0">75</tspan>
          <tspan x="245.32" y="0">個部位</tspan>
          <tspan class="cls-17">
            <tspan x="5.48" y="49.13">每個部分對應各別年齡</tspan>
            <tspan x="24.44" y="98.25">藉此判斷當年運勢</tspan>
            <tspan class="cls-18" x="292.28" y="98.25"></tspan>
            <tspan class="cls-19" x="295.59" y="98.25">。</tspan>
          </tspan>
        </text>
        <circle class="cls-20" cx="286.4" cy="1206.87" r="178.24" />
        <path
          class="cls-21"
          d="M192.52,1231.19c-6-23.76-8.56-48.93-2.65-72.73s21.16-46.09,43.55-56.59c16-7.52,34.49-8.6,52-6.14,15.21,2.13,30.33,7,42.57,16.14,17.93,13.43,28,34.67,35.37,55.67,5.7,16.18,10.29,33,10.11,50.16-.15,14.14-3.53,28-6.9,41.76s-6.73,27.51-10.37,41.2c-2,7.35-4,14.79-8.05,21.26-8.31,13.44-21.69,20.91-36.18,29.71-7,4.28-15.62,9.48-23.82,10.36-10.49,1.12-24.27-1.65-33.78-6.17-7.95-3.78-18.53-12.16-25.2-17.87-15.16-13-22.17-28.36-28.41-47.2s-6.91-42.78-8.66-62.53"
        />
        <path
          d="M206.81,1125.48c-12.18,2.33-20.38,14-24.55,25.66a82.79,82.79,0,0,0,5.9,67.79c5-20,16.46-24.4,12.18-63.81-.82-7.53,4.51-13,8.86-19.24,2.1-3,6.79-3.73,9.38-6.3,3.92-3.89,7.88-7.82,12.52-10.8,9-5.76,23.66-9.35,34.31-9.78,33.09-1.36,57.24-.22,80.95,22.91,5.26,5.13,13.91,25,17.41,31.46,6.47,11.93,10.26,24.21,9.7,37.77-.24,5.82-3.34,22.09,1.6,25.16,5.85,3.63,16.19-10.8,20.67-16a80.52,80.52,0,0,0,16-30.72c3.1-11.13,3.7-22.69-1.52-33-3.34-6.59-8.73-13.32-6.82-20.45.81-3,8.29-6.71,7.73-9.78-.52-2.85-8.35-4.51-11.17-5.18-13-3.07-20-4.6-35.87-18.34-14.61-12.64,1.88-14.17-3.36-16.5-5.5-2.44-19.92-3-30.55-5.5-4.84-1.15-12.37-5.69-16.8-7.94-11-5.59-26.76-8.84-39.1-9.16s-33.48,10.93-40.94,20.77A9.93,9.93,0,0,1,231,1077c-1.35.87-3,.91-4.64,1.08-8.64,1-8.26,5.75-13.2,12.9s-2.46,11-5.19,19.25c-1.24-3.22-4.46-7.13-7.27-5.13a30,30,0,0,0-4.4,6.44,33.24,33.24,0,0,0,.45,21.73"
        />
        <path
          class="cls-21"
          d="M375.34,1230.41q-6.75,31.47-14.29,62.76A51.28,51.28,0,0,0,387.39,1238c-1-4.9-3.58-10.49-8.54-11.09"
        />
        <path
          class="cls-21"
          d="M188.91,1227.91c-.92-3.88-6.55-5.33-10.2-3.45s-5.52,5.88-6.42,9.75c-2.37,10.11-1.41,22.84,3.61,32.11S192.53,1285,200,1292.59c-5.52-20-9.37-43.91-11.61-64.46"
        />
        <path
          class="cls-22"
          d="M246.27,1306.05c4.71,1.24,8.86,3.35,13.77,4.09,5.43.81,10.87,1.34,16.34,1.67,5.21.32,10.42.6,15.64.65,5.67.06,12.74.45,18.08-1.65,3.89-1.52,6.86-4.58,6.86-9,0-3.81-2.87-8.33-6.86-9-5.67-1-11.38-.08-17.1.22-5.54.28-11.07.47-16.62.52-5,.06-10,.38-15,.4-5.17,0-10-.61-15.1.33-6.09,1.12-5.55,10.33,0,11.79Z"
        />
        <path
          class="cls-23"
          d="M318.88,1296.55c-28,1.86-77.69.4-77.69.4a35.56,35.56,0,0,1,31.1-7.08,25,25,0,0,0,6.29,1.24,23.62,23.62,0,0,0,5.74-1.12,43.85,43.85,0,0,1,35.69,6.43"
        />
        <path
          class="cls-23"
          d="M319.93,1296.62a56.85,56.85,0,0,1-78.84.15s-.17,0,0,.65c2.94,10.88,13.69,20.82,24.34,24.53a48.59,48.59,0,0,0,14.8,2.56c8.46.22,17.13-1.42,24.39-5.78s14-13.53,15.43-21.88Z"
        />
        <path
          d="M241.37,1294.86a2.56,2.56,0,0,1-.05-.27c-.06-.31,0,.31,0,0a4.19,4.19,0,0,1,0-.5c0-.17,0-.33,0-.49s-.05.26,0,0c0-.09,0-.18.05-.26a6.78,6.78,0,0,1,.3-1c.06-.15-.12.24,0,0s.07-.16.12-.24a4.39,4.39,0,0,1,.27-.47l.15-.23s.18-.25.06-.09.07-.08.1-.11l.15-.17.38-.38.21-.18c.2-.17-.22.14,0,0a13.07,13.07,0,0,1,1.79-1,1.11,1.11,0,0,0,.51-1.26,1.15,1.15,0,0,0-1.08-.82,4.42,4.42,0,0,0-1.6.31,6,6,0,0,0-1,.59,7.43,7.43,0,0,0-1.95,2,7,7,0,0,0-1,5.26,1.35,1.35,0,0,0,1.65.93,1.37,1.37,0,0,0,.93-1.64Z"
        />
        <path
          d="M315.61,1289.5a7.28,7.28,0,0,1,2.79,2.9c.08.15.16.31.23.47a1.63,1.63,0,0,1,.11.23c.09.21-.07-.18,0,0a9,9,0,0,1,.36,1.11c0,.17.07.34.11.51s0,.37,0,.07l0,.25a11.45,11.45,0,0,1,.05,1.17,4.71,4.71,0,0,0,0,1.3,1.48,1.48,0,0,0,1.78,1.09c1.2-.36,1.26-1.86,1.18-2.91a10.76,10.76,0,0,0-1.5-4.64,10.16,10.16,0,0,0-3.91-3.76,1.26,1.26,0,0,0-1.27,2.18Z"
        />
        <path
          d="M259.79,1196.83,254.4,1190a8.13,8.13,0,0,0-8.14-4q-9.31-.12-18.63.31a18.58,18.58,0,0,0-7.34,1.39c-4.12,2-6.22,6.56-8,10.78,2.45-3.31,6.77-4.59,10.83-5.29a67.87,67.87,0,0,1,35.09,3.35"
        />
        <path
          d="M261,1195.6l-4.19-5.33a21.46,21.46,0,0,0-3.94-4.35,9.67,9.67,0,0,0-5.38-1.63c-2.21-.13-4.44-.09-6.65-.09q-6.74,0-13.46.3c-4,.19-7.8.77-10.84,3.54s-4.49,6.42-6,10a1.74,1.74,0,0,0,1,2.07,1.78,1.78,0,0,0,2.18-.72c2-2.63,5.58-3.66,8.68-4.25a59.77,59.77,0,0,1,11.56-1,65.21,65.21,0,0,1,11.75,1.1,90.46,90.46,0,0,1,11.87,3.32,2.1,2.1,0,0,0,2.56-1.45,2.15,2.15,0,0,0-1.45-2.57c-8-3.31-17.28-4.43-25.87-4.15a62.39,62.39,0,0,0-12.47,1.56c-3.55.83-7.08,2.36-9.38,5.3l2.75,2.12c1.5-3.44,3.15-7.43,6.47-9.52a12.67,12.67,0,0,1,6-1.61c2.56-.18,5.13-.26,7.7-.34s5.23-.12,7.85-.13a42.57,42.57,0,0,1,6.87.16c2.37.4,3.64,2.3,5,4.06l4.79,6.07a1.76,1.76,0,0,0,2.48,0,1.79,1.79,0,0,0,0-2.47Z"
        />
        <path
          d="M303,1196.83l5.38-6.83a11,11,0,0,1,3.25-3.12,10.81,10.81,0,0,1,4.9-.86q9.31-.12,18.62.31a18.67,18.67,0,0,1,7.35,1.39c4.11,2,6.21,6.56,8,10.78-2.45-3.31-6.77-4.59-10.83-5.29a67.88,67.88,0,0,0-35.09,3.35"
        />
        <path
          d="M304.18,1198.07,309,1192c1.39-1.76,2.67-3.66,5-4.06a42.69,42.69,0,0,1,6.88-.16c2.61,0,5.23.06,7.84.13s5.14.16,7.71.34a12.7,12.7,0,0,1,6,1.61c3.31,2.09,5,6.08,6.46,9.52l2.75-2.12c-2.29-2.94-5.82-4.47-9.37-5.3a62.4,62.4,0,0,0-12.48-1.56c-8.58-.28-17.88.84-25.86,4.15a2.16,2.16,0,0,0-1.46,2.57,2.1,2.1,0,0,0,2.56,1.45,90.9,90.9,0,0,1,11.87-3.32,64.39,64.39,0,0,1,11.75-1.1,61.39,61.39,0,0,1,11.56,1c3.1.59,6.67,1.62,8.68,4.25a1.79,1.79,0,0,0,2.19.72,1.74,1.74,0,0,0,1-2.07c-1.33-3.22-2.72-6.47-5.08-9.09-2.67-3-6.28-4.07-10.17-4.35-4.41-.32-8.87-.36-13.29-.38q-3.31,0-6.62,0a14.24,14.24,0,0,0-5.9.94c-2,.91-3.27,2.67-4.58,4.33l-4.78,6.09a1.8,1.8,0,0,0,0,2.47,1.75,1.75,0,0,0,2.47,0Z"
        />
        <path
          class="cls-24"
          d="M241.11,1191.43a28.16,28.16,0,1,0,28.15,28.15A28.15,28.15,0,0,0,241.11,1191.43Zm0,50.82a22.67,22.67,0,1,1,22.66-22.67A22.67,22.67,0,0,1,241.11,1242.25Z"
        />
        <path
          class="cls-24"
          d="M324.41,1191.43a28.16,28.16,0,1,0,28.16,28.15A28.15,28.15,0,0,0,324.41,1191.43Zm0,50.82a22.67,22.67,0,1,1,22.67-22.67A22.67,22.67,0,0,1,324.41,1242.25Z"
        />
        <path
          d="M311.65,1242.3c-8.38-1.21-14.7-8.6-17.69-16.53-1.53-4.07-2.72-8.8-6.47-11s-8.65-.88-11.9,1.93-5.17,6.82-7,10.71-3.7,7.9-6.9,10.76l4-30.16a8.28,8.28,0,0,0,6,.59c2-.46,3.93-1.32,5.92-1.91a21.83,21.83,0,0,1,11.81-.08c3.78,1.05,7.77,3.12,11.39,1.63l-1.13,18.43Z"
        />
        <path
          d="M222.43,1238.84a41.89,41.89,0,0,1-8.12-9.06c-1.78-2.78-3.12-5.87-5.31-8.33s-5.58-4.25-8.76-3.36c-4.11,1.15-5.81,5.86-8.15,9.43a4.45,4.45,0,0,1-1.85,1.8,1.81,1.81,0,0,1-2.29-.64,2.42,2.42,0,0,1-.12-1.39c.5-4.08,2.83-7.67,5.1-11.1,2-3,4.43-6.34,8-6.92,2.67-.44,5.51.78,8.08-.08a10.52,10.52,0,0,0,3.74-2.66l8.08-7.77s-8.36,8.36-3.54,23.37Z"
        />
        <path
          d="M342.49,1238.84a42.15,42.15,0,0,0,8.12-9.06c1.77-2.78,3.12-5.87,5.31-8.33s5.58-4.25,8.75-3.36c4.11,1.15,5.82,5.86,8.16,9.43a4.43,4.43,0,0,0,1.84,1.8,1.8,1.8,0,0,0,2.29-.64,2.43,2.43,0,0,0,.13-1.39c-.5-4.08-2.83-7.67-5.11-11.1-2-3-4.42-6.34-8-6.92-2.68-.44-5.51.78-8.08-.08a10.43,10.43,0,0,1-3.74-2.66l-8.09-7.77s8.37,8.36,3.55,23.37Z"
        />
        <circle cx="244.51" cy="1218.45" r="7.93" />
        <circle cx="322.14" cy="1218.45" r="7.93" />
        <path
          class="cls-25"
          d="M212.94,1128.32c-7.71-3.54-14.35-.65-20.53,5.17-12,11.27-16.35,28.85-15.25,45.27s7,32.09,12.79,47.48a75.88,75.88,0,0,1,5.2-26c1.38-3.55,3-7,4.13-10.64,2-6.78,2-14,2-21.07s0-14.29,2.17-21,5.16-12.7,12.24-13.26c0,0,12-23.83,55.84-26.13,19.41-1,43.92,3,60.17,13.68,25.23,16.58,32.1,37,34.17,44.4,7.36,26.64,6.42,35.19,8.4,60.54,19.47-8.19,34.15-30.9,37.56-51.75,2.65-16.16,1.24-34.71-10.7-45.92a34.61,34.61,0,0,0,8.81-9.52c.65-1,1.27-2.31.89-3.49-.56-1.77-2.84-2.15-4.68-2.31a67.32,67.32,0,0,1-48.6-28c1.57-2.82,5.75-4.81,4.81-7.9-.78-2.56-4.28-2.71-7-2.6-21.28.85-39.46-14.84-60.1-20.11a64,64,0,0,0-51.57,9.29c-4.86,3.37-9.42,7.71-11.2,13.36-10.83,0-21,8.7-22.74,19.4a42.38,42.38,0,0,0,.2,13.68c-.61-.7-1.23-1.38-1.83-2.08-1.5-1.77-3.18-3.7-5.48-4a6.1,6.1,0,0,0-5.41,2.41,13,13,0,0,0-2.37,5.66c-.94,4.53-.17,12.05,3.84,14.92"
        />
        <path
          class="cls-25"
          d="M189.11,1230.56c-1.38-4.52-6.06-8.32-10.66-7.2-4.78,1.16-7,6.6-7.91,11.44-3.88,21.77,7.81,45.41,27.46,55.55"
        />
        <path
          class="cls-25"
          d="M372,1233.94c1.38-4.52,5.21-7.92,9.8-6.8,4.78,1.16,7.06,6.6,7.92,11.44,3.87,21.77-7.81,45.41-27.46,55.55"
        />
        <path
          d="M259.13,1251.13a9.47,9.47,0,0,0-7,6.09,8.83,8.83,0,0,0,2.42,9,9.62,9.62,0,0,0,9.52,2.26,48.13,48.13,0,0,1,5-1.75c1.74-.34,2.74.62,4,1.69a11.82,11.82,0,0,0,9.18,2.89,12.37,12.37,0,0,0,4.66-1.49c1.56-.89,2.48-2.23,3.78-3.4s2.84-.12,4.08.47a14.5,14.5,0,0,0,5,1.66,10.47,10.47,0,0,0,8.51-3.53,9.29,9.29,0,0,0,1.44-10.26,10.49,10.49,0,0,0-8.76-5.83c-2.16-.11-2.15,3.25,0,3.36a6.31,6.31,0,0,1,5.45,4.23,5.57,5.57,0,0,1-1.47,6.31,6.39,6.39,0,0,1-6.23,1.5c-2.8-.94-5.38-3.13-8.51-2.12-2.63.86-3.72,3.72-6.32,4.7a8.16,8.16,0,0,1-7-.51c-1.18-.71-2.09-1.8-3.24-2.56a6.77,6.77,0,0,0-4.55-1.06c-2.84.32-5.4,2.38-8.28,2.3a5.78,5.78,0,0,1-5.41-4.85,6.31,6.31,0,0,1,4.38-6.6,1.3,1.3,0,0,0-.69-2.51Z"
        />
        <path
          class="cls-25"
          d="M372.94,1221.11c-1.08,23.54-6.61,53.77-13.53,76.38-2.06,6.73-4.27,13.48-7.79,19.57-6.91,12-18.34,20.53-29.53,28.65-11.78,8.56-24.82,17.34-39,18.68l-3.9,0c-14.35-1.18-27.54-10.07-39.46-18.72-11.18-8.12-22.61-16.69-29.53-28.65-3.52-6.09-5.72-12.84-7.78-19.57a308.31,308.31,0,0,1-13-71.19"
        />
        <line class="cls-26" x1="160.7" y1="1179.6" x2="411.11" y2="1179.6" />
        <line class="cls-26" x1="160.7" y1="1277.37" x2="411.11" y2="1277.37" />
        <circle class="cls-20" cx="820.95" cy="1206.87" r="178.24" />
        <path
          class="cls-21"
          d="M727.64,1231.19c-6-23.76-8.56-48.93-2.65-72.73s21.16-46.09,43.55-56.59c16-7.52,34.49-8.6,52-6.14,15.21,2.13,35.68-.79,47.93,8.39,17.92,13.43,22.61,42.42,30,63.42,5.7,16.18,10.29,33,10.11,50.16-.14,14.14-.18,26-3.54,39.72s-2,27.88-5.66,41.57c-1.95,7.34-6.3,19.45-10.31,25.93-8.31,13.44-24.07,20.13-38.57,28.93-7,4.28-19,7.26-27.24,8.14-10.48,1.12-24.27-1.65-33.78-6.17-7.95-3.78-22.09-11.22-28.77-16.93-15.15-13-22.69-25.06-28.93-43.9s-2.82-47-4.57-66.77"
        />
        <path
          d="M835.44,1078.35c-10.53-9.43-31.73-14.36-45.41-10.81-19.23,5-40.36,20.49-52.88,35.91-15.64,19.27-9,53.87-34.91,64.85,14.63,13.3,12.3,44.22,20.28,61.52,5-5.66,4.89-29.35,9-40.57.79-2.16-.88-4.11.81-5.66,2-1.87,4.89-2.48,7.56-3.18,16.7-4.39,38.64-13.59,47.5-28.41,4.88-8.17,7.12-21.54,11.64-29.93,4.06-7.54,5.17-7.65,11.3-13.63,3.82-3.72,21.29-8.65,33.92,10,3.52,5.19,10.15-3.25,16.3-2,9.41,1.92,17.63,6.74,21.28,15.63,7.81,19,10.42,28.6,13.63,48.88.81,5.11,6.3,6.57,7.32,10,2.91,9.7.7,19.79,7.1,27.64A26.9,26.9,0,0,1,926,1202.77c2.81-1,6.36-2.09,6.72-5a7.08,7.08,0,0,0-.51-3,284.29,284.29,0,0,1-11.74-53.46c-.88-7.33-1.49-14.76-3.61-21.83-4.52-15.07-15.87-27.61-29.69-35.12s-33.25-6.34-49-5.52"
        />
        <path
          class="cls-21"
          d="M910.46,1230.41c-4.5,21-6.67,46-11.69,66.91,9.64-5.1,16.91-14.42,21.61-24.27s4.22-24.33,2.13-35c-1-4.9-3.58-10.49-8.54-11.09"
        />
        <path
          class="cls-21"
          d="M724,1227.91c-.92-3.88-6.54-5.33-10.19-3.45s-5.52,5.88-6.43,9.75c-2.37,10.11-1.17,22.57,1.14,32.85,3.85,17,19.11,17.92,26.61,25.53-5.52-20-9.36-43.91-11.61-64.46"
        />
        <path
          class="cls-23"
          d="M855.33,1297c-28,1.87-77.68.41-77.68.41a35.5,35.5,0,0,1,31.09-7.09,25.41,25.41,0,0,0,6.3,1.25,23.65,23.65,0,0,0,5.73-1.13,43.87,43.87,0,0,1,35.7,6.44"
        />
        <path
          class="cls-23"
          d="M777.72,1298.43c28.54-3.23,77.82-.66,77.82-.66C817.37,1330.18,777.72,1298.43,777.72,1298.43Z"
        />
        <path
          d="M795.07,1201.75l-3.82-7.82a10.79,10.79,0,0,0-2.52-3.73,10.56,10.56,0,0,0-4.6-1.87q-9.07-2.1-18.27-3.64a18.47,18.47,0,0,0-7.47-.2c-4.45,1.09-7.46,5.1-10.1,8.85,3.1-2.72,7.59-3.06,11.71-2.88a67.85,67.85,0,0,1,33.59,10.69"
        />
        <path
          d="M796.58,1200.87l-2.86-5.87a23.65,23.65,0,0,0-3-5.32c-2.51-2.93-7.11-3.33-10.64-4.1q-6.59-1.44-13.23-2.58c-4-.7-8.09-1.21-11.82.83-3.54,1.94-6,5.37-8.23,8.62-1.2,1.74,1.33,3.34,2.75,2.13,2.62-2.24,6.4-2.48,9.69-2.37a62.24,62.24,0,0,1,11.69,1.57,66.72,66.72,0,0,1,11.12,3.58,91.14,91.14,0,0,1,10.51,5.59c2.34,1.38,4.27-2.09,2.1-3.6a57.44,57.44,0,0,0-11.7-5.93,67.43,67.43,0,0,0-12.31-3.55,64.78,64.78,0,0,0-12.58-1.22c-4,0-7.87.84-11,3.45l2.76,2.12c2.16-3,4.65-6.52,8.34-7.73,2.35-.77,4.83-.42,7.22,0s4.83.82,7.24,1.28,4.82.94,7.22,1.45c2.19.47,4.68.74,6.72,1.71s2.72,3,3.6,4.79l3.41,6.94c1,2,4,.26,3-1.76Z"
        />
        <path
          d="M839.32,1201.13l4-7.71a11,11,0,0,1,2.63-3.66,10.81,10.81,0,0,1,4.65-1.75q9.12-1.85,18.36-3.13a18.84,18.84,0,0,1,7.48,0c4.41,1.21,7.31,5.31,9.84,9.13-3-2.81-7.5-3.27-11.62-3.2a67.68,67.68,0,0,0-33.87,9.75"
        />
        <path
          d="M840.83,1202l3.6-6.86c1-1.83,1.79-3.85,3.82-4.69,2.21-.92,4.85-1.14,7.18-1.57s4.83-.86,7.26-1.24,4.82-.76,7.24-1.06,4.84-.5,7.08.44c3.48,1.46,5.76,4.86,7.79,7.86l2.75-2.12c-3-2.69-6.93-3.57-10.91-3.72a64.85,64.85,0,0,0-12.52.87,68.05,68.05,0,0,0-12.35,3.17,57.06,57.06,0,0,0-12,5.68,2.08,2.08,0,0,0,2.1,3.59,87.75,87.75,0,0,1,10.55-5.24,68.31,68.31,0,0,1,11.4-3.33,62.41,62.41,0,0,1,11.65-1.21c3.31,0,7,.35,9.6,2.66,1.41,1.27,3.89-.37,2.75-2.12-1.9-2.92-3.9-5.84-6.71-8-3.24-2.43-7-2.78-11-2.29-4.44.57-8.86,1.31-13.27,2.11-2.17.4-4.33.82-6.5,1.26a13.06,13.06,0,0,0-5.31,2c-1.75,1.31-2.71,3.27-3.69,5.16l-3.58,6.86c-1,2,2,3.76,3,1.77Z"
        />
        <circle cx="781.29" cy="1218.45" r="7.93" />
        <circle cx="857.27" cy="1218.45" r="7.93" />
        <path
          class="cls-25"
          d="M724.23,1230.56c-1.38-4.52-6.06-8.32-10.65-7.2-4.78,1.16-7.06,6.6-7.92,11.44-3.87,21.77,3.53,44.06,23.18,54.21"
        />
        <path
          class="cls-25"
          d="M907.14,1233.94c1.38-4.52,5.21-7.92,9.8-6.8,4.78,1.16,7.06,6.6,7.92,11.44,3.87,21.77-3.78,45.27-23.43,55.42"
        />
        <path
          class="cls-25"
          d="M908.06,1221.11c-1.08,23.54-3.37,63.91-10.29,86.52-2.42,7.9-8.07,15.44-13.63,21.28-16.83,17.69-37.89,31.26-62.32,35-18.39,2.84-39-8.61-53.41-19.06-7.71-5.6-14.21-10.31-20.62-17.3-5.53-6-10.71-15.2-13.49-23-2.87-8.08-4-17.26-5.19-25.72-2.5-17.43-3.34-35-4.6-52.56"
        />
        <path
          d="M801.93,1265.83a29.05,29.05,0,0,1,4.65,1.43l.23.09.07,0,.6.27,1,.5c.35.18.69.36,1,.55a5.1,5.1,0,0,0,1.4.7,1.6,1.6,0,0,0,1.76-.72,1.64,1.64,0,0,0-.24-1.89,4.26,4.26,0,0,0-1.28-.89l-1-.56c-.84-.43-1.69-.82-2.56-1.18a31.15,31.15,0,0,0-4.8-1.53,1.66,1.66,0,0,0-.88,3.2Z"
        />
        <path
          d="M791.85,1255.48a6.82,6.82,0,0,0-2.27,8.69,8,8,0,0,0,3.69,3.5,10.06,10.06,0,0,0,2.5.83,7.31,7.31,0,0,0,2.87-.11c1.27-.28.88-2.07-.29-2.16-2.44-.17-5.16-.89-6.42-3.18a5.19,5.19,0,0,1-.58-3,4,4,0,0,1,1.53-2.76c1-.75.11-2.45-1-1.76Z"
        />
        <path
          d="M829.52,1262.63a31.15,31.15,0,0,0-4.8,1.53c-.84.35-1.67.73-2.48,1.15-.37.19-.73.38-1.09.59a4.26,4.26,0,0,0-1.28.89,1.61,1.61,0,0,0-.24,1.89,1.63,1.63,0,0,0,1.76.72,5.1,5.1,0,0,0,1.4-.7c.33-.19.67-.37,1-.55l1-.5.6-.27.07,0,.23-.09a29.05,29.05,0,0,1,4.65-1.43,1.66,1.66,0,0,0,1.16-2,1.7,1.7,0,0,0-2-1.16Z"
        />
        <path
          d="M839.45,1257.24A4,4,0,0,1,841,1260a5.18,5.18,0,0,1-.58,3c-1.26,2.29-4,3-6.42,3.18-1.16.09-1.57,1.88-.29,2.16a7.31,7.31,0,0,0,2.87.11,9.62,9.62,0,0,0,2.43-.8,8,8,0,0,0,3.76-3.53,6.82,6.82,0,0,0-2.27-8.69c-1.14-.69-2.07,1-1,1.76Z"
        />
        <path
          d="M729.59,1183.27c16.55,5.29,35.9-4.15,48.18-15a70.86,70.86,0,0,0,15.25-19c4.27-7.72,5.43-16.19,8.9-24.14,3.86-8.83,13.49-17,23.67-16.23,9.08.66,9.82,11.16,17.64,13.59,3.7,1.14,7.35-1.07,11-1.65a25,25,0,0,1,16,2.63c9.14,5.06,12.3,15.13,14.24,24.72,3.66,18.11,5.1,44.25,26.73,50.16,2.85.78,4.06-3.62,1.22-4.43-21.19-6-19.86-35.25-24.14-52.39-4.23-17-17.74-29.22-36-26-2.24.39-5.75,2.15-7.93,1.29-1.83-.72-3.34-3.53-4.48-5-1.88-2.49-3.73-5.05-6.49-6.63-7.13-4.1-16.22-1.88-23,1.88A34,34,0,0,0,795,1126c-3,8.34-4.88,16.2-9.87,23.72a66.18,66.18,0,0,1-21.46,20.19c-9.29,5.5-21.46,10-32.19,6.62-4.32-1.38-6.17,5.4-1.87,6.78Z"
        />
        <path
          d="M733.83,1178.94c-6.7,8.34-7.12,22.21-8.11,32.37-.86,8.9-1.38,17.87-1.5,26.82l3.65-1c-14.83-20.68-7.42-50.4-23.74-70.5l-1.74,4.21c17.16-2.2,18.2-21.52,21.89-34.7,4.83-17.26,12.87-32,26.18-44.19,11.92-10.88,27.2-21,43.77-22.14a71.14,71.14,0,0,1,22.13,2.14c7.46,1.88,13.91,5.45,20.92,8.44,2.6,1.11,5.21-1.74,3.12-4-7.3-8.06-20.63-11-31-12.44a63.62,63.62,0,0,0-34.1,4.17,96.73,96.73,0,0,0-53.27,56.27,175.41,175.41,0,0,0-5.83,22.76c-1.71,8.2-5.07,17.53-14.5,18.89-2,.29-2.13,2.88-1.09,4.12,8.1,9.66,10,23.43,12.38,35.4,2.29,11.49,4.47,23.88,11.47,33.59a2,2,0,0,0,3.65-1c.07-7.87.49-15.76,1.14-23.61a177.19,177.19,0,0,1,2.44-19.07c.88-4.53,1.8-10.14,4.76-13.86,1.49-1.86-1.13-4.53-2.65-2.65Z"
        />
        <path
          d="M836.92,1081.28c28.74-8.8,62.7,3.88,72.78,33.31,5.27,15.39,7.33,31.52,9.29,47.6,1.7,14,4.6,28.25,15.31,38.25v-5.14c-5.8,6.87-15.31,8.74-21.37,15.34-6.57,7.15-5.49,17.5-9.09,26l5.56,1.51c.6-14.45,2-29.56-.16-43.92-.43-2.87-5.2-2.19-5.07.69.67,14.33-.21,28.9-.74,43.23-.13,3.66,4.66,3.66,5.87.79,3.54-8.43,2.43-18.83,10.14-25.05,6.35-5.12,14.49-7.11,20-13.43a3.71,3.71,0,0,0,0-5.14c-12.73-12.23-11.87-31-14.22-47.15-2.29-15.79-5.41-33.44-13.55-47.41-15-25.71-49.06-34.16-76.08-24.34-3,1.1-1.82,5.85,1.34,4.88Z"
        />
        <line
          class="cls-27"
          x1="895.78"
          y1="1185.26"
          x2="1029.12"
          y2="1185.26"
        />
        <circle class="cls-10" cx="1036.11" cy="1185.26" r="10.13" />
        <line
          class="cls-27"
          x1="742.96"
          y1="1253.09"
          x2="624.25"
          y2="1253.09"
        />
        <circle class="cls-10" cx="617.26" cy="1253.09" r="10.13" />
        <circle class="cls-10" cx="817.41" cy="1142.58" r="4.77" />
        <circle class="cls-10" cx="817.41" cy="1172.29" r="4.77" />
        <circle class="cls-10" cx="851.99" cy="1168.74" r="4.77" />
        <circle class="cls-10" cx="892.34" cy="1186.48" r="4.77" />
        <circle class="cls-10" cx="888.35" cy="1218.4" r="4.77" />
        <circle class="cls-10" cx="861.75" cy="1239.68" r="4.77" />
        <circle class="cls-10" cx="864.41" cy="1267.62" r="4.77" />
        <circle class="cls-10" cx="868.84" cy="1291.56" r="4.77" />
        <circle class="cls-10" cx="855.54" cy="1316.83" r="4.77" />
        <circle class="cls-10" cx="893.67" cy="1253.43" r="4.77" />
        <circle class="cls-10" cx="893.23" cy="1281.8" r="4.77" />
        <circle class="cls-10" cx="881.7" cy="1318.61" r="4.77" />
        <circle class="cls-10" cx="854.65" cy="1340.77" r="4.77" />
        <circle class="cls-10" cx="912.29" cy="1276.04" r="4.77" />
        <circle class="cls-10" cx="845.78" cy="1139.92" r="4.77" />
        <circle class="cls-10" cx="783.27" cy="1168.74" r="4.77" />
        <circle class="cls-10" cx="742.92" cy="1186.48" r="4.77" />
        <circle class="cls-10" cx="746.91" cy="1218.4" r="4.77" />
        <circle class="cls-10" cx="773.51" cy="1239.68" r="4.77" />
        <circle class="cls-10" cx="770.85" cy="1267.62" r="4.77" />
        <circle class="cls-10" cx="766.42" cy="1291.56" r="4.77" />
        <circle class="cls-10" cx="779.72" cy="1316.83" r="4.77" />
        <circle class="cls-10" cx="741.59" cy="1253.43" r="4.77" />
        <circle class="cls-10" cx="742.03" cy="1281.8" r="4.77" />
        <circle class="cls-10" cx="753.56" cy="1318.61" r="4.77" />
        <circle class="cls-10" cx="780.61" cy="1340.77" r="4.77" />
        <circle class="cls-10" cx="722.96" cy="1276.04" r="4.77" />
        <circle class="cls-10" cx="789.47" cy="1139.92" r="4.77" />
        <circle class="cls-10" cx="817.41" cy="1201.99" r="4.77" />
        <circle class="cls-10" cx="817.41" cy="1231.7" r="4.77" />
        <circle class="cls-10" cx="817.41" cy="1256.86" r="4.77" />
        <circle class="cls-10" cx="817.41" cy="1281.8" r="4.77" />
        <circle class="cls-10" cx="817.41" cy="1326.92" r="4.77" />
        <circle class="cls-10" cx="817.41" cy="1350.53" r="4.77" />
        <text class="cls-8" transform="translate(128.83 634.8)">
          各部位
          <tspan class="cls-28" x="120" y="0" xml:space="preserve">\</tspan>
          <tspan x="153.6" y="0">代表性格</tspan>
        </text>
        <text class="cls-8" transform="translate(667.05 634.8)">
          5型人
          <tspan class="cls-28" x="105.9" y="0" xml:space="preserve">\</tspan>
          <tspan class="cls-29" x="130.5" y="0"></tspan>
          <tspan x="140.94" y="0">代表性格</tspan>
        </text>
        <text class="cls-30" transform="translate(140.76 702.44)">
          髮
          <tspan class="cls-11" x="36.17" y="0">、</tspan>
          <tspan class="cls-31" x="61.28" y="0"></tspan>
          <tspan class="cls-18" x="70.12" y="0">額</tspan>
          <tspan class="cls-11" x="106.29" y="0">、</tspan>
          <tspan class="cls-32" x="131.4" y="0"></tspan>
          <tspan x="140.24" y="0">印堂</tspan>
          <tspan class="cls-11" x="209.27" y="0">、</tspan>
          <tspan class="cls-31" x="234.38" y="0"></tspan>
          <tspan class="cls-18" x="243.21" y="0">眉</tspan>
          <tspan class="cls-11" x="279.39" y="0">、</tspan>
          <tspan class="cls-32" x="304.5" y="0"></tspan>
          <tspan x="0" y="41.13">眼</tspan>
          <tspan class="cls-11" x="36.17" y="41.13">、</tspan>
          <tspan class="cls-31" x="61.28" y="41.13"></tspan>
          <tspan class="cls-18" x="70.12" y="41.13">鼻</tspan>
          <tspan class="cls-11" x="106.29" y="41.13">、</tspan>
          <tspan class="cls-32" x="131.4" y="41.13"></tspan>
          <tspan x="140.24" y="41.13">口</tspan>
          <tspan class="cls-12" x="176.41" y="41.13">、</tspan>
          <tspan class="cls-31" x="201.52" y="41.13"></tspan>
          <tspan x="210.35" y="41.13">牙齒</tspan>
          <tspan class="cls-11" x="279.39" y="41.13">、</tspan>
          <tspan class="cls-32" x="304.5" y="41.13"></tspan>
          <tspan x="-31.62" y="82.25">舌頭</tspan>
          <tspan class="cls-11" x="37.41" y="82.25">、</tspan>
          <tspan class="cls-32" x="62.52" y="82.25"></tspan>
          <tspan x="71.36" y="82.25">山根</tspan>
          <tspan class="cls-12" x="140.39" y="82.25">、</tspan>
          <tspan class="cls-31" x="165.5" y="82.25"></tspan>
          <tspan class="cls-18" x="174.34" y="82.25">耳</tspan>
          <tspan class="cls-11" x="210.51" y="82.25">、</tspan>
          <tspan class="cls-32" x="235.62" y="82.25"></tspan>
          <tspan x="244.45" y="82.25">顴骨</tspan>
          <tspan class="cls-12" x="313.49" y="82.25">、</tspan>
          <tspan class="cls-33" x="338.6" y="82.25"></tspan>
          <tspan x="0.42" y="123.38">人中</tspan>
          <tspan class="cls-11" x="69.45" y="123.38">、</tspan>
          <tspan class="cls-32" x="94.56" y="123.38"></tspan>
          <tspan x="103.39" y="123.38">法令</tspan>
          <tspan class="cls-12" x="172.43" y="123.38">、</tspan>
          <tspan class="cls-31" x="197.54" y="123.38"></tspan>
          <tspan class="cls-18" x="206.37" y="123.38">地閣</tspan>
          <tspan class="cls-11" x="275.41" y="123.38">。</tspan>
        </text>
        <text class="cls-9" transform="translate(639.5 702.44)">
          以面相特徵
          <tspan class="cls-19" x="171.33" y="0">，</tspan>
          <tspan class="cls-32" x="196.44" y="0"></tspan>
          <tspan class="cls-17" x="205.28" y="0">將人劃分為</tspan>
          <tspan class="cls-10">
            <tspan x="39.95" y="45.13" xml:space="preserve">金</tspan>
            <tspan class="cls-34" x="85.11" y="45.13"></tspan>
            <tspan class="cls-12" x="88.43" y="45.13">、</tspan>
            <tspan class="cls-35" x="113.54" y="45.13">木</tspan>
            <tspan class="cls-36" x="147.02" y="45.13"></tspan>
            <tspan class="cls-12" x="150.33" y="45.13">、</tspan>
            <tspan class="cls-35" x="175.44" y="45.13">水</tspan>
            <tspan class="cls-36" x="208.92" y="45.13"></tspan>
            <tspan class="cls-19" x="212.23" y="45.13">、</tspan>
            <tspan class="cls-17" x="237.34" y="45.13">火</tspan>
            <tspan class="cls-36" x="270.82" y="45.13"></tspan>
            <tspan class="cls-19" x="274.14" y="45.13">、</tspan>
            <tspan class="cls-17" x="299.25" y="45.13">土</tspan>
          </tspan>
          <tspan class="cls-17">
            <tspan x="110.18" y="90.25" xml:space="preserve">5種類別</tspan>
            <tspan class="cls-19" x="236.77" y="90.25">。</tspan>
          </tspan>
        </text>
        <circle class="cls-20" cx="293.05" cy="374.73" r="178.24" />
        <path
          class="cls-37"
          d="M244.4,255.81c-4.93-5.42-13.23-6.86-20.32-5S211,257.4,205.87,262.69c-18.24,19-26.1,45.58-30.15,71.57s-8.44,49.92-15.63,75.22c-4.34,15.25-7.5,33.8-3.28,49.08,2.12,7.67,6.84,14.37,12.14,20.3,7.92,8.87,17.76,16.63,29.36,19.22,6.12,1.36,12.49,1.24,18.62,2.53,8.5,1.8,6.64,7.13,15.15,8.89s28.52-1.07,32.58-8.75c21,9.23,45.16.51,66.4-8.2,6.2,9.52,17.15,15.28,28.38,17,23.27,3.56,47.1-9.46,60.41-28.87s16.63-38.55,10.54-61.28c-6.47-24.14-13.2-49.41-21.13-71.57-9-25.18-19.58-63.74-35.35-85.35s-36.57-39-62.94-43.55-56.51,11.25-69,34.92"
        />
        <path
          class="cls-21"
          d="M209.66,387.93c.86-21.56,2.45-37.65,8.19-60.79s-.64-30.31,21.13-40.52c15.56-7.31,10.53-13.6,27.59-11.21,14.79,2.07,32.93,36.34,44.83,45.26,17.43,13.07,28,36.65,71.14,53.46,12.8,5,1.5,24.84-1.77,38.2s-6.55,26.75-10.09,40.06c-1.9,7.14-3.92,14.37-7.82,20.67-8.08,13.07-21.09,20.33-35.18,28.89-6.84,4.15-15.19,9.22-23.16,10.07-10.2,1.09-23.6-1.6-32.84-6-7.74-3.68-18-11.82-24.51-17.37-14.73-12.62-21.55-27.58-27.62-45.89s-6.72-41.6-8.42-60.8"
        />
        <circle cx="261.26" cy="384.74" r="8.1" />
        <circle cx="329.28" cy="384.74" r="8.1" />
        <path
          class="cls-21"
          d="M208,381.65c-.9-3.77-6.36-5.18-9.92-3.35s-5.36,5.72-6.24,9.48c-2.3,9.83-1.37,22.21,3.51,31.22s16.17,18.15,23.47,25.54c-5.37-19.46-9.1-42.69-11.29-62.67"
        />
        <path
          class="cls-38"
          d="M208.18,384.23c-1.34-4.4-5.89-8.09-10.36-7-4.64,1.13-6.86,6.42-7.69,11.12-3.77,21.17,7.59,44.15,26.7,54"
        />
        <path
          class="cls-21"
          d="M385.8,380.79c.89-3.77,6.36-5.18,9.91-3.35s5.37,5.72,6.25,9.48c2.3,9.83,1.36,22.21-3.52,31.22S382.27,436.29,375,443.68c5.36-19.46,9.1-42.69,11.28-62.67"
        />
        <path
          class="cls-38"
          d="M385.6,383.37c1.34-4.4,5.9-8.09,10.36-7,4.65,1.13,6.86,6.42,7.7,11.13,3.77,21.16-7.6,44.15-26.7,54"
        />
        <path
          class="cls-38"
          d="M385.55,374.56c-1.05,22.9-5.06,52.76-11.78,74.75a100.16,100.16,0,0,1-7.57,19c-12,21.88-52.86,41.88-66.63,43.18l-3.79,0c-13.95-1.15-53.78-20.7-67.08-43.22-3.47-5.89-5.57-12.48-7.57-19a299.88,299.88,0,0,1-12.67-69.23"
        />
        <path
          class="cls-38"
          d="M274,350.51c-1.82-1.5-4.36-1.66-6.72-1.68a62,62,0,0,0-36.89,11.46"
        />
        <path
          class="cls-38"
          d="M315.45,350.51c1.82-1.5,4.36-1.66,6.72-1.68a62,62,0,0,1,36.89,11.46"
        />
        <path
          class="cls-38"
          d="M290.82,404.85c-.64,5-2.22,14.27-.28,18.95.76,1.85,1.93,3.63,1.93,5.63"
        />
        <path class="cls-38" d="M304.73,428.45a9.42,9.42,0,0,1,4.85-2.88" />
        <path
          class="cls-38"
          d="M389.86,375.86c-44-9.49-75.31-53.58-103.42-83.74-5.91-6.34-12.23-12.73-20.35-15.75-15.36-5.7-26.73,11.42-26.73,11.42-6.29.19-11.89,4.5-15.18,9.87s-4.61,11.67-5.6,17.88c-3.14,19.79-9.94,66.79-10.53,66"
        />
        <path
          class="cls-39"
          d="M273.64,454a53.09,53.09,0,0,0,25.1,8.16c5.86.36,14.9.18,19.26-4.75,4.72-5.35-2.15-8.54-6.75-8.74-9.6-.42-19.16-1.49-28.73-2.33-5.78-.51-5.91,8.89,0,9.11l19.25.69c2.3.08,4.61.19,6.91.19,1.3,0,3.86-.46,4.95.37l-1.74-2.26c1.13-2.18,1-2.76-.29-1.72l-1.1.5a16.9,16.9,0,0,1-2.78.87,37.21,37.21,0,0,1-9.83.53A47.62,47.62,0,0,1,277,448.28c-3.69-2.08-6.88,3.43-3.31,5.67Z"
        />
        <path
          class="cls-38"
          d="M270.29,444.71c13.09,14.13,40.48,12.57,52.54,1.85"
        />
        <path
          class="cls-38"
          d="M272.63,446.92a14.48,14.48,0,0,0,7.67,1.32c3.62-.38,7.37-2.11,10.73-.71,1.05.44,2,1.16,3.07,1.5,3.24,1,6.48-1.71,9.87-1.84,2.06-.07,4,.81,6,1.31,3.39.86,8.81.81,12.2-1.3"
        />
        <path
          class="cls-38"
          d="M271.32,447.44a21.24,21.24,0,0,0,8.33,11,30.86,30.86,0,0,0,13,4.88A31.69,31.69,0,0,0,310.83,461a22.55,22.55,0,0,0,12-13.44"
        />
        <path
          class="cls-38"
          d="M215.27,500.44c12.3,10.14,39.68,13.82,50.87,0"
        />
        <path
          class="cls-38"
          d="M242.06,251.21c-10.82-3.65-23.15.26-31.88,7.62s-14.4,17.72-18.82,28.25c-13.46,32-17.07,67.16-25.1,100.95-3,12.5-6.57,24.88-8.3,37.6s-1.5,26,3,38a55.79,55.79,0,0,0,63.44,35.08"
        />
        <path
          class="cls-38"
          d="M242.86,251.7c15.14-22.61,40.76-37.76,67.71-34,25.58,3.55,46.5,22.54,61.32,43.69a191.36,191.36,0,0,1,21.54,40.24c3.85,9.87,7.19,18.75,9.82,29.15,2.92,11.56,6.05,23.07,9.79,34.38,7.65,23.18,17.93,46.06,18.89,70.45.51,12.94-1.72,26.09-7.33,37.76s-14.72,21.78-26.19,27.79c-20.13,10.53-43.13,13.09-61.69-1.77"
        />
        <line class="cls-27" x1="346.92" y1="275.97" x2="465.64" y2="275.97" />
        <circle class="cls-10" cx="472.63" cy="275.97" r="10.13" />
        <line class="cls-27" x1="293.05" y1="325.85" x2="363.88" y2="325.85" />
        <circle class="cls-10" cx="370.87" cy="325.85" r="10.13" />
        <line class="cls-27" x1="375.86" y1="401.67" x2="446.69" y2="401.67" />
        <circle class="cls-10" cx="453.67" cy="401.67" r="10.13" />
        <line class="cls-27" x1="253.14" y1="385.71" x2="158.37" y2="385.71" />
        <circle class="cls-10" cx="151.39" cy="385.71" r="10.13" />
        <line class="cls-27" x1="299.04" y1="416.63" x2="247.16" y2="416.63" />
        <circle class="cls-10" cx="240.18" cy="416.63" r="10.13" />
        <line class="cls-27" x1="280.07" y1="460.53" x2="195.29" y2="460.53" />
        <circle class="cls-10" cx="188.3" cy="460.53" r="10.13" />
        <circle class="cls-20" cx="824.94" cy="376.73" r="178.24" />
        <path
          class="cls-21"
          d="M737.3,397.45c-5.82-22.92-4.16-44.43,1.54-67.38s20.41-44.45,42-54.58c15.44-7.25,33.27-8.3,50.19-5.93,14.67,2.06,29.25,6.72,41.06,15.57,17.29,13,27,33.44,34.11,53.69,5.5,15.61,9.93,31.86,9.75,48.39-.14,13.63,2.34,28.77-.9,42s-6.5,26.54-10,39.75c-1.88,7.08-9.6,15.88-13.47,22.13-8,12.95-21,16.78-34.94,25.27-6.78,4.12-15.06,9.14-23,10-10.11,1.08-23.41-1.59-32.57-6-7.68-3.65-17.14-12.72-24.31-17.23-17.67-11.11-24.16-25-30.18-43.18s-9.22-44.14-10.9-63.19"
        />
        <path
          class="cls-40"
          d="M822.61,248.64C811.94,239,791.22,234.16,778,240s-26.89,17.79-34.31,30.15c-6.24,10.37-9.17,22.83-18.29,30.79-7.8,6.81-20,11.65-25.33,20.52-5.52,9.16-2.9,26.91-6.74,36.89s-13.67,14.19-13.79,25c-.05,4.83,5.44,8.68,7.95,12.81,4.15,6.8,10.18,10.78,17.07,14.77,2,1.14,10.17,2.31,12.19,1.29a6.89,6.89,0,0,0,2.74-3.39c2.75-5.74-1.16-11.08,4.6-13.77,2.52-1.17,8.58.26,11.26-.48,11.57-3.2,15.94-15.38,17.64-27.26s-.5-27.57,6.77-37.12c5.28-7,16.3-11.83,22.09-18.37,8.2-9.26,7.71-21.65,15.06-31.6s15.91-12.73,28.24-12S849.83,271,856,281.67c4.72,8.23,5.91,23.1,11.87,30.47s19.52,8.52,25.06,16.34c6.84,9.66,5.52,22.6,6.24,34.42s4.89,25,15.5,30.55c5.05,2.63,11.28,3.36,15.17,7.53,1.55,1.67,3.19,8.13,5.29,9,2.38,1,5.08,0,7.46-1,5-2.12,9.62-8.55,13.75-12.08s9.28-9.43,9-14.86c-.32-7.09-10.05-11.26-13.14-17.64-5.15-10.61-1-23.94-4.57-35.17-5.2-16.27-21.08-26.3-31.79-39.6-11.41-14.18-18.7-35.78-33.88-45.82-17.52-11.59-41.06-8.34-58.86,5.43"
        />
        <path
          class="cls-41"
          d="M852.3,459.87c0,4.28-51.39,4.54-51.41.27a23.47,23.47,0,0,1,20.57-4.69,16.75,16.75,0,0,0,4.17.82,15.5,15.5,0,0,0,3.8-.74,29,29,0,0,1,23.62,4.26"
        />
        <path
          class="cls-41"
          d="M853,459.92c-8.12,4.76-44,4.32-52.18.1,0,0-.12,0,0,.43,1.95,7.2,9.07,13.77,16.11,16.23a32.28,32.28,0,0,0,9.8,1.7,29.89,29.89,0,0,0,16.14-3.83c4.8-2.89,9.28-8.95,10.21-14.48Z"
        />
        <path
          d="M807,353.65l-6-5.91a10.59,10.59,0,0,0-3.48-2.6,10.44,10.44,0,0,0-4.79-.24q-8.93,1-17.79,2.5a18,18,0,0,0-6.87,2.2c-3.7,2.41-5.17,7-6.37,11.27,1.95-3.46,5.94-5.2,9.74-6.34a65.3,65.3,0,0,1,34-1"
        />
        <path
          d="M808.16,352.45l-4.74-4.69a21.58,21.58,0,0,0-4.16-3.65,9.42,9.42,0,0,0-5.65-1c-2.14.18-4.29.46-6.42.73q-6.39.8-12.72,1.86c-3.6.61-7.21,1.56-9.7,4.42s-3.7,6.66-4.7,10.31a1.7,1.7,0,0,0,3.09,1.3c1.56-2.67,4.46-4.13,7.3-5.09a56.51,56.51,0,0,1,11.19-2.47,65.18,65.18,0,0,1,11.6-.38,83.16,83.16,0,0,1,11.64,1.73c2.51.5,3.59-3.18,1.07-3.88-8.22-2.27-17.34-2.22-25.71-.92a57.39,57.39,0,0,0-11.76,3c-3.33,1.3-6.37,3.16-8.25,6.29l3.09,1.3c1-3.4,2.08-7.17,4.86-9.57a11.84,11.84,0,0,1,5.53-2.32c2.38-.49,4.8-.85,7.2-1.23s4.81-.72,7.23-1a50,50,0,0,1,7-.72c2.28,0,3.64,1.51,5.16,3l5.45,5.38a1.69,1.69,0,0,0,2.39-2.39Z"
        />
        <path
          d="M849.4,353.55l6.38-5.46a10.52,10.52,0,0,1,3.66-2.33,10.23,10.23,0,0,1,4.79.11q8.84,1.62,17.56,3.79a17.82,17.82,0,0,1,6.69,2.69c3.52,2.67,4.64,7.38,5.53,11.7-1.69-3.59-5.54-5.61-9.25-7a65.32,65.32,0,0,0-33.82-3.43"
        />
        <path
          d="M850.59,354.74l5.63-4.8c1.63-1.39,3.1-2.79,5.38-2.72a43.32,43.32,0,0,1,6.94,1.23c2.45.51,4.91,1,7.35,1.61s4.61,1.06,6.88,1.69a11.84,11.84,0,0,1,5.39,2.71c2.66,2.6,3.46,6.54,4.22,10l3.09-1.3c-1.61-3.2-4.58-5.32-7.76-6.79a60.93,60.93,0,0,0-11.68-3.9c-8.24-1.9-17.29-2.52-25.63-.86-2.18.44-1.73,4.23.54,4a87.36,87.36,0,0,1,11.87-.92,63.45,63.45,0,0,1,11.58,1.19,58.86,58.86,0,0,1,11,3.21c2.9,1.2,5.78,2.91,7.2,5.82.89,1.81,3.44.48,3.09-1.3-.63-3.21-1.36-6.53-3.11-9.35-2-3.27-5.19-4.95-8.81-5.93-4.08-1.1-8.23-2-12.36-2.82q-3.21-.66-6.44-1.24a13.48,13.48,0,0,0-5.72-.2c-2,.52-3.63,2-5.19,3.32l-5.81,5c-1.65,1.41.75,3.78,2.39,2.38Z"
        />
        <path
          class="cls-42"
          d="M788.25,361.86A27.16,27.16,0,1,0,815.41,389,27.16,27.16,0,0,0,788.25,361.86Zm0,49A21.86,21.86,0,1,1,810.12,389,21.86,21.86,0,0,1,788.25,410.88Z"
        />
        <path
          class="cls-42"
          d="M868.6,361.86A27.16,27.16,0,1,0,895.75,389,27.16,27.16,0,0,0,868.6,361.86Zm0,49A21.86,21.86,0,1,1,890.46,389,21.87,21.87,0,0,1,868.6,410.88Z"
        />
        <path
          d="M856.29,410.92c-8.08-1.16-14.18-8.29-17.06-15.93-1.48-3.93-2.62-8.49-6.24-10.62s-8.35-.84-11.48,1.87-5,6.57-6.74,10.32-3.57,7.62-6.65,10.39L812,377.86a8,8,0,0,0,5.83.57c1.95-.45,3.79-1.28,5.71-1.84a20.89,20.89,0,0,1,11.39-.08c3.64,1,7.49,3,11,1.57l-1.1,17.77Z"
        />
        <circle cx="791.53" cy="387.92" r="7.65" />
        <circle cx="866.41" cy="387.92" r="7.65" />
        <path
          class="cls-21"
          d="M734.38,397.05c-.88-3.74-6.31-5.14-9.83-3.32s-5.32,5.66-6.2,9.39c-2.28,9.76-1.35,22,3.49,31s16,18,23.28,25.34c-5.32-19.3-9-42.34-11.2-62.17"
        />
        <path
          class="cls-43"
          d="M734.58,399.6c-1.34-4.36-5.85-8-10.28-6.94-4.61,1.12-6.8,6.37-7.64,11-3.73,21,7.54,43.79,26.49,53.57"
        />
        <path
          class="cls-21"
          d="M920.75,400c-4.34,20.23-6.52,39.85-11.36,60a49.47,49.47,0,0,0,25.4-53.21c-.93-4.73-3.45-10.11-8.24-10.7"
        />
        <path
          class="cls-43"
          d="M920,402.86c1.33-4.36,5-7.63,9.45-6.56,4.61,1.12,6.8,6.37,7.63,11,3.74,21-7.53,43.79-26.48,53.58"
        />
        <path
          d="M805.64,419.45a9.07,9.07,0,0,0-6.76,5.93,8.51,8.51,0,0,0,2.47,8.72,9.25,9.25,0,0,0,9.13,2.06c1.65-.5,3.23-1.44,5-1.7s2.72.89,4,1.91a11.32,11.32,0,0,0,8.83,2.51,11,11,0,0,0,6.3-2.95c.58-.56,1-1.3,1.66-1.81,1.16-.94,2.7,0,3.85.53a13.75,13.75,0,0,0,4.84,1.6,10.07,10.07,0,0,0,8.11-3.33,9,9,0,0,0,1.47-9.91,10.12,10.12,0,0,0-8.47-5.68c-2.08-.1-2.07,3.13,0,3.24a6.09,6.09,0,0,1,5.33,4.31,5.34,5.34,0,0,1-1.58,5.93,6.13,6.13,0,0,1-5.92,1.37c-2.66-.88-5.07-2.93-8-2.1-2.57.72-3.62,3.5-6,4.51a7.84,7.84,0,0,1-6.77-.32,33.56,33.56,0,0,1-3-2.35,6.64,6.64,0,0,0-4.42-1.27c-2.83.22-5.29,2.26-8.12,2.26a5.56,5.56,0,0,1-5.35-4.7,6.09,6.09,0,0,1,4.23-6.35,1.25,1.25,0,0,0-.67-2.41Z"
        />
        <path
          class="cls-43"
          d="M918.51,397.77c-1,22.72-5.38,44.58-12.06,66.39-2,6.49-4.11,13-7.5,18.87-6.68,11.54-21,21.41-31.76,29.24-11.36,8.25-24.77,15.12-38.44,16.4l-3.76,0c-13.83-1.14-28.41-9.38-39.9-17.72-10.79-7.83-23.8-16.42-30.47-28-3.39-5.88-5.52-12.38-7.51-18.87a297.52,297.52,0,0,1-12.57-68.67"
        />
        <path
          d="M795,493.35c-.73,8.58-.69,17,2.52,25.1,1.24,3.11,6.15,2,5.15-1.41-2.32-7.78-4.58-15.51-4.5-23.69,0-2.07-3-2-3.17,0Z"
        />
        <path
          d="M802.93,499.12c.23,8.26-.38,22.22,8.09,26.89a2.39,2.39,0,0,0,3.46-2.66c-1.06-3.68-3.46-6.72-4.8-10.31a62.87,62.87,0,0,1-2.95-13.92c-.3-2.34-3.86-2.52-3.8,0Z"
        />
        <path
          d="M812.09,503.28c.52,7.45.66,18.83,6,24.57,1,1.13,3.42.32,3.24-1.34-.4-3.73-2.37-6.82-3.64-10.31a49.82,49.82,0,0,1-2.92-12.92c-.15-1.72-2.84-1.75-2.71,0Z"
        />
        <path
          d="M820.06,503.93c.56,4.87.94,9.76,1.56,14.62.46,3.62.18,7.91,3.6,9.9a1.67,1.67,0,0,0,2.23-.58c2.26-3.09.33-7-.48-10.42-1.1-4.67-2.26-9.3-3.09-14-.38-2.13-4.07-1.66-3.82.52Z"
        />
        <path
          d="M831.38,503.3c.27,4.13.37,8.25.27,12.39-.08,3.37-1.22,7.2.87,10a1.94,1.94,0,0,0,3,.38c2.5-2.66,1.82-6.12,1.72-9.61a130,130,0,0,0-1.14-13.21c-.39-2.9-4.9-3.09-4.71,0Z"
        />
        <path
          d="M838.89,501c1.09,6.74.5,13.68,1.59,20.38.54,3.33,5.1,2.09,5.42-.73.76-6.78-1.44-14.23-3-20.76-.63-2.65-4.43-1.48-4,1.11Z"
        />
        <path
          d="M849.7,493.35a61.12,61.12,0,0,1,.36,12.61c-.3,3.83-1.6,7.55-1.06,11.35.27,1.91,2.76,1.66,3.64.48,4.69-6.3,2.76-17.84,1.15-25-.51-2.24-4.4-1.8-4.09.55Z"
        />
        <path
          d="M858.77,497.52A81.83,81.83,0,0,1,857.06,510c-.78,3.62,4.59,4.44,5.06.69a85.13,85.13,0,0,0,.63-13.16,2,2,0,0,0-4,0Z"
        />
        <path
          d="M823.73,267.17c-13.81-1.86-26.15,7-32.84,18.67-3.92,6.85-3.33,15-6.79,21.91-3.77,7.53-13,9.64-19.33,14.32a33.56,33.56,0,0,0-12,15.35c-3.06,8.51-1.16,17.57-1.26,26.36-.07,7.28-1.26,15-6.21,20.69S733.87,391,726.72,391c-2.34,0-2.46,3-.52,3.82,11.73,4.72,23.32-3.4,27.81-14.17,2.7-6.48,3.08-13.71,2.8-20.64-.39-9.45-1.69-18.87,4.24-27,5-6.85,12.34-9.56,19.17-14,6.33-4.11,9.52-9.55,11.31-16.77,2-8.07,3.61-14.31,9.38-20.62,5.92-6.49,13.23-11.23,22.33-10.84,2,.09,2.59-3.36.49-3.64Z"
        />
        <path
          d="M940.43,412.12c12.38-5.94,40.61-26.32,22.06-40.45-11.43-8.71-7.52-21.44-9.59-33.94a45.86,45.86,0,0,0-14.74-26.52A214.78,214.78,0,0,1,917,290c-3.58-4.2-5.76-9.59-8.21-14.48-6.78-13.52-14.7-28.13-28.27-35.92-7.8-4.48-17.72-6.4-26.65-6.19-11.9.27-22.3,5.46-31.32,12.94h3.39c-12.42-9.92-27.55-15.29-43.32-10.8-18,5.13-35.06,20.61-43.29,37.18-4.37,8.79-7.57,18.78-15,25.55-7.82,7.11-17.27,11.14-23.5,20.07-6,8.57-7.86,18.13-8.15,28.38-.16,5.77-.08,11.69-2.45,17.07s-7.62,7.06-11,11.56c-6.34,8.48,2.87,19,8.48,25.13,6.28,6.85,16.94,15.42,26.87,14.13,1.73-.22,1.86-2.81,0-2.8-8.47.05-18.42-8.46-23.77-14.43-3.29-3.68-7.32-7.9-8.85-12.67-2.23-6.94,3.28-8.78,7.33-12.7,5.81-5.64,6.78-14,6.93-21.64.19-10.4,1.26-19.84,7.33-28.64,5.83-8.45,14.56-12.54,22.38-18.71,6.19-4.88,9.82-11.42,13.14-18.45,3.94-8.36,7.46-16.22,13.81-23.08,6.5-7,13.83-13.47,22.39-17.87,16.3-8.39,33.32-5.06,47.3,6.11a2.49,2.49,0,0,0,3.39,0c14.38-11.93,34.79-15.65,51.7-6.34,12.69,7,20.12,20.61,26.45,33.09,2.39,4.7,4.55,9.65,7.47,14.06,4,6.11,9.78,11.52,14.83,16.78C932.81,314,940,319.37,944,328.11c4.76,10.38,2.59,21.22,4.62,32.07a24.51,24.51,0,0,0,5.53,12,48.64,48.64,0,0,0,5.65,4.67c3.82,3.12,3.85,6.72,1.65,10.9-4.81,9.17-14.23,16-23.17,20.67-2.44,1.28-.29,4.84,2.15,3.67Z"
        />
        <path
          d="M828.32,270.81c9.12-.4,16.4,4.34,22.34,10.84,5.26,5.76,7.26,11.51,9,19,1.66,7.18,4.23,13.14,10.39,17.48,6.51,4.59,14,7,19.25,13.27,6.64,7.86,6.07,17.08,5.56,26.71-.39,7.26,0,14.42,2.55,21.31,4,10.72,14.86,23.78,27.71,18.91,2-.75,1.79-3.78-.52-3.82-17.29-.35-24.73-17.42-24.54-32.64.11-8.76,1.67-17.57-1.84-25.92a34.11,34.11,0,0,0-12.06-14.38c-6.29-4.47-15-6.54-18.69-13.86-3.52-6.9-2.88-15.06-6.8-21.9-6.5-11.38-18.8-20.57-32.35-18.75-2.28.31-2.46,3.82,0,3.71Z"
        />
        <circle class="cls-44" cx="822.95" cy="208.47" r="26.6" />
        <path
          class="cls-10"
          d="M838.64,204c-.77,1.76-2.29,2.32-4.15,1.4a23.82,23.82,0,0,1-3.13-1.83,1.52,1.52,0,0,1-1.44.92H826v3.17h8.06a1.42,1.42,0,0,1,1.55,1.58,1.36,1.36,0,0,1-1.55,1.51H826v8.76h1.59l-.25-.21a1.49,1.49,0,0,1-.14-2c.63-1.13,1.51-3,2.29-4.54.88-1.8,2.28-2.15,3.8-1.23s1.79,2.21.39,3.94a20.62,20.62,0,0,1-4.33,3.87,1.24,1.24,0,0,1-.32.17h6.62a1.41,1.41,0,0,1,1.54,1.55,1.46,1.46,0,0,1-1.54,1.62H810.35a1.46,1.46,0,0,1-1.55-1.62,1.42,1.42,0,0,1,1.55-1.55h4.89a2.91,2.91,0,0,1-1.12-1.65c-.5-1.44-1.09-3-1.83-4.68-.36-.85-.25-1.55.42-1.94a1.59,1.59,0,0,1,2,.25,18.87,18.87,0,0,1,3.84,4.15,2.44,2.44,0,0,1-.92,3.84s0,0-.07,0h2.67v-8.76h-8.48a1.55,1.55,0,0,1,0-3.09h8.48v-3.17h-4a1.47,1.47,0,0,1-1.27-.63,27.85,27.85,0,0,1-6.33,2.7,1.21,1.21,0,0,1-1.66-.77,1.3,1.3,0,0,1,.71-1.72,30.48,30.48,0,0,0,12.24-12.28,2.88,2.88,0,0,1,4.19-.92,2.33,2.33,0,0,1,1.16,3.17,34.25,34.25,0,0,0,11.23,6.05C838.64,200.86,839.35,202.44,838.64,204Zm-10.2-2.61a25.7,25.7,0,0,1-4.61-5.06,28.43,28.43,0,0,1-5.21,5.06Z"
        />
        <circle class="cls-44" cx="993.88" cy="366.4" r="26.6" />
        <path
          class="cls-10"
          d="M1008.9,376.45c-1.3,1.59-3,1.87-4.75.32a31.83,31.83,0,0,1-7.22-10.94v13.23c0,1.9-1,2.88-3,2.88s-3-1-3-2.88V366.71A32.14,32.14,0,0,1,980.22,378a1.23,1.23,0,0,1-1.86-.36,1.32,1.32,0,0,1,.35-1.9,38.51,38.51,0,0,0,10.41-16.36h-7.91a1.83,1.83,0,0,1,0-3.66H991v-4.43c0-1.9,1.06-2.75,3-2.75s3,.85,3,2.75v4.43h10a1.83,1.83,0,1,1,0,3.66h-9.32c2,4.51,6,8.9,10.45,12.35C1009.71,373,1010.27,374.8,1008.9,376.45Z"
        />
        <circle class="cls-44" cx="934.39" cy="516.17" r="26.6" />
        <path
          class="cls-10"
          d="M949.63,527.11c-1.41,1.72-3.27,1.65-5-.18a30.89,30.89,0,0,1-6.51-11.36v11.15c0,4.61-4.68,6-10.38,3.31a1.49,1.49,0,0,1-.92-1.83c.18-.7.71-1.06,1.83-.92,2.89.36,3.7,0,3.7-1.9V511c-1.76,7.56-6.06,14.25-11.62,17.49a1.18,1.18,0,0,1-1.79-.32,1.34,1.34,0,0,1,.25-1.83c4.25-4.33,6.47-9.18,7.88-15.83.07-.29-.11-.46-.39-.46h-5.8a1.52,1.52,0,1,1,0-3H927a3.57,3.57,0,0,1,4-.7,2.49,2.49,0,0,1,1.34,1.19v-6.4c0-1.86,1-2.78,2.88-2.78s2.89.92,2.89,2.78v4a35.47,35.47,0,0,0,1.9,5.49,46.75,46.75,0,0,0,3.69-4.75c1.13-1.62,2.54-1.65,4.12-.42,1.41,1.09,1.48,2.67.07,4a21.36,21.36,0,0,1-6.37,4.22,28.58,28.58,0,0,0,7.11,8.45C950.58,523.73,951.07,525.35,949.63,527.11Z"
        />
        <circle class="cls-44" cx="723.19" cy="515.51" r="26.6" />
        <path
          class="cls-10"
          d="M707.74,529.79a1.3,1.3,0,0,1,.81-1.73c8.09-4.08,11.5-11,11.5-22v-5.52c0-1.94,1.06-2.75,3-2.75s3,.78,3,2.75v5.59c1,7.81,4.36,13.58,11.11,18.12,2,1.34,2.61,3.1,1.38,4.89s-3.17,2.15-5.07.57A25.88,25.88,0,0,1,724.87,512c-.56,9.14-6.12,15.9-15.37,18.54C708.62,530.74,708,530.53,707.74,529.79Zm5-16.82a65.18,65.18,0,0,0-3.13-7.74,1.37,1.37,0,0,1,.42-1.9,1.45,1.45,0,0,1,2,.1,31,31,0,0,1,5.88,6.86c1.23,2,.91,3.66-1,4.61S713.44,515.22,712.7,513Zm15.8.77a1.43,1.43,0,0,1,0-2,38.81,38.81,0,0,0,3.8-6.61c.88-1.94,2.4-2.47,4.19-1.52s1.83,2.78.53,4.44a25.24,25.24,0,0,1-6.44,5.91A1.51,1.51,0,0,1,728.5,513.74Z"
        />
        <circle class="cls-44" cx="651.92" cy="366.4" r="26.6" />
        <path
          class="cls-10"
          d="M667.86,378a1.55,1.55,0,0,1-1.66,1.75H637.88a1.76,1.76,0,0,1,0-3.51h10.84V363.43H639.5a1.59,1.59,0,0,1-1.73-1.76A1.53,1.53,0,0,1,639.5,360h9.22v-8.56c0-2,1.09-2.74,3.09-2.74s3.1.74,3.1,2.74V360h9.22a1.51,1.51,0,0,1,1.72,1.68,1.58,1.58,0,0,1-1.72,1.76h-9.22v12.78H666.2A1.55,1.55,0,0,1,667.86,378Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style scoped>
.cls-1 {
  isolation: isolate;
}

.cls-2 {
  fill: #f9e6e7;
}

.cls-3 {
  fill: #f4f1f4;
}

.cls-4 {
  mix-blend-mode: multiply;
}

.cls-25,
.cls-26,
.cls-27,
.cls-38,
.cls-43,
.cls-5 {
  fill: none;
}

.cls-20,
.cls-26,
.cls-27,
.cls-44,
.cls-5 {
  stroke: #ff5445;
}

.cls-24,
.cls-42,
.cls-5 {
  stroke-miterlimit: 10;
}

.cls-5 {
  stroke-width: 0.24px;
}

.cls-6,
.cls-7 {
  font-size: 65px;
  /* font-family: GenEi-M-Gothic-v2-Heavy, GenEi M Gothic v2; */
  font-weight: 800;
  letter-spacing: 0.04em;
}

.cls-10,
.cls-7,
.cls-8 {
  fill: #ff5445;
}

.cls-8 {
  font-size: 40px;
  /* font-family: GenEi-M-Gothic-v2-Bold, GenEi M Gothic v2; */
  font-weight: 700;
}

.cls-16,
.cls-30,
.cls-9 {
  font-size: 31px;
  /* font-family: GenEi-M-Gothic-v2-Medium, GenEi M Gothic v2; */
  font-weight: 500;
}

.cls-13,
.cls-15,
.cls-16,
.cls-30,
.cls-34,
.cls-36,
.cls-9 {
  fill: #231815;
}

.cls-9 {
  letter-spacing: 0.08em;
}

.cls-11,
.cls-12,
.cls-14,
.cls-19,
.cls-31,
.cls-32,
.cls-33,
.cls-40 {
  fill: #3e3a39;
}

.cls-11,
.cls-12,
.cls-14,
.cls-19,
.cls-28,
.cls-31,
.cls-32,
.cls-33 {
  /* font-family: NotoSansHant-Medium-B5pc-H, Noto Sans T Chinese; */
}

.cls-11,
.cls-12,
.cls-14,
.cls-19,
.cls-28,
.cls-29,
.cls-31,
.cls-32,
.cls-33 {
  font-weight: 400;
}

.cls-11 {
  letter-spacing: -0.19em;
}

.cls-12 {
  letter-spacing: -0.19em;
}

.cls-14 {
  letter-spacing: -0.17em;
}

.cls-15,
.cls-30 {
  letter-spacing: 0.06em;
}

.cls-16,
.cls-35 {
  letter-spacing: 0.08em;
}

.cls-17 {
  letter-spacing: 0.08em;
}

.cls-18,
.cls-32,
.cls-36 {
  letter-spacing: 0.06em;
}

.cls-19 {
  letter-spacing: -0.19em;
}

.cls-20,
.cls-22,
.cls-44 {
  fill: #fff;
}

.cls-20,
.cls-23,
.cls-25,
.cls-26,
.cls-27,
.cls-38,
.cls-41,
.cls-43,
.cls-44 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-20 {
  stroke-width: 5.05px;
}

.cls-21 {
  fill: #f7e2db;
}

.cls-23,
.cls-41 {
  fill: #ffc1b3;
}

.cls-23,
.cls-24,
.cls-25,
.cls-38,
.cls-41,
.cls-42,
.cls-43 {
  stroke: #000;
}

.cls-23 {
  stroke-width: 4.26px;
}

.cls-24 {
  stroke-width: 1.46px;
}

.cls-25 {
  stroke-width: 5.11px;
}

.cls-26 {
  stroke-width: 2.5px;
  stroke-dasharray: 8;
}

.cls-27 {
  stroke-width: 2px;
}

.cls-29 {
  /* font-family: jf-jinxuan-SemiBold-B5pc-H, jf-jinxuan; */
}

.cls-33 {
  letter-spacing: -0.02em;
}

.cls-34 {
  letter-spacing: 0.06em;
}

.cls-37 {
  fill: #604c3f;
}

.cls-38 {
  stroke-width: 4.97px;
}

.cls-39 {
  fill: #e06748;
}

.cls-41 {
  stroke-width: 3.86px;
}

.cls-42 {
  stroke-width: 1.41px;
}

.cls-43 {
  stroke-width: 4.93px;
}

.cls-44 {
  stroke-width: 3px;
}
</style>
