<template>
  <div class="comment-card">
    <p>
      {{ content }}
    </p>
    <div class="comment-user-wrapper">
      <div class="comment-user-img">
        <img :src="iconLink" />
      </div>
      <p>{{ name }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '#',
    },
    content: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  computed: {
    iconLink: function () {
      return '/image/comment-icon/' + this.icon + '.png'
    },
  },
}
</script>

<style scoped>
img {
  display: block;
  width: 100%;
}
.comment-card {
  padding: 8% 5% 3%;
  background-color: #f4f1f4;
  width: 60%;
  margin: 30px auto;
  border-radius: 10px;
  text-align: justify;
  font-weight: 500;
}
.comment-card .comment-user-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-top: 2%;
  justify-content: flex-end;
  align-items: center;
  color: #ff5445;
  padding-right: 3%;
}
.comment-card .comment-user-img {
  width: 14%;
  padding-right: 3%;
}
</style>
