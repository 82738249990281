<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 350 1110 1050">
    <g id="圖層_26" data-name="圖層 26">
      <path
        class="cls-1"
        d="M484.57,518.09c-5.29-20.85-7.51-42.92-2.33-63.79s18.57-40.43,38.2-49.64c14-6.59,30.25-7.54,45.64-5.39,13.35,1.87,26.6,6.11,37.34,14.16,15.73,11.79,24.53,30.41,31,48.83,5,14.19,9,29,8.87,44-.13,12.39-3.1,24.57-6,36.62S631.35,567,628.16,579c-1.71,6.44-3.54,13-7,18.64-7.3,11.79-20.66,18.26-33.32,24.12-6.55,3-13.25,6.11-20.44,6.88-9.2,1-20.16,2.7-28.5-1.27-7-3.32-16.25-10.66-22.1-15.67-13.29-11.38-19.44-24.87-24.92-41.39s-6.06-37.53-7.59-54.85"
      />
      <path
        d="M497.11,425.37c-10.68,2-17.88,12.26-21.54,22.5a72.63,72.63,0,0,0,5.18,59.46c4.39-17.57,14.43-21.4,10.68-56-.72-6.61,3.95-11.44,7.77-16.88,1.84-2.62,6-3.27,8.23-5.52a68.27,68.27,0,0,1,11-9.48c7.87-5.05,20.75-8.19,30.09-8.58,29-1.19,50.2-.19,71,20.1,4.61,4.5,12.2,21.93,15.27,27.59,5.67,10.47,9,21.24,8.5,33.13-.21,5.1-2.93,19.37,1.41,22.06,5.13,3.19,14.2-9.46,18.13-14a70.64,70.64,0,0,0,14-26.95c2.72-9.76,3.25-19.9-1.33-28.94-2.93-5.78-7.66-11.69-6-17.94.71-2.64,7.27-5.88,6.78-8.58-.46-2.5-7.32-4-9.8-4.54-11.37-2.69-17.53-4-31.46-16.09-12.81-11.08,1.65-12.42-2.95-14.46-4.82-2.15-17.47-2.6-26.79-4.83-4.24-1-10.85-5-14.74-7-9.66-4.9-23.46-7.76-34.29-8s-29.36,9.59-35.9,18.22a8.75,8.75,0,0,1-2,2.2,9.06,9.06,0,0,1-4.07,1c-7.58.84-7.24,5-11.58,11.32s-2.16,9.64-4.55,16.87c-1.09-2.82-3.91-6.25-6.37-4.49a25.74,25.74,0,0,0-3.86,5.64,29.2,29.2,0,0,0,.39,19.07"
      />
      <path
        class="cls-1"
        d="M644.92,517.4Q639,545,632.38,572.45a45,45,0,0,0,23.1-48.39c-.84-4.3-3.13-9.2-7.49-9.73"
      />
      <path
        class="cls-1"
        d="M481.4,515.21c-.8-3.41-5.74-4.68-8.94-3s-4.84,5.15-5.63,8.54c-2.08,8.87-1.23,20,3.17,28.16s14.58,16.37,21.16,23c-4.83-17.55-8.21-38.5-10.18-56.53"
      />
      <path
        class="cls-2"
        d="M242.73,763.61a49.07,49.07,0,0,1-2.15-12.37,383.5,383.5,0,0,0,57.35-6.13c9-1.65,17.95-3.63,27.07-4.08,1.29-.07,2.75,0,3.65.9a4.46,4.46,0,0,1,.94,2.72,61.07,61.07,0,0,1,.22,10.56c-30.48,4-55.55,4-85.13,8.82"
      />
      <path
        class="cls-3"
        d="M513.43,643.43c-21.21,24.52-88,37.32-116,56.7-33.76,23.31-46.32,97.71-46.32,97.71s.6-38.33-10.75-39.43c-32.95-3.22-103.68,8-103.68,8s-2.41,94.85,37.78,190.5c8.76,20.84,22.5,48.22,45,67.51,17,14.54,50.2,37.91,82.78,8,9.65-8.84-.2,49,10.45,46.62,74.54-16.68,231.19,11,315.08-2.11,8.15-1.28-6.14-15.65,3.21-34.06,20.66-40.68,63.57-107.12,60.14-138.41-6-55.1-10.57-118.64-33.79-169-5.77-12.51-19-27.94-29.82-36.45-16-12.58-43.53-23.17-63.62-26.51s-37.11-11.26-43.74-19.22c-2.76-3.31-5.91-19.16-9.28-21.86-6.68-5.37-22.83,11.38-31.06,13.76-21.22,6.15-42.85-11.74-64.69-15.09"
      />
      <path
        class="cls-4"
        d="M500.83,654.08c-21.3,22.35-34.06,51.76-40.77,81.89s-13.1,70.1-13.56,101c-.88,59,1.53,106.76,4.64,165.67,1.23,23.36,6.83,49.07,9.67,72.29.35,2.81.83,5.86,2.89,7.8,1.71,1.6,4.14,2.1,6.44,2.53,10.63,2,38.79,20.82,49.42,22.81-15.27-48.22-22.92-125.76-25.8-180.25A1344.08,1344.08,0,0,1,508,649.67"
      />
      <path
        class="cls-4"
        d="M625.74,654.08C647,676.43,659.8,705.84,666.51,736s13.1,70.1,13.56,101c.88,59-1.53,106.76-4.63,165.67-1.24,23.36-6.84,49.07-9.68,72.29-.34,2.81-.82,5.86-2.89,7.8-1.7,1.6-4.14,2.1-6.44,2.53-10.63,2-32.19,17.6-42.83,19.6,4-40.19,16.33-122.55,19.21-177a1343.39,1343.39,0,0,0-14.2-278.12"
      />
      <path
        class="cls-2"
        d="M516.42,629.42l-10.6,56.84c-3.77,20.23-7.54,40.5-9.48,61-2.19,23.22-2,46.59-1.81,69.9.46,57.85-4.46,116,1.4,173.53,4.25,41.75,12.33,79.66,21.22,116.56,36.76,3.74,60.78,5.86,97.26,0,2.49-.4,30.89-219.27,14.33-372.36-2.82-26-12.59-76.2-16.57-108-31.11,16-58.7,14.34-92.4,5"
      />
      <path
        class="cls-1"
        d="M513.45,601c0,14.47.07,29.49,6.71,42.57,5.91,11.65,14.16,15.74,24.42,24.36,4.48,3.78,15.57,12.26,17.77,12.92,3.66,1.08,6.94-4.14,12-8.94,9-8.48,24.09-18,30-28.63s8.87-23,6.08-34.66c-.46-1.9-1.16-3.91-2.82-5.1s-3.67-1.28-5.64-1.41L519.39,597"
      />
      <path
        class="cls-5"
        d="M531.71,583.74c4.85,1.34,9.08,3,14.16,3.61,4.37.53,8.76.87,13.15,1.13,8.89.53,20.09,2.15,28.57-.92,6.4-2.31,8.42-13.89,0-15.09-9.19-1.31-19.23.54-28.57.65-4.38.06-8.77.25-13.15.25-4.87,0-9.29-.79-14.16,0-5.42.92-4.78,9,0,10.34Z"
      />
      <path
        class="cls-6"
        d="M595.4,575.41c-24.57,1.63-68.14.35-68.14.35a31.15,31.15,0,0,1,27.27-6.21,22.5,22.5,0,0,0,5.52,1.09,20.83,20.83,0,0,0,5-1,38.48,38.48,0,0,1,31.3,5.65"
      />
      <path
        class="cls-6"
        d="M596.32,575.47a49.86,49.86,0,0,1-69.15.13s-.15,0,0,.57c2.58,9.55,12,18.26,21.34,21.52a42.8,42.8,0,0,0,13,2.24c7.42.2,15-1.24,21.38-5.07S595.2,583,596.43,575.67Z"
      />
      <path
        d="M527.42,573.92a1.55,1.55,0,0,1,0-.23c0-.27,0,.27,0,0s0-.29,0-.44,0-.29,0-.43,0,.23,0,0c0-.07,0-.15,0-.23a5.13,5.13,0,0,1,.26-.87c.05-.14-.1.21,0,0l.1-.21.24-.42.13-.2s.16-.22.06-.07l.08-.1.13-.15.34-.34.18-.15c.18-.15-.19.12,0,0a9.69,9.69,0,0,1,1.57-.88,1,1,0,0,0-.5-1.83,3.71,3.71,0,0,0-1.4.27,5.25,5.25,0,0,0-.89.51,6.81,6.81,0,0,0-1.71,1.8,6.14,6.14,0,0,0-.87,4.61,1.2,1.2,0,0,0,1.45.82,1.21,1.21,0,0,0,.82-1.45Z"
      />
      <path
        d="M592.53,569.22a6.38,6.38,0,0,1,2.44,2.55c.08.13.14.27.21.41a2,2,0,0,0,.1.21c.08.17-.06-.16,0,0a8,8,0,0,1,.31,1l.09.44c0,.07,0,.32,0,.06,0,.07,0,.15,0,.22,0,.34,0,.69,0,1a4,4,0,0,0,0,1.14,1.29,1.29,0,0,0,1.56,1c1.06-.31,1.11-1.63,1-2.55a9.34,9.34,0,0,0-1.31-4.07,8.86,8.86,0,0,0-3.42-3.29,1.1,1.1,0,1,0-1.12,1.9Z"
      />
      <path
        class="cls-1"
        d="M265.55,745.2c-12.73-11.37-28.05-22.18-33.63-38.3-7.14-20.63-8.11-63.18,4.92-90,1.69-3.48,9.75-10.52,13.46-9.44,4.64-7.33,8.51-13.21,15.48-8,1.28,1,3.76,7.39,5.22,8s4.78-2.4,6.23-2c2.67.72,5.32,5.38,6.89,7.67.68,1,3.88,1.49,4.76,2.38,5.05,5,4.73,17.79,6.43,24.71,2.1,8.52-.68,20,1.41,28.54s6,17.84,9.24,26.12c5.6,14.32,19.75,22.65,18.37,38-.07.78.27,4.68-.28,5.23a3.56,3.56,0,0,1-1.78.79c-18.35,4.35-37.52,4.14-56.34,5.45"
      />
      <path
        d="M543.57,488l-4.72-6a7.12,7.12,0,0,0-7.15-3.49q-8.16-.12-16.33.27a16.37,16.37,0,0,0-6.45,1.21c-3.61,1.76-5.44,5.76-7,9.46,2.15-2.9,5.94-4,9.5-4.63a59.44,59.44,0,0,1,30.78,2.93"
      />
      <path
        d="M544.66,486.87c-1.58-2-3.14-4-4.72-6-1.35-1.7-2.83-3.17-5-3.65a31.63,31.63,0,0,0-6.67-.34q-4,0-7.93.09c-4.36.11-9.69-.33-13.45,2.32-3.29,2.32-4.94,6.12-6.43,9.72a1.53,1.53,0,0,0,.89,1.82,1.58,1.58,0,0,0,1.92-.63c1.74-2.27,4.79-3.18,7.48-3.71a52.39,52.39,0,0,1,10.39-.88,57.06,57.06,0,0,1,10.35,1,80.19,80.19,0,0,1,10.24,2.89c2.29.75,3.08-2.66,1-3.53-7-2.9-15.17-3.89-22.7-3.65a55.52,55.52,0,0,0-10.83,1.35c-3.15.74-6.28,2.07-8.32,4.68l2.41,1.86c1.3-3,2.72-6.45,5.57-8.29a11.58,11.58,0,0,1,5.67-1.5c2.19-.14,4.39-.21,6.58-.27s4.45-.11,6.68-.11a31.31,31.31,0,0,1,6.35.18c2,.4,3.08,2.11,4.26,3.6l4.14,5.24c1.22,1.55,3.37-.64,2.17-2.17Z"
      />
      <path
        d="M581.42,488l4.73-6a7.11,7.11,0,0,1,7.14-3.49q8.16-.12,16.34.27a16.36,16.36,0,0,1,6.44,1.21c3.61,1.76,5.45,5.76,7,9.46-2.15-2.9-5.94-4-9.5-4.63a59.44,59.44,0,0,0-30.78,2.93"
      />
      <path
        d="M582.51,489l4.14-5.24c1.17-1.49,2.28-3.2,4.25-3.6a31.31,31.31,0,0,1,6.35-.18q3.35,0,6.68.11c2.2.06,4.39.13,6.58.27a11.52,11.52,0,0,1,5.67,1.5c2.85,1.84,4.28,5.31,5.57,8.29l2.42-1.86c-2-2.56-5-3.9-8.14-4.64a55.21,55.21,0,0,0-11-1.39c-7.53-.24-15.68.75-22.69,3.65-2.11.87-1.32,4.28,1,3.53a81,81,0,0,1,10-2.85,57.83,57.83,0,0,1,10.58-1,52.92,52.92,0,0,1,10.21.85c2.74.51,5.88,1.42,7.66,3.74a1.58,1.58,0,0,0,1.92.63,1.52,1.52,0,0,0,.89-1.82c-1.49-3.6-3.14-7.4-6.43-9.72-3.76-2.65-9.09-2.21-13.45-2.32q-4-.1-7.93-.09a31.63,31.63,0,0,0-6.67.34c-2.2.48-3.68,2-5,3.65-1.58,2-3.14,4-4.71,6-1.21,1.53.94,3.72,2.17,2.17Z"
      />
      <path
        class="cls-7"
        d="M527.18,483.21a24.7,24.7,0,1,0,24.7,24.69A24.69,24.69,0,0,0,527.18,483.21Zm0,44.57a19.88,19.88,0,1,1,19.89-19.88A19.89,19.89,0,0,1,527.18,527.78Z"
      />
      <path
        class="cls-7"
        d="M600.25,483.21a24.7,24.7,0,1,0,24.69,24.69A24.69,24.69,0,0,0,600.25,483.21Zm0,44.57a19.88,19.88,0,1,1,19.88-19.88A19.88,19.88,0,0,1,600.25,527.78Z"
      />
      <path
        d="M589.06,527.83c-7.36-1.06-12.9-7.55-15.52-14.5-1.34-3.57-2.38-7.71-5.67-9.65s-7.59-.77-10.44,1.7-4.54,6-6.13,9.38-3.25,6.94-6,9.45l3.53-26.46a7.28,7.28,0,0,0,5.3.52c1.78-.4,3.45-1.16,5.19-1.67a19.09,19.09,0,0,1,10.36-.07c3.31.91,6.81,2.73,10,1.43l-1,16.16Z"
      />
      <path
        d="M510.8,524.79a36.89,36.89,0,0,1-7.12-7.95c-1.56-2.43-2.74-5.14-4.66-7.3s-4.89-3.73-7.68-3c-3.6,1-5.09,5.14-7.15,8.27a3.91,3.91,0,0,1-1.62,1.58,1.56,1.56,0,0,1-2-.56,2,2,0,0,1-.11-1.22c.43-3.58,2.48-6.73,4.47-9.73,1.77-2.66,3.88-5.56,7-6.07,2.35-.38,4.83.68,7.09-.07a9.2,9.2,0,0,0,3.28-2.34l7.09-6.81s-7.33,7.33-3.11,20.5Z"
      />
      <path
        d="M616.1,524.79a36.67,36.67,0,0,0,7.13-7.95c1.55-2.43,2.73-5.14,4.65-7.3s4.9-3.73,7.68-3c3.61,1,5.1,5.14,7.15,8.27a4,4,0,0,0,1.62,1.58,1.57,1.57,0,0,0,2-.56,2.19,2.19,0,0,0,.11-1.22c-.44-3.58-2.49-6.73-4.48-9.73-1.76-2.66-3.88-5.56-7-6.07-2.34-.38-4.83.68-7.08-.07a9.24,9.24,0,0,1-3.29-2.34l-7.09-6.81s7.34,7.33,3.11,20.5Z"
      />
      <circle cx="530.17" cy="506.91" r="6.96" />
      <circle cx="598.26" cy="506.91" r="6.96" />
      <path
        class="cls-8"
        d="M502.48,427.86c-6.76-3.1-12.58-.57-18,4.53C474,442.28,470.14,457.7,471.1,472.1s6.12,28.14,11.22,41.64A66.72,66.72,0,0,1,486.87,491c1.22-3.11,2.67-6.13,3.63-9.33,1.77-5.95,1.76-12.27,1.76-18.48s0-12.53,1.9-18.45,4.52-11.14,10.73-11.64c0,0,10.55-20.89,49-22.91,17-.89,38.53,2.63,52.78,12,22.13,14.54,28.16,32.41,30,38.94,6.46,23.36,5.63,30.86,7.37,53.1,17.08-7.18,29.95-27.1,32.94-45.39,2.32-14.17,1.09-30.45-9.39-40.27a30.06,30.06,0,0,0,7.73-8.36,3.82,3.82,0,0,0,.79-3.06c-.5-1.55-2.5-1.89-4.12-2a59,59,0,0,1-42.62-24.57c1.38-2.47,5-4.22,4.22-6.93-.68-2.24-3.75-2.37-6.1-2.28-18.67.75-34.62-13-52.72-17.63a56.07,56.07,0,0,0-45.22,8.14c-4.27,3-8.27,6.77-9.83,11.72a20.93,20.93,0,0,0-19.94,17,37.09,37.09,0,0,0,.17,12c-.53-.61-1.08-1.2-1.6-1.82-1.32-1.55-2.79-3.24-4.81-3.54a5.32,5.32,0,0,0-4.74,2.11,11.23,11.23,0,0,0-2.08,5c-.83,4-.15,10.58,3.37,13.09"
      />
      <path
        class="cls-8"
        d="M481.58,517.53c-1.21-4-5.32-7.29-9.35-6.31-4.19,1-6.18,5.79-6.94,10-3.4,19.1,6.85,39.83,24.08,48.73"
      />
      <path
        class="cls-8"
        d="M642,520.49c1.21-4,4.57-6.94,8.6-6,4.19,1,6.19,5.79,6.94,10,3.4,19.09-6.85,39.82-24.08,48.72"
      />
      <path
        d="M543,535.58a8.25,8.25,0,0,0-6.17,5.45,7.75,7.75,0,0,0,2.2,7.82,8.4,8.4,0,0,0,8.11,2c1.51-.42,2.9-1.21,4.43-1.55,1.75-.39,2.65.6,3.91,1.64a10.26,10.26,0,0,0,7.87,2.33,10.76,10.76,0,0,0,4-1.3,17.9,17.9,0,0,0,3.2-2.88c1.12-1,2.45-.27,3.64.3a12.55,12.55,0,0,0,4.6,1.48,9.19,9.19,0,0,0,7.27-3.11,8.11,8.11,0,0,0,1.31-8.88,9.24,9.24,0,0,0-7.73-5.23c-1.89-.1-1.89,2.84,0,3,4.62.24,6.91,6.4,3.3,9.41a5.57,5.57,0,0,1-5.63,1c-2.33-.92-4.61-2.59-7.23-1.69-2.24.78-3.18,3.24-5.44,4.09a7.14,7.14,0,0,1-6-.4c-1.06-.61-1.86-1.56-2.87-2.25a6,6,0,0,0-3.63-1c-2.6.13-4.77,1.93-7.33,2.07a5.08,5.08,0,0,1-5.09-4.09,5.54,5.54,0,0,1,3.82-6,1.14,1.14,0,0,0-.6-2.19Z"
      />
      <path
        class="cls-8"
        d="M642.81,509.24c-.95,20.65-5.8,47.16-11.87,67-1.8,5.9-3.74,11.81-6.83,17.15-6.06,10.5-16.08,18-25.89,25.14-10.34,7.5-21.78,15.21-34.21,16.38l-3.41,0c-12.59-1-24.16-8.83-34.61-16.41-9.81-7.13-19.83-14.64-25.9-25.14-3.09-5.34-5-11.25-6.83-17.15a270.38,270.38,0,0,1-11.43-62.45"
      />
      <path
        class="cls-8"
        d="M514.26,611.74c.55,13.28.79,27.68-7,38.48-8.12,11.31-22.7,15.52-36.14,19.14-18.61,5-37.29,10.31-54.48,19s-33,21.14-42.79,37.72c-6.67,11.24-17.4,55.83-20.71,68.48"
      />
      <path
        class="cls-8"
        d="M509.57,646.54a1305.58,1305.58,0,0,0-3.63,410.34"
      />
      <path
        class="cls-8"
        d="M490.73,662.32c-7.73,7.43-11.63,15.82-16,25.63-16.65,37.71-24.1,78.88-27.74,119.94-7.64,86.12.91,172.77,9.45,258.81"
      />
      <path
        class="cls-8"
        d="M371.48,733.58c-2.31,58.44,3.06,135,18.69,186.19"
      />
      <path
        class="cls-8"
        d="M388.18,816.4c-2.65,74.88,7.95,190.85,23.86,261.09"
      />
      <path
        class="cls-8"
        d="M515.18,628.45a63,63,0,0,0,22.92,35.33c7.16,5.45,18.38,10.55,24.37,17.26"
      />
      <path
        class="cls-8"
        d="M510,651.09c3.35,12.91,14.71,21.88,25.26,30s24.43,18.26,26.88,31.38"
      />
      <path
        class="cls-8"
        d="M612.17,609.31c-.55,13.28-1.21,30.11,6.54,40.91,8.12,11.31,22.69,15.52,36.14,19.14,18.61,5,37.29,10.31,54.48,19s33,21.14,42.79,37.72c6.67,11.24,10.37,23.95,13.69,36.6,23.5,89.75,23.73,172.28,27.84,265"
      />
      <path
        class="cls-8"
        d="M616.44,646.54a1305.58,1305.58,0,0,1,3.63,410.34"
      />
      <path
        class="cls-8"
        d="M635.84,663.56c7.73,7.43,11.07,14.58,15.4,24.39,16.65,37.71,24.1,78.88,27.74,119.94,7.64,86.12-.91,172.77-9.45,258.81"
      />
      <path
        class="cls-8"
        d="M754.53,733.58c2.22,60.24-2.2,125.08-15.79,173.6"
      />
      <path
        class="cls-8"
        d="M738.08,801.15c5.25,71.21-6.68,205.05-10.6,276.34"
      />
      <path
        class="cls-8"
        d="M611.34,626.87c-3.28,13.94-12.7,28.23-24.09,36.91-7.16,5.45-18.62,10.39-24.62,17.1"
      />
      <path
        class="cls-8"
        d="M616,651.09c-3.35,12.91-14.71,21.88-25.26,30s-24.51,17.77-27,30.88"
      />
      <line class="cls-8" x1="563.13" y1="713.35" x2="563.13" y2="1052.64" />
      <path
        class="cls-8"
        d="M375.69,919.16C363.2,867.83,345.86,757,344.34,757.07c-29.74.8-108.5,9.11-108.5,9.11-1.3,61.33,15.32,128.61,36.84,186.06,21,56.14,59.33,94.78,96.58,93.11,8.51-.38,20.09.8,32.15-11.25"
      />
      <path
        class="cls-8"
        d="M243.05,763.5c-.69-4.91-.1-7.15-.78-12.06,41.79-7.5,86.37-12.55,87.23-12s3.18,10.83,3.59,16.59"
      />
      <path
        class="cls-8"
        d="M280.58,673.21c-1.61-8.84-2.68-59.21,5.87-59.91,2.75-.23,4.36,3.06,5.16,5.71,7.2,24,1.14,51.47,13.11,73.46,3.19,5.85,7.53,11,10.86,16.77,4.14,7.17,9.31,21.36,10,29.61"
      />
      <path
        class="cls-8"
        d="M263.49,670.25c-1-17.68-1.94-35.67,2.28-52.87.87-3.54,2.05-7.2,4.66-9.75s7.05-3.57,9.95-1.35c2.36,1.81,3,5,3.47,8"
      />
      <path
        class="cls-8"
        d="M244.18,672.4A157.55,157.55,0,0,1,249.59,612c1.08-3.73,2.36-7.52,4.87-10.49s6.53-5,10.29-4.06c5.45,1.4,4,6.82,4.24,10.84"
      />
      <path
        class="cls-8"
        d="M250.09,608.46c-3.33-1-7,.59-9.37,3.11s-3.71,5.83-4.85,9.1c-6.09,17.49-8.33,36.14-8.57,54.65a87.55,87.55,0,0,0,.72,14.21c1.82,12,7.8,23.05,15.37,32.52s15.76,16,24.86,24"
      />
      <path
        class="cls-2"
        d="M883.67,766.83a48.65,48.65,0,0,0,2.16-12.38,381.89,381.89,0,0,1-57.35-6.12c-9-1.66-18-3.64-27.08-4.09-1.29-.06-2.75,0-3.64.9a4.49,4.49,0,0,0-.94,2.73,61.05,61.05,0,0,0-.23,10.56c30.48,4,55.56,4,85.14,8.82"
      />
      <path
        class="cls-3"
        d="M773.55,809s1.18-46.31,12.53-47.42c33-3.21,103.69,8,103.69,8S892.18,864.5,852,960.15c-8.76,20.85-26.19,56.26-48.7,75.55-17,14.54-40.19,22.51-71.53,3.22"
      />
      <path
        class="cls-1"
        d="M860.86,748.41c12.73-11.36,28-22.17,33.62-38.3,7.14-20.63,8.12-63.17-4.91-90-1.7-3.48-9.75-10.53-13.47-9.45-4.64-7.32-8.51-13.2-15.47-8-1.28,1-3.76,7.38-5.22,8s-4.78-2.4-6.23-2c-2.68.72-5.32,5.38-6.89,7.66-.68,1-3.88,1.5-4.77,2.38-5,5-4.72,17.79-6.43,24.72-2.09,8.51.69,20-1.4,28.53s-6,17.85-9.25,26.12c-5.59,14.33-19.74,22.66-18.37,38,.07.79-.27,4.68.29,5.24a3.7,3.7,0,0,0,1.78.79c18.35,4.34,37.52,4.13,56.33,5.44"
      />
      <path
        class="cls-8"
        d="M750.72,922.37c12.49-51.32,29.83-162.13,31.34-162.09,29.74.81,108.51,9.11,108.51,9.11,1.3,61.34-15.32,128.61-36.84,186.06-21,56.14-55,97.2-92.23,95.53-8.51-.39-18.49-.81-30.55-12.87"
      />
      <path
        class="cls-8"
        d="M883.36,766.71c.69-4.91.09-7.14.78-12.05-41.79-7.51-86.38-12.55-87.23-12s-3.18,10.82-3.59,16.59"
      />
      <path
        class="cls-8"
        d="M845.83,676.42c1.61-8.84,2.68-59.21-5.88-59.91-2.74-.22-4.36,3.07-5.15,5.71-7.2,24-1.14,51.48-13.12,73.46-3.18,5.85-7.52,11-10.85,16.78-4.14,7.16-9.31,21.36-10,29.6"
      />
      <path
        class="cls-8"
        d="M862.92,673.47c1-17.68,1.93-35.67-2.28-52.87-.87-3.55-2-7.2-4.66-9.75s-7.05-3.57-10-1.36c-2.36,1.81-3,5-3.48,8"
      />
      <path
        class="cls-8"
        d="M882.22,675.62a157.6,157.6,0,0,0-5.4-60.4c-1.09-3.72-2.37-7.51-4.87-10.48s-6.53-5-10.29-4.06c-5.45,1.39-4,6.82-4.24,10.84"
      />
      <path
        class="cls-8"
        d="M876.32,611.67c3.32-1,7,.59,9.37,3.11s3.7,5.84,4.84,9.11c6.1,17.49,8.34,36.13,8.58,54.65a87.61,87.61,0,0,1-.72,14.21c-1.82,12-7.81,23-15.38,32.52s-15.76,16-24.86,24"
      />
    </g>
    <g id="圖層_6" data-name="圖層 6">
      <path
        class="cls-9"
        d="M-22.57,1227.27H-712.49v.18l-1202.88,2.87s-1.36,92.68,0,118.84c150.28-36.36,218.4-27.58,285.1-15.33,95.2,17.47,187.21,49.1,281.68,70.16s194,31.27,287.38,5.73c90.5-24.77,246.3-80.85,350.05-95.48v-.2c33.18-6,66.46-11.3,99.9-15.65,197.13-25.66,445.48-5.15,587.8,32.33,315.65,83.13,474.64,35.3,715.52,8.66s484.59-69.76,723.84-31.14c160.43,25.9,327.76,88,483.53,41.73V1230.32Z"
      />
      <path
        class="cls-9"
        d="M1898.82,1261.36s1.36-246.18,0-272.34c-150.28,36.36-218.4,27.57-285.1,15.33-95.19-17.48-187.21-49.11-281.67-70.16s-194-31.28-287.39-5.73c-90.49,24.76-246.3,80.85-350.05,95.48v.19c-33.17,6-66.45,11.3-99.9,15.65-197.13,25.67-445.48,5.15-587.8-32.33-315.65-83.13-474.64-35.29-715.52-8.66s-484.58,69.76-723.83,31.14c-160.43-25.89-327.76-88-483.53-41.73v273.16Z"
      />
    </g>
    <g id="圖層_33" data-name="圖層 33">
      <a href="#" v-scroll-to="'#class-01'">
        <rect
          class="cls-10"
          x="254.41"
          y="1112.93"
          width="571.62"
          height="109.65"
          rx="54.82"
        />
        <rect
          class="cls-11"
          x="230.62"
          y="1098.66"
          width="619.19"
          height="139.39"
          rx="69.7"
        />
        <path
          class="cls-5"
          d="M330.47,1154.59v-5.51h37.36v5.51a96.48,96.48,0,0,1-15.05,13.13v14q0,5.06-.78,6a6.84,6.84,0,0,1-.88.9,3.89,3.89,0,0,1-1.81.6,25.59,25.59,0,0,1-3.53.2c-1.46,0-4.17-.07-8.15-.23l-.32-5.5q5.6.23,7,.23c.95,0,1.52-.11,1.72-.33a3.4,3.4,0,0,0,.3-1.88v-20.19h4.91a93.94,93.94,0,0,0,8.68-7Z"
        />
        <path
          class="cls-5"
          d="M375.68,1186.49a30.56,30.56,0,0,0,1.76-7.64,134.8,134.8,0,0,0,.49-13.7v-2.67l-2.25-1.51a44,44,0,0,0,5.83-14.73l5.82.55c-.06.36-.22,1.16-.5,2.38h7.57V1154c-.3.92-.73,2-1.28,3.31h3.81v4.45a12.07,12.07,0,0,0,6.29-8h-5.56v-5.14h21.67c-.19,5.3-.39,8.65-.62,10.06a21.62,21.62,0,0,1-.65,3,3.25,3.25,0,0,1-1,1.49,2.78,2.78,0,0,1-1.38.71,15.59,15.59,0,0,1-2.34.12q-1.65,0-6.11-.23l-.41-4.08a16.2,16.2,0,0,1-4.82,5.37l3,.55c-.31,1.1-.55,1.92-.74,2.47h3v-3h5.88v3h5.87v5h-5.87v4.59h6.61v5.14h-6.61v7.39h-6.11v-7.39h-9.18v-5.14h9.18V1173h-4.59a40.11,40.11,0,0,1-1.93,4.13l-3.62-1.42v6.79a31,31,0,0,1-.3,5.26,2.8,2.8,0,0,1-1.06,2,4.74,4.74,0,0,1-2.36.46q-1.6,0-6.43-.23l-.27-5c2.35.15,3.78.23,4.27.23s.78-.1.89-.3a5.92,5.92,0,0,0,.16-1.9v-3h-9a44.61,44.61,0,0,1-2.29,10.47Zm7.48-11.2h2.29v-4.36H383.3C383.3,1171.08,383.25,1172.54,383.16,1175.29Zm.14-8.86h2.15v-4.17H383.3Zm.68-9.13h3.26c.68-1.35,1.18-2.45,1.52-3.31h-3.44C384.7,1155.62,384.26,1156.72,384,1157.3Zm5.7,9.13h2.15v-4.17h-2.15Zm0,8.86h2.15v-4.36h-2.15Zm7.25-2.89a48.85,48.85,0,0,0,2.29-6l-2.29-3.53Zm10.32-13.54c1.57.12,2.74.18,3.52.18a2.64,2.64,0,0,0,1.49-.27c.46-.34.73-2,.82-5h-4.26A19.16,19.16,0,0,1,407.25,1158.86Z"
        />
        <path
          class="cls-5"
          d="M424.88,1185.43a50.92,50.92,0,0,0,5.83-4.26v-10.7h-5.1v-5.14h11.06v14.6a7.12,7.12,0,0,0,2.78,2.82,15.3,15.3,0,0,0,5.3,1.28q3.63.39,10.83.39h12.63l-.28,5.19H455.49a74.06,74.06,0,0,1-14-1,13.26,13.26,0,0,1-7.11-3.42,54.44,54.44,0,0,1-6.66,5.15Zm1.05-33.13,4.13-3.68q5.52,5.66,8.08,8.59l-4.08,3.67C431.88,1158.4,429.18,1155.54,425.93,1152.3Zm9.6,11a58.66,58.66,0,0,0,7.52-16.16l5.6.73c-.24,1.2-.62,2.77-1.14,4.73h6.1c.58-1.77,1.09-3.59,1.51-5.46l5.65.6q-.74,3-1.33,4.86h7.44v5h-8.45v3.22H466v4.4h-7.53v3.35H466V1173h-7.53v3.4h9.18v4.91h-27.4v-15.1Zm10.78-2.48h6.34v-3.22h-6.34Zm0,7.75h6.34v-3.35h-6.34Zm0,7.81h6.34V1173h-6.34Z"
        />
        <path class="cls-5" d="M474.49,1170.93v-6.24h42.6v6.24Z" />
        <path
          class="cls-5"
          d="M527.73,1178.92a31.7,31.7,0,0,0-4.82-3.77l-.36-.23.41-.23a26.84,26.84,0,0,0,5.23-3.9,23.49,23.49,0,0,0,3.95-4.73l.09-.18,6.2,2-.19.33a35.06,35.06,0,0,1-4.54,5.71,45.9,45.9,0,0,1-5.65,5l-.18.13Zm9.64,1,0-.23a15.92,15.92,0,0,0-1.7-4.82l-.19-.41h.46c1,0,2,0,3,0s1.7,0,2,0a4.1,4.1,0,0,0,.53,0s.07-.1.07-.26v-8.39H523.42v-5.79h6.29v-10h6.15v10h5.64v-12.94h6.2v4.36h13v5.64h-13v2.94h17.76v5.79H547.61v8.49a6.9,6.9,0,0,1-.55,3.07,3.81,3.81,0,0,1-2,1.79,8.08,8.08,0,0,1-2.13.53q-1.26.16-2.25.21c-.66,0-1.69,0-3.1,0Zm-7.8,10.6a15.75,15.75,0,0,0-2.76-5.14l-.32-.36.51,0q12.39-1.38,18.86-5.42a22.49,22.49,0,0,0,9.32-12.16l.09-.27,6.15,2-.09.28a32.11,32.11,0,0,1-4.68,8.67,25.74,25.74,0,0,1-6.7,6.15,37.69,37.69,0,0,1-8.86,4,71.15,71.15,0,0,1-11.25,2.48l-.23,0Z"
        />
        <path
          class="cls-5"
          d="M571.79,1160.74v-5H588v5Zm1.24,29.7v-14.32h14.42v7.43a31.53,31.53,0,0,0,8.76-8.35H588.5v-5.1h10.33v-2.34h-9.27v-19.37h24.6v19.37h-9.41v2.34h10.74v5.1h-8.26a31.52,31.52,0,0,0,9,8.58l-2.89,5.1a32,32,0,0,1-8.58-9.28v10.56h-5.92v-10.6a32.59,32.59,0,0,1-8.63,9.32l-2.75-4.13v4h-9.18v1.7Zm0-36.72v-4.78h14.28v4.78Zm.42,13.72v-4.63h13.77v4.63Zm0,6.61v-4.54h13.77v4.54Zm4.82,10.19h4.36v-3.63h-4.36Zm16.47-27.91h4.09v-3.16h-4.09Zm0,7.16h4.09v-3.25h-4.09Zm10-7.16h4.08v-3.16h-4.08Zm0,7.16h4.08v-3.25h-4.08Z"
        />
        <path
          class="cls-5"
          d="M619.8,1177.72a44,44,0,0,0,5.6-13.12h-4.59v-5.14h4.82v-4.87c-1.25.18-2.6.32-4,.41l-.5-4.86a66.56,66.56,0,0,0,14.13-2.71l1.38,4.73a37.89,37.89,0,0,1-5.32,1.51v5.79h4.91v5.14h-4.68q2.52,4.5,5.73,10.42l-3.76,2.89-2.2-4.82v17.12h-5.65v-13.77a28.44,28.44,0,0,1-3.17,7.2Zm14.64,6.15h12.3v-3.62h-9.41v-4.55h9.41v-3.39H636.33v-4.78h27.58v4.78H653v3.39h9.86v4.55H653v3.62h11.52V1189H634.44Zm2.89-18.86v-16.16h25.2V1165Zm6-4.64h13.08V1154H643.35Z"
        />
        <path
          class="cls-5"
          d="M669.46,1160.74v-5h16.43v5Zm1.24,29.7v-14.32h14.73v8.63a22.39,22.39,0,0,0,3.77-6.06,30.08,30.08,0,0,0,1.74-8h-4v-5.32h4.36c.06-1.38.09-3.7.09-7v-4.77H687v-5.19h22q0,34.06.83,34.06.36,0,.83-6.93l3.85,1.65a42.76,42.76,0,0,1-1,8.15,9.42,9.42,0,0,1-1.77,4,3.34,3.34,0,0,1-2.48,1,7.22,7.22,0,0,1-2.25-.27,3.54,3.54,0,0,1-1.58-1.54,9.09,9.09,0,0,1-1.15-3.49q-.35-2.22-.78-8t-.44-23.5h-5.78v4.77q0,3.81-.14,7h4.41v5.32h-4.77a39.45,39.45,0,0,1-2.53,11.23,27.08,27.08,0,0,1-5.46,8.14l-3.31-3.62v2.34h-9.5v1.7Zm0-36.72v-4.78h14.6v4.78Zm.41,13.72v-4.63h14.1v4.63Zm0,6.61v-4.54h14.1v4.54Zm4.82,10.19h4.73v-3.63h-4.73Z"
        />
        <path
          class="cls-5"
          d="M717.56,1186.26a28,28,0,0,0,5.65-9.13l5,2.29a28.67,28.67,0,0,1-6.15,10.37Zm5-10.74v-24.46h12.9a50.18,50.18,0,0,0,1.29-4.87l6.42.69c-.3,1.41-.67,2.8-1.1,4.18h15.74v24.46H742.07a49.58,49.58,0,0,1,5.51,4.41l-3.49,3.76a50.9,50.9,0,0,0-7.53-5.78l2.39-2.39Zm6.2,7.94v-6.38h6.29v5.65a7,7,0,0,0,.18,2.15c.25.4,1.59.6,4,.6s4.28,0,5.51-.16a3.2,3.2,0,0,0,2.39-.94c.36-.52.61-1.85.73-4l5.28.78c-.4-.62-1-1.53-1.88-2.76l4.91-2.52a71.52,71.52,0,0,1,6.61,11l-5.14,2.3a66.14,66.14,0,0,0-4-7.3c-.22,3.43-.71,5.59-1.49,6.49s-2.57,1.48-5.35,1.73q-2.16.18-5.9.18t-6.36-.14a11.49,11.49,0,0,1-3.76-.64,2.83,2.83,0,0,1-1.58-1.79A14.72,14.72,0,0,1,728.76,1183.46Zm0-25.15h22.76v-2.39H728.81Zm0,6.24h22.76v-2.39H728.81Zm0,6.24h22.76v-2.34H728.81Z"
        />
        <path
          class="cls-12"
          d="M874,1158.12l-6.15-5.68a8.68,8.68,0,0,0-12.68,11.8,8.7,8.7,0,0,0-11.26,1.29h0a8.7,8.7,0,0,0-.4,11.32,8.69,8.69,0,0,0-11.25,1.29h0a8.69,8.69,0,0,0-.55,11.13l-23.32-21.54a8.68,8.68,0,0,0-12.28.48h0a8.7,8.7,0,0,0,.48,12.29l44.33,41L828,1218.54a9.5,9.5,0,1,0-4.19,18.54l20.57,4.65a9,9,0,0,0,1.92.23c7.2,2.2,34.86,9.33,52.42-4.15l.43-.34c.32-.26.65-.5,1-.77v0a33.49,33.49,0,0,0,2.72-2.63h0a36.24,36.24,0,0,0-2-51.21l-26-24C874.6,1158.59,874.3,1158.35,874,1158.12Z"
        />
        <line
          class="cls-13"
          x1="829.88"
          y1="1187.51"
          x2="847.76"
          y2="1204.03"
        />
        <line
          class="cls-13"
          x1="841.59"
          y1="1174.84"
          x2="859.46"
          y2="1191.36"
        />
        <line
          class="cls-13"
          x1="853.31"
          y1="1162.75"
          x2="871.19"
          y2="1179.27"
        />
      </a>
    </g>
    <g id="圖層_9" data-name="圖層 9">
      <path
        class="cls-10"
        d="M741.88,433.8a19,19,0,0,0-18.67,18.74c-.13,3.84,0,7.71,0,11.55v50.29c0,11.66-2.72,27.26,9.07,34.35,5,3,10.48,2.67,16,2.67h49.87a36.42,36.42,0,0,1,4.9,0c9.46,1.32-2.47,19.29-5.25,23-1.31,1.74.43,4.77,2.59,4.52a40.19,40.19,0,0,0,24.74-12.36,53.2,53.2,0,0,0,7.92-11.36c1.15-2.1,1.84-3.44,4.51-3.78,4.46-.58,9.38,0,13.87,0H996.31c12.43,0,25.25.91,37.66-.06a18.87,18.87,0,0,0,16.85-14.5,37.46,37.46,0,0,0,.47-8.33V459.15c0-9.21.35-18.57-8.53-24.35-4.91-3.2-10.25-3-15.77-3H806.55c-3.86,0-3.87,6,0,6h223.7c8.13,0,14.91,4.38,15,13.33.13,8.3,0,16.61,0,24.9v38.26c0,6.74,1.29,15-.39,21.59-2.25,8.86-10.26,9.52-17.61,9.52H841.43c-1.3,0-2.62-.06-3.91,0-7,.33-8.49,5.37-11.72,10.59-5.56,9-14.77,15.68-25.37,16.91l2.59,4.51c5.18-6.89,16.39-24.69,4.64-30.85-3.7-1.95-9-1.16-13-1.16H747.69c-6.34,0-12.8.17-16.63-6.1-2.44-4-1.85-9.06-1.85-13.57v-69c0-1.41-.06-2.83,0-4.24a13,13,0,0,1,12.67-12.74c3.85-.16,3.87-6.16,0-6Z"
      />
      <circle class="cls-14" cx="773.05" cy="428.39" r="18.51" />
      <polyline
        class="cls-15"
        points="790.21 415.38 772.64 436.66 762.93 426.53"
      />
      <path
        class="cls-5"
        d="M102.61,858.69H234.9a6.72,6.72,0,0,0,6.17-3.94c5.5-12.46,17.17-22.31,30.68-23.56a51.76,51.76,0,0,0-9.24,19,6.81,6.81,0,0,0,6.57,8.53h61.21A15.68,15.68,0,0,1,346,874.36v82.25a15.68,15.68,0,0,1-15.68,15.68h-282a15.68,15.68,0,0,1-15.68-15.68V874.36a15.68,15.68,0,0,1,15.68-15.67"
      />
      <path
        class="cls-10"
        d="M102.61,861.69H232.17c3.92,0,7.67-.32,10.38-3.69,1.77-2.21,2.69-5.12,4.23-7.52a38.62,38.62,0,0,1,6.54-7.72,33.19,33.19,0,0,1,18.43-8.57l-2.59-4.51c-4.66,6.19-13,18.83-8.69,26.86,2.32,4.31,6.61,5.15,11,5.15h34.15c8.51,0,17.54-.9,26,.06,13.07,1.48,11.32,15.31,11.32,24.73v68c0,8.55-5,14.76-13.93,14.84-4.37,0-8.76,0-13.14,0H54c-5.08,0-10.1.48-14.26-3.28-6.14-5.55-4.17-17.11-4.17-24.56,0-22.1-.3-44.23,0-66.33.11-7.4,5.1-13.11,12.68-13.43,3.84-.16,3.86-6.16,0-6-8.89.38-16.65,6.23-18.34,15.2a44.6,44.6,0,0,0-.34,8.17v71.83a69,69,0,0,0,.15,7.74,18.74,18.74,0,0,0,15.85,16.43,72.14,72.14,0,0,0,10.08.23h267.6c3.43,0,7.13.3,10.52-.34a18.87,18.87,0,0,0,15.15-17c.19-2.44.06-4.92.06-7.36V879.06c0-3.15.18-6.37-.61-9.45-2.15-8.31-9.7-13.62-18.07-13.92-9.18-.32-18.43,0-27.61,0-10.81,0-22.08.93-32.85,0-10.6-.92,1.42-18.88,4.51-23,1.31-1.73-.44-4.76-2.59-4.51a40.5,40.5,0,0,0-26.68,14.53,47.19,47.19,0,0,0-4.24,6c-1.64,2.74-2.27,6.41-5.93,7-.44.07-.93,0-1.38,0-8.26.47-16.69,0-25,0H102.61c-3.86,0-3.86,6,0,6Z"
      />
      <circle class="cls-14" cx="75.41" cy="854.71" r="18.51" />
      <polyline class="cls-15" points="92.56 841.7 75 862.99 65.29 852.85" />
      <path
        class="cls-5"
        d="M989.45,915.89H879.84a6.72,6.72,0,0,1-6.17-3.95c-5.49-12.46-17.17-22.3-30.68-23.55a51.63,51.63,0,0,1,9.24,19,6.81,6.81,0,0,1-6.57,8.54H784.45a15.66,15.66,0,0,0-15.67,15.67v82.25a15.66,15.66,0,0,0,15.67,15.67h262.8a15.66,15.66,0,0,0,15.67-15.67V931.56a15.66,15.66,0,0,0-15.67-15.67"
      />
      <path
        class="cls-10"
        d="M989.45,912.89H902.69c-7.16,0-14.55.52-21.69,0-1.45-.11-2.14.37-3.72-1-1.86-1.57-2.83-5.24-4.13-7.27a47.35,47.35,0,0,0-4.43-5.83A40.28,40.28,0,0,0,843,885.39c-2.15-.25-3.89,2.78-2.59,4.51,3.15,4.19,14.91,22.09,4.52,23-3.93.34-8,0-12,0H794.77c-10.51,0-21.89-.59-27.22,10.76-3.12,6.64-1.77,16-1.77,23.07,0,22.1-.31,44.23,0,66.33.15,11,8.1,19.12,19.12,19.43,3.85.11,7.71,0,11.56,0H935.25c37.08,0,74.21.52,111.29,0,10.87-.15,19-8,19.38-18.92.28-8.42,0-16.89,0-25.31V949.16c0-7.25,1.41-16.56-1-23.6-2.61-7.71-9.73-12.33-17.68-12.67-3.86-.17-3.85,5.83,0,6,13.38.57,12.67,13.05,12.67,22.67v68c0,2.86.25,5.89-.77,8.61-2,5.36-6.9,8.07-12.39,8.27-11.38.43-22.84,0-34.22,0H787.71c-8,0-15.48-3.33-15.93-12.68-1-19.71,0-39.7,0-59.43,0-7.53-.33-15.14,0-22.67s5.6-12.49,12.93-12.81c3.22-.14,6.47,0,9.68,0h37.29c7.16,0,19.3,2.31,22.89-5.87,3.53-8-4.38-20-9-26.15L843,891.39c10.61,1.23,19.82,7.93,25.37,16.9,3.25,5.25,4.72,10.27,11.78,10.6,6.28.29,12.64,0,18.93,0h90.38c3.86,0,3.87-6,0-6Z"
      />
      <circle class="cls-14" cx="1019.85" cy="909.91" r="18.51" />
      <polyline
        class="cls-15"
        points="1037.01 896.9 1019.44 918.18 1009.73 908.05"
      />
      <path
        class="cls-10"
        d="M102.07,390.57A19,19,0,0,0,83.4,409.24c-.1,2.67,0,5.37,0,8.05v43.9c0,10.06-.36,20.19,0,30.24.34,9.2,6.65,17.18,16,18.5a40.06,40.06,0,0,0,5.79.23H294.57a19.54,19.54,0,0,1,2.42,0c4.12.53,4.81,4.52,6.73,7.62,6.77,10.94,17.73,18.4,30.55,19.88,2.15.25,3.89-2.78,2.59-4.51-2.86-3.8-14.63-21.67-5.25-23a36.42,36.42,0,0,1,4.9,0H389c7.91,0,15.41-1.69,19.82-9.07,3.12-5.21,2.67-11,2.67-16.74V458.77c0-16.1.15-32.2,0-48.3-.08-9.33-5.59-17.76-15.2-19.57a41.88,41.88,0,0,0-7.68-.33H239.43c-26.42,0-52.93-.65-79.35,0h-1.32c-3.86,0-3.87,6,0,6H381.41c9.91,0,23.39-1.44,24.07,12.68.66,13.64,0,27.47,0,41.11s.66,27.48,0,41.11a12.93,12.93,0,0,1-12.69,12.69c-1.72.09-3.46,0-5.18,0H335.68c-6.93,0-14.72,2.21-13.75,11,.81,7.4,5.37,15.17,9.75,21l2.59-4.52c-13.65-1.58-21.74-10.89-28.09-22.07-2.18-3.83-5.63-5.34-9.87-5.43-6.65-.14-13.32,0-20,0H116c-4.62,0-9.31.24-13.92,0-14.78-.75-12.68-16-12.68-26.28V412.15c0-8,3.59-15.2,12.67-15.58,3.85-.17,3.87-6.17,0-6Z"
      />
      <circle class="cls-14" cx="130.24" cy="387.15" r="18.51" />
      <polyline
        class="cls-15"
        points="147.4 374.14 129.83 395.43 120.12 385.3"
      />
      <text class="cls-16" transform="translate(118.69 446.01)">
        <tspan class="cls-17">現代觀念解說傳統</tspan>
        <tspan class="cls-18" x="0" y="36">相</tspan>
        <tspan x="33.48" y="36">法</tspan>
        <tspan class="cls-19" x="56.73" y="36">，</tspan>
        <tspan class="cls-18" x="80.6" y="36">吸收好輕鬆</tspan>
      </text>
      <text class="cls-16" transform="translate(754.85 486.58)">
        <tspan class="cls-18">簡老師親自主持的</tspan>
        <tspan class="cls-20" x="0" y="36">討論</tspan>
        <tspan x="68.2" y="36">群</tspan>
        <tspan class="cls-21" x="91.45" y="36">，</tspan>
        <tspan class="cls-20" x="115.94" y="36">持續精進</tspan>
      </text>
      <text class="cls-22" transform="translate(75.13 910.9)">
        超精美全彩講義
        <tspan x="0" y="36">學習效率up</tspan>
      </text>
      <text class="cls-23" transform="translate(805.4 968.1)">
        課程影片不限次
        <tspan x="0" y="37.2">數</tspan>
        <tspan class="cls-24" x="24.49" y="37.2">、</tspan>
        <tspan x="46.5" y="37.2">永久觀看</tspan>
      </text>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style scoped>
@keyframes button-animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 0;
  }
}

.cls-1 {
  fill: #f7e2db;
}

.cls-2 {
  fill: #efeded;
}

.cls-3 {
  fill: #ff8c81;
}

.cls-4 {
  fill: #eccfd6;
}

.cls-12,
.cls-5 {
  fill: #fff;
}

.cls-6 {
  fill: #ffc1b3;
  stroke-width: 3.74px;
}

.cls-12,
.cls-13,
.cls-6,
.cls-7,
.cls-8 {
  stroke: #000;
}

.cls-12,
.cls-13,
.cls-15,
.cls-6,
.cls-8 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-14,
.cls-7 {
  stroke-miterlimit: 10;
}

.cls-7 {
  stroke-width: 1.28px;
}

.cls-13,
.cls-14,
.cls-15,
.cls-8 {
  fill: none;
}

.cls-8 {
  stroke-width: 4.48px;
}

.cls-9 {
  fill: #f4f1f4;
}

.cls-10,
.cls-11 {
  fill: #ff5445;
}

.cls-11 {
  opacity: 0.5;
}

.cls-12,
.cls-13 {
  stroke-width: 4.46px;
}

.cls-14,
.cls-15 {
  stroke: #ff5445;
  stroke-width: 5px;
}

.cls-16,
.cls-22,
.cls-23 {
  font-size: 31px;
  /* font-family: GenEi-M-Gothic-v2-Bold, GenEi M Gothic v2; */
  font-weight: 700;
}

.cls-17 {
  letter-spacing: 0.06em;
}

.cls-18,
.cls-22 {
  letter-spacing: 0.08em;
}

.cls-19,
.cls-21 {
  /* font-family: NotoSansHant-Bold-B5pc-H, Noto Sans T Chinese; */
}

.cls-19,
.cls-21,
.cls-24 {
  font-weight: 400;
}

.cls-19 {
  letter-spacing: -0.23em;
}

.cls-20 {
  letter-spacing: 0.1em;
}

.cls-21 {
  letter-spacing: -0.21em;
}

.cls-23 {
  letter-spacing: 0.04em;
}

.cls-24 {
  /* font-family: NotoSansHant-Medium-B5pc-H, Noto Sans T Chinese; */
  letter-spacing: -0.29em;
}

.cls-11 {
  -webkit-animation-name: button-animate;
  animation-name: button-animate;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  animation-direction: normal;
}
</style>
